import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ImageSkeleton = () => (
  <SkeletonTheme color="#cfcfcf" highlightColor="#e0e0e0" duration={1.0}>
    <div style={{ height: '100%', width: '100%' }}>
      <Skeleton height="100%" width="100%" />
    </div>
  </SkeletonTheme>
);

export default ImageSkeleton;