import React from 'react';

function StarRating({ score, setScore }) {
  const totalStars = 5;
  const fullStars = Math.floor(score);
  const halfStar = (score % 1) >= 0.5 ? 1 : 0;
  const emptyStars = totalStars - fullStars - halfStar;

  const fullStarIcon = <span style={{ fontSize: '15px', lineHeight: '1' }}>★</span>;
  const halfStarIcon = <span style={{ fontSize: '15px', lineHeight: '1' }}>★</span>;
  const emptyStarIcon = <span style={{ fontSize: '15px', lineHeight: '1' }}>☆</span>;

  setScore = setScore || (() => {});

  return (
    <div className="flex">
      {Array(fullStars).fill().map((_, index) => (
        <React.Fragment key={`full-${index}`}>
          <span onClick={() => setScore(index+1)}>{fullStarIcon}</span>
        </React.Fragment>
      ))}
      {/* {halfStar ? <React.Fragment key="half">{fullStarIcon}</React.Fragment> : null} */}
      {halfStar ? <span onClick={() => setScore(fullStars + halfStar)}>{halfStarIcon}</span> : null}
      
      {Array(emptyStars).fill().map((_, index) => (
        <React.Fragment key={`empty-${index}`}>
          <span onClick={() => setScore(fullStars + halfStar + index+1)}>{emptyStarIcon}</span>
        </React.Fragment>
      ))}
    </div>
  );
}

export default StarRating;