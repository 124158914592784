import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const profiles = [
  {
    name: "Adarsh Dayalan",
    majorDesc: "Freshman in Undeclared",
    desc: "Interested in a place with a good gym and looking at 3-5 bed apartments. I'm an easygoing dude looking for a couple of chill roommates. Hit me up if you're interested!",
    profilePicture:
      "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/profile_pictures%2FAdarshInstagram.jpg?alt=media&token=5f67cf8e-1cc0-4b5a-8350-e93d7f9055db",
    instagramHandle: "@adarsh.dayalan",
    interests: ["Fitness", "Basketball", "Cooking", "Music"],
    priceRange: "$800-$1000",
  },
  {
    name: "Amanda Lee",
    majorDesc: "Sophomore in Astronomy",
    desc: "Hey, I'm Amanda. We are a group of 2 girls looking for 1-2 more female roommate for a 4-bedroom near campus. I study a lot during the week and love to hang out on weekends. Let's connect if that sounds good!",
    profilePicture:
      "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/profile_pictures%2FScreenshot%202024-07-17%20at%208.29.47%E2%80%AFPM.png?alt=media&token=ceea6b6b-3e89-42a2-b5d7-4748fabee4c1",
    instagramHandle: "@amanda.le333",
    interests: ["Astronomy", "Hiking", "Reading", "Traveling"],
    priceRange: "$700-$900",
  },
  // {
  //   name: "Alex Johnson",
  //   majorDesc: "Sophomore in Business",
  //   desc: "What's up, I'm Alex, a sophomore in Business. I'm looking for 2-3 roommates who love basketball and video games as much as I do. If you're into chill nights gaming, hooping, and lifting, let me know!",
  //   profilePicture: "",
  //   instagramHandle: "@alex.bball",
  //   interests: ["Basketball", "Video Games", "Fitness", "Music"],
  //   priceRange: "$800-$1100",
  // },
  // {
  //   name: "Emily Rodriguez",
  //   majorDesc: "Senior in Sociology",
  //   desc: "Hey, I'm Emily. Looking for a roommate in a 2-bedroom. I'm new here, clean, love cooking, and exploring. If you're cool with helping me settle in, let's chat!",
  //   profilePicture: "",
  //   instagramHandle: "@emily.soc",
  //   interests: ["Cooking", "Exploring", "Photography", "Yoga"],
  //   priceRange: "$600-$800",
  // },
];

export const ProfileCard = ({ profile }) => (
  <div className="">
    <div className="flex items-center mb-4">
      <div className="relative">
        {profile.profilePicture ? (
          <img
            src={profile.profilePicture}
            alt="Profile"
            className="w-32 h-32 rounded-full object-cover"
          />
        ) : (
          <div className="w-32 h-32 rounded-full bg-gray-200 items-center justify-center flex">
            <h2 className="text-black text-xs flex">Upload Profile Picture</h2>
          </div>
        )}
      </div>
      <div className="ml-4">
        <div className="text-lg font-semibold">{profile.name}</div>
        <div className="text-gray-600">{profile.majorDesc}</div>
        <div className="text-instagram-purple underline mb-4">
        <i className="fab fa-instagram text-md pr-1"></i>
          <a
            href={`https://www.instagram.com/${profile.instagramHandle.replace(
              "@",
              ""
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {profile.instagramHandle.replace(
              "@",
              ""
            )}
          </a>
        </div>
      </div>
    </div>
    <div>
      <h2 className="text-md font-medium">Roommate Description</h2>
      <p className="mb-4">{profile.desc}</p>
    </div>
    <div className="text-gray-600">
      Interests: {profile.interests.join(", ")}
    </div>
    <div className="text-gray-600 mb-2">Price Range: {profile.priceRange}</div>
  </div>
);

const ProfileCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Slider {...settings}>
      {profiles.map((profile, index) => (
        <div className="px-12 py-8">
          <ProfileCard key={index} profile={profile} />
        </div>
      ))}
    </Slider>
  );
};

export default ProfileCarousel;
