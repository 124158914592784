import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase/index"; // Make sure to import auth from Firebase
import { getDoc, doc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Import auth state change listener

function UserCards({ apartmentId = "general", filters, load = true }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null); // Store current user ID
  const navigate = useNavigate();

  // Listen for the current authenticated user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid); // Set current user ID if logged in
      } else {
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (load && currentUserId) {
      const fetchInterestedUsers = async () => {
        setLoading(true); // Start loading
        try {
          // Step 1: Get the apartment document to fetch userIds
          const apartmentDocRef = doc(db, "apartments", apartmentId);
          const apartmentDoc = await getDoc(apartmentDocRef);

          if (apartmentDoc.exists()) {
            const apartmentData = apartmentDoc.data();
            const userIds = apartmentData.userIds || []; // Get userIds from the apartment document, default to an empty array

            // Step 2: Fetch user information for each userId in the userIds array
            const userPromises = userIds.map((userId) => {
              const userDocRef = doc(db, "users", userId);
              return getDoc(userDocRef).then((userDoc) => ({
                id: userDoc.id,
                ...userDoc.data(),
              }));
            });

            // Resolve all promises and get the list of user data
            const usersList = await Promise.all(userPromises);

            // Step 3: Filter out the current user, users where showRoommateProfile is false, and match filters.gender with user.gender
            const filteredUsers = usersList.filter(
              (user) =>
                user.id !== currentUserId &&
                user.showRoommateProfile &&
                (filters.gender === "all" || user.gender === filters.gender) // If "all", show all users
            );

            const sortedUsers = sortUsersByProfilePicture(filteredUsers);

            // Step 4: Set the filtered list of users
            setUsers(sortedUsers);
          } else {
            console.error("No such apartment document!");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
        setLoading(false); // End loading
      };

      fetchInterestedUsers();
    }
  }, [apartmentId, load, currentUserId, filters.gender]);

  const sortUsersByProfilePicture = (users) => {
    return users.sort((a, b) => {
      if (a.profilePicture && !b.profilePicture) return -1; // a comes before b
      if (!a.profilePicture && b.profilePicture) return 1; // b comes before a
      return 0; // Keep the same order for both having or both lacking profilePicture
    });
  };

  const handleConnectClick = (userId) => {
    // Open the user's profile page in a new tab
    navigate(`/roommate/${userId}`);
  };

  return (
    <div className="">
      {loading ? (
        <div className="flex justify-center items-center">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.length > 0 ? (
            users.map((user) => (
              <div
                key={user.id}
                className="bg-white shadow-lg rounded-lg p-6 flex flex-col text-center"
              >
                <div className="flex items-center">
                  <div className="relative">
                    <img
                      src={
                        user.profilePicture ||
                        "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      alt="User Profile"
                      className="w-24 h-24 rounded-full mb-2 mr-4 object-cover"
                    />
                  </div>

                  <div className="flex flex-col items-start text-left">
                    <h3 className="text-lg font-semibold">{user.firstName}</h3>
                    {(user.year && user.major) && (
                    <p className="text-sm text-gray-600">
                      {user.year.charAt(0).toUpperCase() + user.year.slice(1)}{" "}
                      in {user.major}
                    </p>
                    )}
                    {user.instagramHandle && (
                      <div className="text-instagram-purple underline mb-4">
                        <i className="fab fa-instagram text-md pr-1"></i>
                        <a
                          href={`https://www.instagram.com/${user.instagramHandle.replace(
                            "@",
                            ""
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user.instagramHandle.replace("@", "")}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-left mt-2">
                    <h2 className="text-md font-medium">
                      Roommate Description
                    </h2>
                    <p className="mb-4">{user.roommateDescription}</p>
                  </div>
                  {user.priceRange &&
                  user.priceRange.lowerLimit !== undefined &&
                  user.priceRange.upperLimit !== undefined ? (
                    <p className="text-gray-600 mb-2 text-left">
                      Price Range: ${user.priceRange.lowerLimit} - $
                      {user.priceRange.upperLimit}
                    </p>
                  ) : (
                    <p className="text-gray-600 mb-2 text-left">
                      Price Range: All
                    </p>
                  )}
                  {user.favoriteNames && (
                    <div>
                      <p>Favorites:</p>
                      <ul>
                        {user.favoriteNames.map((name, index) => (
                          <li key={index} className="text-blue-600">
                            {name}
                          </li>
                        ))}
                      </ul>

                      {user.favorites.length > 3 && (
                        <button
                          onClick={() => navigate("/connect")}
                          className="text-blue-600 underline mt-2"
                        >
                          More
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex space-x-2 justify-center mt-auto">
                  <button
                    onClick={() => {
                      handleConnectClick(user.id);
                    }}
                    className="bg-logo_blue text-white px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-logo_blue focus:ring-opacity-50"
                  >
                    Connect
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No currently interested users found. Check back later.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default UserCards;
