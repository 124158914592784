import React, { useState, useEffect } from "react";
import {
  fetchUserData,
  updateContactInfo,
} from "../backend/dbAPI"; // Adjust the path as needed
import { sendVisitWebsiteEmail } from "../backend/emailAPI";

const VisitWebsiteModal = ({
  userId,
  apartmentEmail,
  apartmentWebsite,
  apartmentPropertyName,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [agreed, setAgreed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId) {
        const userData = await fetchUserData(userId);
        setUserData(userData);
        if (userData) {
          setFormData({
            name: userData.firstName + " " + userData.lastName,
            email: userData.email || "",
            phone: userData.phone || "",
            description: "",
          });
        }
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (formData.email && formData.phone && formData.description) {
      try {
        const response = await sendVisitWebsiteEmail(formData, apartmentEmail, apartmentPropertyName);
        const result = await response.json();

        if (response.ok) {
          setSubmitted(true);
          if (
            userData.email !== formData.email ||
            userData.phone !== formData.phone
          ) {
            if (userId) {
              await updateContactInfo(userId, {
                email: formData.email,
                phone: formData.phone,
              });
            }
          }
          window.open(apartmentWebsite, "_blank");
        } else {
          setError(result.message || "Failed to send email");
        }
      } catch (err) {
        setError("Failed to send email");
      }
    } else {
      setError("Please fill in all required fields and agree to the terms.");
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
      <div className="relative bg-white p-6 rounded-lg shadow-xl max-w-lg w-full">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {submitted ? (
          <div className="text-center">
            <h2 className="text-xl font-bold mb-4">Thank you!</h2>
            <p>
              If we have any specials or discounts that match what you are looking for, we will let you know! You can now visit the apartment's website by clicking the
              button below.
            </p>
            <button
              onClick={() => window.open(apartmentWebsite, "_blank")}
              className="mt-4 bg-logo_blue text-white px-4 py-2 rounded hover:bg-logo_blue_dark transition-colors"
            >
              Visit Website
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-bold mb-2">Visit Apartment Website</h2>
            <p className="mb-4">
              Let this apartment know a bit about what you are looking for!
            </p>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Full Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Personal Email"
              required
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Phone Number"
              required
            />
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full h-32 p-2 border rounded"
              placeholder="Specify your desired floorplan/# of beds to hear about matching discounts, gift cards, or special offers available for you!"
              required
            />
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
                className="mr-2"
                required
              />
              I want to hear about special discounts/offers available for me
            </label>
            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="w-full bg-logo_blue text-white px-4 py-2 rounded hover:bg-logo_blue_dark transition-colors"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit and Visit Website"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default VisitWebsiteModal;
