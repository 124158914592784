import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    query,
    collection,
    getDocs,
    deleteDoc,
    where,
    limit,
} from "firebase/firestore";
import { db } from "../firebase/index";

export async function sendFriendRequest(fromUserId, toUserId) {
    if (!fromUserId || !toUserId) {
        throw new Error("User IDs must be provided.");
    }

    try {
        const friendRequestsRef = collection(db, 'friendRequests');
        const q = query(friendRequestsRef,
            where('from', 'in', [fromUserId, toUserId]),
            where('to', 'in', [fromUserId, toUserId]));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            throw "A friend request already exists between these users.";
        }

        const friendRequestRef = doc(collection(db, 'friendRequests'));
        await setDoc(friendRequestRef, {
            from: fromUserId,
            to: toUserId,
            status: 'pending',
        });

        // console.log('Friend request sent successfully.');
    } catch (error) {
        console.error('Error sending friend request:', error);
        throw error;
    }
}
export async function declineFriendRequest(requestId) {
    const requestDocRef = doc(db, 'friendRequests', requestId);
    const requestDocSnap = await getDoc(requestDocRef);

    if (requestDocSnap.exists()) {
        const { fromUserId, toUserId } = requestDocSnap.data();
        // Delete the friend request document
        await deleteDoc(requestDocRef);
        // console.log(`Friend request from ${fromUserId} to ${toUserId} declined successfully.`);
    } else {
        throw new Error('No such friend request!');
    }
}

export async function acceptFriendRequest(requestId) {
    if (!requestId) {
        throw new Error("Request ID must be provided.");
    }

    const friendRequestRef = doc(db, 'friendRequests', requestId);

    try {
        const friendRequestSnap = await getDoc(friendRequestRef);
        if (!friendRequestSnap.exists()) {
            throw new Error('No such friend request.');
        }

        const friendRequestData = friendRequestSnap.data();
        if (friendRequestData.status !== 'pending') {
            throw new Error('Friend request is not pending.');
        }

        // Update the friend request status to accepted
        await updateDoc(friendRequestRef, { status: 'accepted' });

        // Add the friends to the friends collection
        const friendsRef = doc(collection(db, 'friends'));
        await setDoc(friendsRef, {
            user1: friendRequestData.from,
            user2: friendRequestData.to,
        });
        // console.log('Friend request accepted and friendship created.');
    } catch (error) {
        console.error('Error accepting friend request:', error);
        throw error;
    }
}

export async function fetchPendingRequests(userId) {
    if (!userId) {
        throw new Error("User ID must be provided.");
    }

    const friendRequestsQuery = query(
        collection(db, 'friendRequests'),
        where('to', '==', userId),
        where('status', '==', 'pending')
    );

    try {
        const querySnapshot = await getDocs(friendRequestsQuery);
        const pendingRequests = [];

        for (const each of querySnapshot.docs) {
            const request = each.data();
            const fromUserDoc = await getDoc(doc(db, 'users', request.from));
            if (fromUserDoc.exists()) {
                const fromUserData = fromUserDoc.data();
                pendingRequests.push({
                    id: each.id,
                    fromUserId: request.from,
                    fromUserName: `${fromUserData.firstName} ${fromUserData.lastName}`,
                    fromUserEmail: fromUserData.schoolEmail,
                    profilePicture: fromUserData.profilePicture,
                });
            }
        }

        return pendingRequests;
    } catch (error) {
        console.error('Error fetching pending friend requests:', error);
        throw error;
    }
}

export async function fetchFriends(userId) {
    if (!userId) {
        // console.log("fetchFriends: userId is null.");
    }

    const friendsQuery1 = query(
        collection(db, 'friends'),
        where('user1', '==', userId)
    );

    const friendsQuery2 = query(
        collection(db, 'friends'),
        where('user2', '==', userId)
    );

    try {
        const [friendsSnapshot1, friendsSnapshot2] = await Promise.all([
            getDocs(friendsQuery1),
            getDocs(friendsQuery2),
        ]);

        const friends1 = friendsSnapshot1.docs.map(doc => doc.data().user2);
        const friends2 = friendsSnapshot2.docs.map(doc => doc.data().user1);

        const friendIds = [...new Set([...friends1, ...friends2])];

        const friendsDetails = [];
        const batchLimit = 10;
        for (let i = 0; i < friendIds.length; i += batchLimit) {
            const batch = friendIds.slice(i, i + batchLimit);
            const friendDocs = await Promise.all(batch.map(friendId => getDoc(doc(db, 'users', friendId))));
            friendDocs.forEach(docSnap => {
                if (docSnap.exists()) {
                    friendsDetails.push({ id: docSnap.id, ...docSnap.data() });
                }
            });
        }
        return friendsDetails;
    } catch (error) {
        throw error;
    }
}

export async function searchUsers(searchTerm) {
    if (!searchTerm) {
        return [];
    }

    const usersRef = collection(db, "users");
    const q = query(
        usersRef,
        where("firstName", ">=", searchTerm),
        where("firstName", "<=", searchTerm + '\uf8ff'),
        limit(3)
    );

    const qLastName = query(
        usersRef,
        where("lastName", ">=", searchTerm),
        where("lastName", "<=", searchTerm + '\uf8ff'),
        limit(3)
    );

    try {
        const [firstNameSnapshot, lastNameSnapshot] = await Promise.all([
            getDocs(q),
            getDocs(qLastName)
        ]);

        const users = firstNameSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        })).concat(lastNameSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        })));

        const uniqueUsers = users.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        return uniqueUsers;
    } catch (error) {
        console.error('Error searching users:', error);
        throw error;
    }
}