import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

export const SignUpPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3; // Adjust based on the actual steps in your form
  const [user, loading, error] = useAuthState(auth);
  const [loginLoading, setLoginLoading] = useState(false);
  const [agreed, setAgreed] = useState(true);

  const schoolEmailRef = useRef(null);
  const passwordRef = useRef(null);
  // const confirmPasswordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const genderRef = useRef(null);
  const yearRef = useRef(null);
  const majorRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (target, e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default action for the Enter key (form submission)
      switch (target) {
        case "schoolEmail":
          nextStep();
          break;
        case "email":
          passwordRef.current.focus();
          break;
        case "password":
          nextStep();
          // confirmPasswordRef.current.focus();
          break;
        // case "confirmPassword":
        //   nextStep();
        //   break;
        case "firstName":
          lastNameRef.current.focus();
          break;
        case "lastName":
          genderRef.current.focus();
          break;
        case "gender":
          yearRef.current.focus();
          break;
        case "year":
          majorRef.current.focus();
          break;
        case "major":
          nextStep();
        default:
          break;
      }
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    schoolEmail: "",
    password: "",
    // confirmPassword: "",
    firstName: "",
    lastName: "",
    gender: "",
    year: "",
    major: "",
    email: "",
  });

  const [signUpError, setSignUpError] = useState("");

  const goToLogin = () => {
    const from = location.state?.from?.pathname || "/listings";
    navigate("/login", { state: { from: from } });
  };

  const goToGuestSignup = () => {
    const from = location.state?.from?.pathname || "/listings";
    navigate("/guest-signup", { state: { from: from } });
  };

  useEffect(() => {
    if (user) {
      const from = location.state?.from?.pathname || "/listings";
      navigate(from);
    }
  }, [user, navigate]);

  useEffect(() => {
    if (location.state?.email) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        schoolEmail: location.state.email,
      }));
    }
  }, [location.state]);

  // useEffect(() => {
  //   if (location.state?.email && currentStep === 1) {
  //     passwordRef.current?.focus();
  //   } else {
  //     emailRef.current?.focus();
  //   }
  // }, [currentStep, location.state]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nextStep = () => {
    setSignUpError("");
    if (currentStep == 1) {
      const { schoolEmail } = formData;
      if (!isSchoolEmailValid(schoolEmail)) {
        setSignUpError("Please use a valid UIUC email address.");
        return;
      }
      setCurrentStep(currentStep + 1);
    }
    if (currentStep == 2) {
      const { email, password } = formData;
      if (!isValidEmail(email)) {
        setSignUpError("Please enter a valid personal email address.");
        return;
      }

      if (!isPasswordStrong(password)) {
        setSignUpError(
          "Password must be at least 6 characters long and include a letter and number"
        );
        return;
      }

      // if (password !== confirmPassword) {
      //   setSignUpError("Passwords do not match.");
      //   return;
      // }

      setCurrentStep(currentStep + 1);
    }
    if (currentStep == 3) {
      const { firstName, lastName, gender, year, major } = formData;

      if (!firstName || !lastName || !gender || !year || !major) {
        setSignUpError("All fields must be filled out.");
        return;
      }
    }
  };

  const prevStep = () => {
    setSignUpError("");
    setCurrentStep(currentStep - 1);
  };

  const isSchoolEmailValid = (schoolEmail) =>
    schoolEmail.endsWith("@illinois.edu");

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isPasswordStrong = (password) => {
    const regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$");
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    const {
      schoolEmail,
      password,
      firstName,
      lastName,
      gender,
      year,
      major,
      email,
    } = formData;

    if (!firstName || !lastName || !gender || !year || !major) {
      setSignUpError("All fields must be filled out.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      const userData = {
        schoolEmail,
        firstName,
        lastName,
        gender,
        year,
        major,
        email,
      };

      await setDoc(doc(db, "users", user.uid), userData);
    } catch (error) {
      const errorMessage = error.message.replace("Firebase: ", "");
      setSignUpError(errorMessage);
      setLoginLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block font-medium mb-2">
                School Email
              </label>
              <input
                ref={schoolEmailRef}
                type="email"
                id="schoolEmail"
                name="schoolEmail"
                placeholder="netid@illinois.edu"
                value={formData.schoolEmail}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown("schoolEmail", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block font-medium mb-2">
                Personal Email
              </label>
              <input
                ref={emailRef}
                type="email"
                id="email"
                name="email"
                placeholder="johndoe@gmail.com"
                value={formData.email}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown("email", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block font-medium mb-2">
                Create Password
              </label>
              <input
                ref={passwordRef}
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown("password", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              />
            </div>
            {/* <div>
              <label htmlFor="confirmPassword" className="block font-medium mb-2">
                Confirm Password
              </label>
              <input
                ref={confirmPasswordRef}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown("confirmPassword", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              />
            </div> */}
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <div className="flex justify-between space-x-4">
              <div className="flex-1">
                <label htmlFor="firstName" className="font-medium mb-2">
                  First Name
                </label>
                <input
                  ref={firstNameRef}
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="John"
                  value={formData.firstName}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown("firstName", e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  required
                />
              </div>
              <div className="flex-1">
                <label htmlFor="lastName" className="font-medium mb-2">
                  Last Name
                </label>
                <input
                  ref={lastNameRef}
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Doe"
                  value={formData.lastName}
                  onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown("lastName", e)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="gender" className="block font-medium mb-2">
                Gender
              </label>
              <select
                ref={genderRef}
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                // onKeyDown={(e) => handleKeyDown("gender", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div>
              <label htmlFor="year" className="block font-medium mb-2">
                Year
              </label>
              <select
                ref={yearRef}
                id="year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                // onKeyDown={(e) => handleKeyDown("year", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              >
                <option value="">Select Year</option>
                <option value="freshman">Freshman</option>
                <option value="sophomore">Sophomore</option>
                <option value="junior">Junior</option>
                <option value="senior">Senior</option>
                <option value="grad">Grad</option>
                <option value="phd">PhD</option>
              </select>
            </div>
            <div>
              <label htmlFor="major" className="block font-medium mb-2">
                Major
              </label>
              <input
                ref={majorRef}
                type="text"
                id="major"
                name="major"
                placeholder="Astronomy"
                value={formData.major}
                onChange={handleChange}
                onKeyDown={(e) => handleKeyDown("major", e)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
                required
              />
            </div>
            {/* <div>
              <label htmlFor="phoneNumber" className="block font-medium mb-2">
                Phone Number
              </label>
              <input
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="123456789"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue"
              />
            </div> */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center justify-center ">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-2">Welcome to Abode!</h2>
        <p className="text-md text-gray-500">
          View every on-campus apartment here. <br />
          For UIUC students, by UIUC students.
        </p>
        {/* {currentStep == 3 && (
          <div>
            <p className="text-md pt-2">Last Step!</p>
          </div>
        )} */}
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg my-8 mx-4 md:max-w-2xl w-full">
      <h2 className="text-xl font-bold mb-4">Student Sign Up</h2>
        <div className="w-full bg-gray-200 rounded-full mb-6">
          <div
            className={`bg-logo_blue h-2 rounded-full`}
            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
          ></div>
        </div>
        {renderStepContent(currentStep)}
        <div className="flex justify-between mt-4">
          {currentStep > 1 && (
            <button
              onClick={prevStep}
              className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-md transition-colors"
            >
              Back
            </button>
          )}
          {currentStep < totalSteps ? (
            <button
              onClick={nextStep}
              className="bg-logo_blue text-white py-2 px-4 rounded-md transition-colors"
            >
              Next
            </button>
          ) : (
            <div>
              {loginLoading ? (
                <button className="bg-logo_blue py-2 px-4 rounded-md transition-colors text-white">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="bg-logo_blue py-2 px-4 rounded-md transition-colors text-white"
                >
                  Sign Up
                </button>
              )}
            </div>
          )}
        </div>
        {signUpError && (
          <p className="text-red-500 text-center mt-2">{signUpError}</p>
        )}
      </div>
      {/* {currentStep == 2 && (
        <>
          <p className="text-sm">
            *We may send important messages regarding apartments, roommates, and
            more to this email.
          </p>
          <p className="text-sm">
            Please make sure to input a email you can check regularly and not
            your school email.
          </p>
        </>
      )} */}
      {currentStep == 3 && (
        <>
          <p className="mt-2 text-sm text-gray-700 line-clamp-2">
            We only use this data to verify you are a{" "}
          </p>
          <p className="mb-2 text-sm text-gray-700 line-clamp-2">
            UIUC student and personalize your experience
          </p>
        </>
      )}
      {currentStep == 1 && (
        <>
          <button onClick={goToLogin}>
            Already have an account? <span className="text-blue-700 underline">Log in here</span> 
          </button>
          <button onClick={goToGuestSignup} className="pt-2">
            Not a student? <span className="text-blue-700 underline">Sign up here</span>
          </button>
        </>
      )}
    </div>
  );
};
