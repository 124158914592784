import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import MainHeader from "../../components/Headers/MainHeader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { ref, uploadBytes, deleteObject, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs, deleteDoc, addDoc } from "firebase/firestore";
import { storage } from "../../firebase";

function SubleaseEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    apartmentName: "",
    description: "",
    rent: "",
    startDate: "",
    endDate: "",
    address: "",
    bedrooms: "",
    bathrooms: "",
    configuration: "",
    genderRequirement: "",
    feeInfo: "",
    websiteLink: "",
  });
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [roommates, setRoommates] = useState([]);

  useEffect(() => {
    const fetchSublease = async () => {
      try {
        const subleaseDoc = await getDoc(doc(db, "subleases", id));
        if (subleaseDoc.exists()) {
          const data = subleaseDoc.data();
          // Verify ownership
          if (data.userId !== user?.uid) {
            navigate("/unauthorized");
            return;
          }
          setFormData(data);
        } else {
          navigate("/not-found");
        }
      } catch (error) {
        console.error("Error fetching sublease:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchSublease();
    }
  }, [id, user, navigate]);

  useEffect(() => {
    const fetchSubleaseData = async () => {
      try {
        // Fetch existing roommates
        const roommatesSnapshot = await getDocs(
          collection(db, "subleases", id, "roommates")
        );
        const roommatesData = roommatesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRoommates(roommatesData);

        // Fetch existing images
        const imagesRef = ref(storage, `sublease_images/${id}`);
        const imagesList = await listAll(imagesRef);
        const urls = await Promise.all(
          imagesList.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return { ref: item, url };
          })
        );
        setExistingImages(urls);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSubleaseData();
  }, [id]);

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    
    // If the date is in MM-DD-YYYY format, convert it to YYYY-MM-DD for input
    if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      const [month, day, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    }
    return dateString;
  };

  const formatDateForStorage = (dateString) => {
    if (!dateString) return "";
    
    // Convert from YYYY-MM-DD to MM-DD-YYYY
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for dates
    if (name === 'startDate' || name === 'endDate') {
      setFormData(prev => ({
        ...prev,
        [name]: formatDateForStorage(value)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    const totalImages = existingImages.length + files.length;
    
    if (totalImages > 10) {
      alert("You can only have a maximum of 10 images.");
      e.target.value = null;
      return;
    }

    try {
      // Upload each new image and get its URL
      const uploadPromises = files.map(async (file) => {
        const imageRef = ref(storage, `sublease_images/${id}/${file.name}`);
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        return { 
          ref: imageRef, 
          url,
          name: file.name 
        };
      });

      const newImages = await Promise.all(uploadPromises);
      
      // Update existingImages with the new images
      setExistingImages(prev => [...prev, ...newImages]);
      
      // Clear the file input
      e.target.value = null;
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images. Please try again.");
    }
  };

  const handleDeleteImage = async (imageRef) => {
    try {
      await deleteObject(imageRef);
      setExistingImages(prev => prev.filter(img => img.ref !== imageRef));
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleRoommateChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRoommates = [...roommates];
    updatedRoommates[index] = {
      ...updatedRoommates[index],
      [name]: value,
    };
    setRoommates(updatedRoommates);
  };

  const handleRemoveRoommate = async (index, roommateId) => {
    if (roommateId) {
      // Delete from Firestore if it's an existing roommate
      await deleteDoc(doc(db, "subleases", id, "roommates", roommateId));
    }
    const updatedRoommates = roommates.filter((_, i) => i !== index);
    setRoommates(updatedRoommates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "subleases", id), formData);
      navigate(`/sublease/${id}`);
    } catch (error) {
      console.error("Error updating sublease:", error);
    }
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...existingImages];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setExistingImages(updatedImages);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "subleases", id), {
        ...formData,
        images: existingImages.map(img => ({
          url: img.url,
          name: img.ref.name
        })),
        rent: parseFloat(formData.rent),
        bedrooms: parseFloat(formData.bedrooms),
        bathrooms: parseFloat(formData.bathrooms),
      });

      // Handle roommate updates
      const roommatesRef = collection(db, "subleases", id, "roommates");
      
      // Delete existing roommates
      const existingRoommates = await getDocs(roommatesRef);
      await Promise.all(
        existingRoommates.docs.map(doc => deleteDoc(doc.ref))
      );

      // Add updated roommates
      await Promise.all(
        roommates.map(roommate => 
          addDoc(roommatesRef, {
            gender: roommate.gender,
            age: parseFloat(roommate.age),
            occupation: roommate.occupation,
          })
        )
      );

      navigate(`/sublease/${id}`);
    } catch (error) {
      console.error("Error updating sublease:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="max-w-4xl mx-auto py-8 px-4">
        <div className="bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-semibold mb-6">Edit Sublease</h1>
          <form onSubmit={onSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Apartment Name
                </label>
                <input
                  type="text"
                  name="apartmentName"
                  value={formData.apartmentName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Rent (per month)
                </label>
                <input
                  type="number"
                  name="rent"
                  value={formData.rent}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <input
                  type="date"
                  name="startDate"
                  value={formatDateForInput(formData.startDate)}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <input
                  type="date"
                  name="endDate"
                  value={formatDateForInput(formData.endDate)}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Configuration
                </label>
                <input
                  type="text"
                  name="configuration"
                  value={formData.configuration}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bedrooms
                </label>
                <input
                  type="number"
                  name="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Bathrooms
                </label>
                <input
                  type="number"
                  name="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Gender Requirement
                </label>
                <select
                  name="genderRequirement"
                  value={formData.genderRequirement}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                >
                  <option value="">Select Gender Requirement</option>
                  <option value="Any">Any</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows="4"
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Fee Information
                </label>
                <input
                  type="text"
                  name="feeInfo"
                  value={formData.feeInfo}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Website Link
                </label>
                <input
                  type="url"
                  name="websiteLink"
                  value={formData.websiteLink}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                />
              </div>

              {/* Images Section */}
              <div className="col-span-2 mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Current Images (use arrows to reorder)
                </label>
                <div className="flex flex-wrap gap-2">
                  {existingImages.map((img, index) => (
                    <div key={index} className="relative group">
                      <img 
                        src={img.url} 
                        alt={`Sublease ${index}`} 
                        className="w-36 h-36 object-cover rounded shadow-sm"
                      />
                      <div className="absolute top-0 right-0 flex gap-1 p-1">
                        {index > 0 && (
                          <button
                            type="button"
                            onClick={() => moveImage(index, index - 1)}
                            className="bg-white rounded-full w-6 h-6 flex items-center justify-center shadow hover:bg-gray-100"
                          >
                            ↑
                          </button>
                        )}
                        {index < existingImages.length - 1 && (
                          <button
                            type="button"
                            onClick={() => moveImage(index, index + 1)}
                            className="bg-white rounded-full w-6 h-6 flex items-center justify-center shadow hover:bg-gray-100"
                          >
                            ↓
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => handleDeleteImage(img.ref)}
                          className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-600"
                        >
                          ×
                        </button>
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs text-center">
                        {index + 1}
                      </div>
                    </div>
                  ))}
                </div>

                <input
                  type="file"
                  multiple
                  onChange={handleImagesChange}
                  className="mt-4 w-full"
                />
                
                {imageURLs.length > 0 && (
                  <div className="mt-4">
                    <p className="text-sm font-medium text-gray-700">New Images:</p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {imageURLs.map((url, index) => (
                        <div key={index} className="relative">
                          <img 
                            src={url}
                            alt={`New ${index}`}
                            className="w-20 h-20 object-cover rounded"
                          />
                          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs text-center">
                            New
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Roommates Section */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Roommates
                </label>
                
                <div className="space-y-4">
                  {roommates.map((roommate, index) => (
                    <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                      <input
                        type="text"
                        name="occupation"
                        value={roommate.occupation || ""}
                        onChange={(e) => handleRoommateChange(index, e)}
                        className="p-2 border rounded"
                        placeholder="Occupation (e.g. Student)"
                        required
                      />
                      <select
                        name="gender"
                        value={roommate.gender || ""}
                        onChange={(e) => handleRoommateChange(index, e)}
                        className="p-2 border rounded"
                        required
                      >
                        <option value="" disabled>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <input
                        type="number"
                        name="age"
                        value={roommate.age || ""}
                        onChange={(e) => handleRoommateChange(index, e)}
                        className="p-2 border rounded"
                        placeholder="Age"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveRoommate(index, roommate.id)}
                        className="p-2 text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>

                <button
                  type="button"
                  onClick={() => setRoommates([...roommates, {}])}
                  className="mt-6 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  + Add Roommate
                </button>
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate(`/sublease/${id}`)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-logo_blue text-white rounded-md text-sm font-medium hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SubleaseEdit; 