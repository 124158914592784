import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { fetchUserData } from "../backend/dbAPI";
import {
  preferenceDescriptions,
  lifestylePreferenceDescriptions,
} from "../data/consts/prefrencesDesc";
import MainHeader from "../components/Headers/MainHeader";
import { fetchLeanSpecificApartment } from "../backend/dynamoAPI";
import ListingCard from "../components/Listings/ListingCard";

const MessageModal = lazy(() => import("../components/Roommates/MessageModal"));

// const testRoommateAptids = [
//   {
//     name: "Here Champaign",
//     img: "https://irp.cdn-website.com/cbe2c725/dms3rep/multi/564a62b3cefb6809.jpg",
//   },
//   {
//     name: "309 Green",
//     img: "https://www.americancampus.com/getmedia/f5a620e3-ce1a-49b4-845b-e6d3619a4c5b/416_-01_Gallery_730x547.jpg",
//   },
//   {
//     name: "75 E Armory",
//     img: "https://ugroupcu.com/property_images/property/detailsimg/4b24d9eeabd0a212788fd5624cefda7d75-Armory-Exterior-091.jpg",
//   },
// ];

function RoommateDetailPage() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const [lifestyleModalOpen, setLifestyleModalOpen] = useState(false);
  const [livingHabitsModalOpen, setLivingHabitsModalOpen] = useState(false);

  const [favApartments, setFavApartments] = useState([]);

  const getTopSimilarPreferencesDesc = (userPrefs, roommatePrefs, desc) => {
    const similarPrefs = [];
    Object.keys(userPrefs).forEach((key) => {
      if (desc[key]) {
        if (userPrefs[key] === roommatePrefs[key]) {
          similarPrefs.push(desc[key](userPrefs[key]));
        }
      }
    });
    return similarPrefs.slice(0, 4).join(", ");
  };

  const similarLifestyles = getTopSimilarPreferencesDesc(
    userData?.lifestylePreferences || {},
    userData?.lifestylePreferences || {},
    lifestylePreferenceDescriptions
  );

  const similarLivingHabits = getTopSimilarPreferencesDesc(
    userData?.livingHabits || {},
    userData?.livingHabits || {},
    preferenceDescriptions
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData(userId);
        if (data) {
          setUserData(data);
  
          // Check if the user has favorite apartments and fetch those apartments
          if (data.favorites && data.favorites.length > 0) {
            const favoriteApartmentsPromises = data.favorites.map(async (favId) => {
              let groupId = "single_apartments"; // Default group ID if no slash is present
          let apartmentId = favId;

          if (apartmentId.includes("/")) {
            const [groupKey, id] = apartmentId.split("/");
            groupId = groupKey;
            apartmentId = favId;
          }

              return await fetchLeanSpecificApartment(groupId,apartmentId);
            });
  
            const favoriteApartments = await Promise.all(favoriteApartmentsPromises);
            setFavApartments(favoriteApartments);
          }
        } else {
          setError("User not found");
        }
      } catch (err) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };
  
    if (userId) {
      loadUserData();
    } else {
      setError("No user ID provided");
      setLoading(false);
    }
  }, [userId]);
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>{" "}
        {/* Tailwind doesn't have a loader by default, customize as needed */}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500 text-lg">{error}</p>
      </div>
    );
  }

  return (
    <div>
      <MainHeader />
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row my-8">
        {/* Left side: Profile Card */}
        <div className="w-full md:w-1/3">
          <div className="p-6 bg-gray-100 rounded-lg shadow-md mb-8">
            <ProfileCard userData={userData} />
            <button
              onClick={handleOpen}
              className="mt-4 px-4 py-2 bg-logo_blue text-white rounded-lg w-full"
            >
              Message {userData.firstName}
            </button>
          </div>
        </div>

        {/* Right side: Scrollable content */}
        <div className="w-full md:w-2/3 pl-12 mb-8">
          {userData.interests && Object.keys(userData.interests).length > 0 && (
            <>
              <h3 className="text-xl font-semibold mb-4">Interests</h3>
              <div className="mb-6">
                <ProfileInterests selectedInterests={userData.interests} />
                <div className="mt-2">
                  <h1>
                    <span className="font-medium">Currently Watching: </span>
                    {userData.shows}
                  </h1>
                </div>
              </div>
            </>
          )}

          

          <h3 className="text-xl font-semibold mt-8 mb-4">Additional Info</h3>
          {userData.ethnicity && (
            <p className="text-gray-700 mb-2">
              Ethnicity: {userData.ethnicity}
            </p>
          )}
          {userData.homeState && (
            <p className="text-gray-700 mb-2">
              Home State: {userData.homeState}
            </p>
          )}
          {userData.isInternational === "Yes" && (
            <p className="text-gray-700">International Student</p>
          )}


          {/* TODO: Replace with real apartments in prod */}
          
          <div>
            {favApartments.length > 0 && (
              <div className="mt-4 relative">
              <h2 className="text-xl font-semibold">Interested Apartments</h2>
              
              {/* Right arrow to indicate scrolling */}
              <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full z-10"
                onClick={() => {
                  const container = document.getElementById("scrollableContainer");
                  container.scrollBy({ left: 300, behavior: "smooth" });
                }}
              >
                <i className="fas fa-chevron-right"></i> {/* Font Awesome icon */}
              </button>
            
              {/* Scrollable container */}
              <div
                id="scrollableContainer"
                className="flex space-x-4 overflow-x-auto py-4 scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                style={{ scrollBehavior: "smooth" }} // Smooth scrolling
              >
                {favApartments.map((apartmentId) => (
                  <div key={apartmentId.id} className="flex-shrink-0 w-64 bg-white rounded-lg">
                    <ListingCard apartment={apartmentId} />
                  </div>
                ))}
              </div>
            </div>
            
            )}
            
          </div> 

          {userData.livingHabits && (
            <div>
              <h3 className="text-xl font-semibold">Living Habits</h3>
              <h1>Similar preferences: {similarLivingHabits}</h1>
              <button
                onClick={() => {
                  setLivingHabitsModalOpen(true);
                }}
                className="text-black font-medium underline mt-2"
              >
                View All Preferences &gt;
              </button>
              {livingHabitsModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl p-6 relative">
                    <button
                      onClick={() => {
                        setLivingHabitsModalOpen(false);
                      }}
                      className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    >
                      &#x2715;
                    </button>
                    <CompactLivingHabits livingHabits={userData.livingHabits} />
                  </div>
                </div>
              )}
            </div>
          )}

          {userData.lifestylePreferences && (
            <div className="mt-4">
              <h3 className="text-xl font-semibold">Lifestyle</h3>
              <h1>Similar Preferences: {similarLifestyles}</h1>

              <button
                onClick={() => {
                  setLifestyleModalOpen(true);
                }}
                className="text-black font-medium underline mt-2"
              >
                View All Preferences &gt;
              </button>
              {lifestyleModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-6 relative">
                    <button
                      onClick={() => {
                        setLifestyleModalOpen(false);
                      }}
                      className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    >
                      &#x2715;
                    </button>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {userData.lifestylePreferences.alcohol && (
                        <span className="bg-gray-100 p-2 rounded">
                          Do you drink alcohol:{" "}
                          {userData.lifestylePreferences.alcohol}
                        </span>
                      )}
                      {userData.lifestylePreferences.smoke && (
                        <span className="bg-gray-100 p-2 rounded">
                          Do you smoke: {userData.lifestylePreferences.smoke}
                        </span>
                      )}
                      {userData.lifestylePreferences.diet && (
                        <span className="bg-gray-100 p-2 rounded">
                          Diet: {userData.lifestylePreferences.diet}
                        </span>
                      )}
                      {userData.lifestylePreferences.greekLife && (
                        <span className="bg-gray-100 p-2 rounded">
                          In Greek life:{" "}
                          {userData.lifestylePreferences.greekLife}
                        </span>
                      )}
                      {userData.lifestylePreferences.pets && (
                        <span className="bg-gray-100 p-2 rounded">
                          Has Pets: {userData.lifestylePreferences.pets}
                        </span>
                      )}
                      {userData.lifestylePreferences.social && (
                        <span className="bg-gray-100 p-2 rounded">
                          Social: {userData.lifestylePreferences.social}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
       
        </div>

        {/* MessageModal is lazily loaded and shown when 'Connect' is clicked */}
        <Suspense fallback={<div>Loading modal...</div>}>
          {open && (
            <MessageModal
              userData={userData}
              isOpen={open}
              onClose={handleClose}
            />
          )}
        </Suspense>
      </div>
    </div>
  );
}

const ProfileCard = ({ userData }) => (
  <div className="">
    <div className="flex items-center mb-4">
      <div className="relative">
        <img
          alt={`${userData.firstName} ${userData.lastName}`}
          src={
            userData.profilePicture
              ? userData.profilePicture
              : "https://via.placeholder.com/150"
          }
          className="w-32 h-32 rounded-full object-cover"
        />
      </div>
      <div className="ml-4">
        <div className="text-lg font-semibold">{`${userData.firstName} ${userData.lastName}`}</div>
        <div className="text-gray-600">{`${
          userData.year.charAt(0).toUpperCase() +
          userData.year.slice(1).toLowerCase()
        } in ${userData.major}`}</div>

        {/* Conditionally render Instagram handle */}
        {userData.instagramHandle && (
          <div className="text-instagram-purple underline mb-4">
            <i className="fab fa-instagram text-md pr-1"></i>
            <a
              href={`https://www.instagram.com/${userData.instagramHandle.replace(
                "@",
                ""
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userData.instagramHandle.replace("@", "")}
            </a>
          </div>
        )}
      </div>
    </div>

    {/* Conditionally render Roommate Description */}
    {userData.roommateDescription ? (
      <div>
        <h2 className="text-md font-medium">Roommate Description</h2>
        <p className="mb-4">{userData.roommateDescription}</p>
      </div>
    ) : (
      <div>
        <h2 className="text-md font-medium">Roommate Description</h2>
        <p className="mb-4 text-gray-500">No description provided.</p>
      </div>
    )}

    {/* Price Range */}
    {userData.priceRange && (
      <p className="text-gray-600 mb-2">
        Price Range: ${userData.priceRange.lowerLimit} - $
        {userData.priceRange.upperLimit}
      </p>
    )}
  </div>
);

const CompactLivingHabits = ({ livingHabits }) => {
  if (!livingHabits) {
    return <></>;
  }

  const habits = [
    {
      question: "Cleanliness",
      value: livingHabits.cleanliness,
      leftText: "A little mess never hurt anybody... 🧹",
      rightText: "Neat freak 🧼",
    },
    {
      question: "Bird Type",
      value: livingHabits.birdType,
      leftText: "Night owl 🦉",
      rightText: "Early bird 🐦",
    },
    {
      question: "Guest Preference",
      value: livingHabits.guestPreference,
      leftText: "Me, myself, and I 🧘",
      rightText: "Friends over 24/7 🎉",
    },
    {
      question: "Noise Level",
      value: livingHabits.noiseLevel,
      leftText: "Quiet Environment 🤫",
      rightText: "Like a lively space 🎶",
    },
    {
      question: "Cooking Frequency",
      value: livingHabits.cookingFrequency,
      leftText: "Eat out every day 🍔",
      rightText: "I'm cheffing in the kitchen 24/7 👨‍🍳",
    },
    {
      question: "Temperature Preference",
      value: livingHabits.temperaturePreference,
      leftText: "Like it cold 🥶",
      rightText: "I want it toasty 🥵",
    },
  ];

  return (
    <div className="bg-white rounded-lg w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4">
        {habits.map((habit, index) => (
          <div key={index} className="mb-4">
            <div className="text-sm font-semibold mb-1">{habit.question}</div>
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span className="pr-2" style={{ width: "40%" }}>
                {habit.leftText}
              </span>
              <div
                className="flex items-center justify-center space-x-2"
                style={{ flex: 1 }}
              >
                {[1, 2, 3, 4].map((value) => (
                  <div
                    key={value}
                    className={`flex items-center justify-center transform transition-transform duration-200 ${
                      habit.value === value
                        ? "bg-blue-600 text-white"
                        : "border-2 border-gray-300 text-gray-500"
                    }`}
                    style={{
                      width: value === 1 || value === 4 ? "20px" : "15px",
                      height: value === 1 || value === 4 ? "20px" : "15px",
                      borderRadius: "50%",
                    }}
                  ></div>
                ))}
              </div>
              <span style={{ width: "40%", textAlign: "right" }}>
                {habit.rightText}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getInterestLevelClass = (level) => {
  switch (level) {
    case 1:
      return "bg-blue-500 text-white";
    case 2:
      return "bg-blue-800 text-white";
    case 3:
      return "bg-logo_blue text-white";
    default:
      return "";
  }
};

const ProfileInterests = ({ selectedInterests }) => {
  const sortedInterests = Object.entries(selectedInterests).sort(
    ([, levelA], [, levelB]) => levelB - levelA
  );

  return (
    <div className="flex flex-wrap">
      {sortedInterests.map(([interest, level]) => (
        <div
          key={interest}
          className={`px-4 py-2 m-1 rounded-lg ${getInterestLevelClass(level)}`}
        >
          {interest}
        </div>
      ))}
    </div>
  );
};

export default RoommateDetailPage;
