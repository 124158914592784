const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-4">
          <p className="text-lg font-semibold">
            Made by UIUC students, for UIUC students.
          </p>
        </div>
        <div className="mb-2">
          <p className="text-gray-300">
            If you have any feedback or want to reach out, please email us
          </p>
        </div>
        <div className="mb-4">
          <p className="text-gray-300">
            We are actively expanding our team. If you are interested in
            contributing or working with our startup, please reach out us.
          </p>
        </div>
        <div>

          <a
            href="mailto:adarshdayal10@gmail.com"
            className="text-blue-400 hover:underline"
          >
            adarshdayal10@gmail.com
          </a>
        </div>

        <div className="mt-1">
          <p className="text-gray-300">
            Text the Founder:
             Adarsh Dayalan | 224-440-9977
          </p>
        </div>
      </div>
      {/* <div class="flex justify-center items-center text-gray-500 mt-5">
        Made by Adarsh Dayalan
      </div> */}
    </footer>
  );
};

export default Footer;
