import HereCover from "./imgs/HereChampaignCover.jpeg";
import CampusCircleCover from "./imgs/CampusCircleCover.jpeg";

export const apartments = [
    {
      id: "herechampaign", 
      name: "Here Champaign",
      link: "https://www.herechampaign.com/",
      img: HereCover,
    },
    {
      id: "seven07", 
      name: "Seven07",
      link: "https://liveseven07.com/",
      img: "https://www.opus-group.com/Media/ProjectImages/University-of-Illinois-Luxury-Student-Housing-Development_5934_1000x667.jpg?v=637122198200000000",
    },
    {
      id: "octave", 
      name: "Octave",
      link: "https://liveatoctave.com",
      img: "https://s3.amazonaws.com/rcp-prod-uploads/property_images/webp/2023-01/24a51e3bac4f1a0d1c9643e5591f86ec1d96c723w7.jpg",
    },
    {
      id: "legacy202", 
      name: "Legacy202",
      link: "https://legacy202.com/",
      img: "https://images1.apartments.com/i2/VgB-eXXmGx0WxHzu0RLDnV5YZiAZgueF1Wtvvfx-Yjo/111/legacy202---luxury-student-housing-champaign-il-primary-photo.jpg",
    },
    {
      id: "hubchampaign", 
      name: "Hub Champaign",
      link: "https://huboncampus.com/champaign/",
      img: "https://images1.apartments.com/i2/sroYpwoeHGEHHMqWQn518v1wopoOR1Vw8-KlyqTdJQA/111/hub-champaign-daniel-champaign-il-primary-photo.jpg",
    },
    {
      id: "thedean", 
      name: "The Dean",
      link: "https://www.thedean.com/campustown/",
      img: "https://images.squarespace-cdn.com/content/v1/5cf146ec596d9f00018aa086/1625067975877-JQZ5D0VI89IML32O26A2/HUB_CMP_1A_03.jpg",
    },
    {
      id: "212east", 
      name: "212 East",
      link: "https://www.212east.com/",
      img: "https://www.212east.com/wp-content/uploads/2019/03/212E_signage-2-1.jpg",
    },
    {
      id: "pacificaongreen", 
      name: "Pacifica",
      link: "https://www.pacificaongreen.com/",
      img: "https://medialibrarycfo.entrata.com/18723/MLv3/3/27/2023/11/29/030952/6567b6b04462c7.43647703207.jpg",
    },
    {
      id: "309green", 
      name: "309 Green",
      link: "https://www.americancampus.com/student-apartments/il/champaign/309-green",
      img: "https://s3.amazonaws.com/rcp-prod-uploads/property_images/webp/2021-06/3c322655509d59eaa47ae7e80405b3123bd319363a.jpeg",
    },
    {
      id: "thetoweratthird", 
      name: "The Tower at Third",
      link: "https://www.americancampus.com/student-apartments/il/champaign/tower-at-3rd",
      img: "https://s3.amazonaws.com/rcp-prod-uploads/property_images/webp/2021-06/400418077c3e17ca2f46fd7044104655df7f981ay8.jpeg",
    },  
    {
      id: "thesuitesatthird", 
      name: "The Suites at Third",
      link: "https://www.americancampus.com/student-apartments/il/champaign/the-suites-at-3rd",
      img: "https://www.americancampus.com/getmedia/9457db12-b449-4e14-bd36-0034d3e1b583/411_-01_Gallery_730x547.jpg",
    },
    {
      id: "thevue", 
      name: "The Vue",
      link: "https://www.greenstrealty.com/properties/profile/711-s-fourth-st",
      img: "https://www.greenstrealty.com/image/13892/1000/711+S+Fourth+High+Res+Final_crop_516_619.jpg",
    },
    {
      id: "icon", 
      name: "Icon",
      link: "https://www.iconchampaign.com/",
      img: "https://images1.apartments.com/i2/8iVWI77Npzj1eSM9GPJkGpoEjdDoL3lVq1yYtDuE64w/111/icon---brand-new-champaign-il-primary-photo.jpg",
    },
    // {
    //   id: "midway", 
    //   name: "Midway",
    //   link: "https://www.greenstrealty.com/on-campus/new-construction/property/901-s-fourth-champaign-il-1",
    //   img: "https://images1.apartments.com/i2/xLJbcWsm-TdUSONZu0FIEj0HQlfVjYoN1FyGM5IxJfo/111/midway-champaign-il-primary-photo.jpg",
    // },
    {
      id: "greenstreettowers", 
      name: "Green Street Towers",
      link: "https://jsmliving.com/node/130567",
      img: "https://jsmliving.com/sites/default/files/2022-07/616%20E%20Green%20Unit%20610%20Champaign-large-001-044-Exterior-1500x1000-72dpi.jpg",
    },
    {
      id: "lofts54", 
      name: "Lofts 54",
      link: "https://www.americancampus.com/student-apartments/il/champaign/lofts54",
      img: "https://s3.amazonaws.com/rcp-prod-uploads/property_images/webp/2021-06/89bdb075b5dd73c6529888c3f129421e40679257oS.jpeg",
    },
    {
      id: "latitude", 
      name: "Latitude",
      link: "https://www.livelatitude.com/",
      img: "https://55f7e2ead9af3dc63bae.b-cdn.net/wp-content/uploads/2022/07/latitude-apartments-sfo-capital-1.jpg",
    },
    {
      id: "burnham310", 
      name: "Burnham 310",
      link: "https://burnham310.com/",
      img: "https://images1.apartments.com/i2/WlRe1kJNvogC7KZr5MhBQR9v0--TeKinTw8hjJYZ_tc/111/burnham-310---off-campus-luxury-living-champaign-il-building-photo.jpg",
    },
    {
      id: "westquad", 
      name: "West Quad",
      link: "https://www.westquad.com/",
      img: "https://rentpath-res.cloudinary.com/$img_current/t_3x2_webp_2xl/t_unpaid/e1ae17d07fb47d72e14982cc8ac70713",
    },
    {
      id: "campuscircle", 
      name: "Campus Circle",
      link: "https://www.livecampuscircle.com",
      img: CampusCircleCover
    },
    {
      id: "75armory", 
      name: "75 Armory",
      link: "https://www.75armory.com/",
      img: "https://images1.apartments.com/i2/f-zIpvO6hM85hly7NPoWDxDWvjSfFpijTPC8f-nXWlw/111/75-armory-champaign-il-primary-photo.jpg",
    },
    {
      id: "507second", 
      name: "507 Second",
      link: "https://www.507second.com/",
      img: "https://medialibrarycfo.entrata.com/fit-in/450x450/16593/MLv3/3/27/2022/2/24/31563/3185_1111190_5a0b45ba89b97340.jpg",
    },
    {
      id: "305daniel", 
      name: "305 Daniel",
      link: "https://www.305daniel.com/",
      img: "https://images1.apartments.com/i2/fZNU7cjbkeU4O_FxgZ0qD5oXobb3fk92l80jXFYcqKw/111/305-daniel-champaign-il-primary-photo.jpg",
    },
    {
      id: "901western", 
      name: "901 Western",
      link: "https://www.901western.com/",
      img: "https://medialibrarycfo.entrata.com/16593/MLv3/3/27/2022/2/24/31645/3193_1111187_5a0b5223230f1186.jpg",
    },
  
    {
      id: "yugochampaign", 
      name: "Yugo Champaign",
      link: "https://yugo.com/en-us/global/united-states-of-america/champaign-il/yugo-champaign-south-3rd-lofts",
      img: "https://images1.apartments.com/i2/B0V1cLoFjzEmPGyknz0hdYRCXEFTaq7hPTVfdKy9H-Y/111/yugo-champaign-south-3rd-lofts-champaign-il-building-photo.jpg",
    },
    {
      id: "yugourbana", 
      name: "Yugo Urbana",
      link: "https://yugo.com/en-us/global/united-states-of-america/urbana-il/yugo-urbana-illinois",
      img: "https://images1.apartments.com/i2/vvIQvFM2cq99-CWnN03g-Whl3znN0NDAdAwpIlrazvI/111/yugo-urbana-illinois-urbana-il-primary-photo.jpg",
    },
    {
      id: "maywoodapts", 
      name: "Maywood Apartments",
      link: "https://maywoodapts.com/",
      img: "https://images1.apartments.com/i2/EFJOEYomIt-3ywjuy8QzjyCO7tnDsMvlZPVDYUTlpmU/116/maywood-apartments-champaign-il-primary-photo.jpg?p=1"
    },
  
  
    // Apartment Groups
    {
      id: "theacademycampustown", 
      name: "The Academy Campustown",
      listings: 4,
      link: "https://www.theacademycampustown.com/",
    },
    {
      id: "greenstreetrealty", 
      name: "Green Street Realty",
      listings: 148,
      link: "https://www.greenstrealty.com/properties/search/area/on-campus",
    },
    {
      id: "smilestudentliving", 
      name: "Smile Student Living",
      listings: 184,
      link: "https://www.smilestudentliving.com/availability",
    },
    {
      id: "jsmapartments", 
      name: "JSM Apartments",
      listings: 190,
      link: "https://jsmliving.com/search-available-units",
    },
    {
      id: "universitygroup", 
      name: "University Group",
      listings: 161,
      link: "https://ugroupcu.com/apartment-search/",
    },
    {
      id: "mhmapartments", 
      name: "MHM Apartments",
      listings: 18,
      link: "https://www.mhmproperties.com/apartments/",
    },
    {
      id: "rolandrealty", 
      name: "Roland Realty",
      listings: 27,
      link: "https://www.roland-realty.com/",
    },
    {
      id: "bankierapartments", 
      name: "Bankier Apartments",
      listings: 12,
      link: "https://bankierapartments.com/properties/",
    },
    {
      id: "weinercompanies", 
      name: "Weiner Companies",
      listings: 15,
      campusListings: 6,
      link: "https://www.weinercompanies.com/brokerage-info/commercial-rentals/",
    },
    {
      id: "ramshaw", 
      name: "Ramshaw",
      listings: "~40",
      campusListings: "~25",
      link: "https://ramshaw.com/apartments-uiuc-campus/",
    },
    {
      id: "jsj", 
      name: "JSJ",
      listings: "~55",
      campusListings: "~25",
      link: "https://jsjmanagement.com/on-campus/query/bedrooms/any/bathrooms/any/types/any/available/any/price/any",
    },
    {
      id: "baileyapartments", 
      name: "Bailey Apartments",
      listings: 5,
      link: "https://baileyapartments.com/apartment/",
    },
  ];