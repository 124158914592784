import { useEffect, useRef } from "react";

const useGoogleMapElements = (map, center, polygonData) => {
  const polygonsRef = useRef([]);
  const markerRef = useRef(null);

  useEffect(() => {
    const initializeMarker = async () => {
      if (!map || !window.google || !center) return;
      
      const { google } = window;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      if (!markerRef.current) {
        markerRef.current = new AdvancedMarkerElement({
          position: center,
          map,
        });
      } else {
        markerRef.current.position = center;
      }
    };

    const initializePolygon = async () => {
      if (!map || !window.google) return;

      const { google } = window;
      const { Polygon } = await google.maps.importLibrary("maps");

      // Clear existing polygons
      polygonsRef.current.forEach((polygon) => polygon.setMap(null));
      polygonsRef.current = [];

      // Create new polygons
      polygonData.forEach((data) => {
        const polygon = new Polygon({
          paths: data.coords,
          map,
          fillColor: data.color,
          fillOpacity: 0.2,
          strokeColor: data.color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
          clickable: false,
          editable: false,
          draggable: false,
        });
        polygonsRef.current.push(polygon);
      });
    };

    initializeMarker();
    initializePolygon();

    return () => {
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
      polygonsRef.current.forEach((polygon) => polygon.setMap(null));
      polygonsRef.current = [];
    };
  }, [map, center, polygonData]);

  return { marker: markerRef.current, polygon: polygonsRef.current };
};

export default useGoogleMapElements;
