import { PiDotsNineBold } from "react-icons/pi";
import React, { useState } from "react";
import Slider from "react-slick";

import ImageSkeleton from "./ImageSekeleton";

const ImageGallery = ({ images, onClick }) => {
  const displayedImages = images.slice(0, 5);
  const [loading, setLoading] = useState(images.map(() => true));
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageLoad = (index) => {
    setLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
  };

  return (
    <div className="overflow-hidden md:rounded-lg">
      <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-2 h-full">
        <div className="col-span-1 md:col-span-1 relative">
        {/* <ImageSkeleton /> */}
          {loading[0] && <ImageSkeleton />}
          <img
            src={displayedImages[0]}
            alt="Main Image"
            className={`w-full h-96 md:h-[26rem] 2xl:h-[32rem] object-cover cursor-pointer hover:opacity-85 transition-opacity duration-300 ${loading[0] ? 'hidden' : 'block'}`}
            onLoad={() => handleImageLoad(0)}
            onClick={onClick}
          />
        </div>
        <div className="col-span-1 md:col-span-1 grid grid-cols-2 gap-2 h-96 md:h-[26rem] 2xl:h-[32rem] relative">
          {displayedImages.slice(1).map((src, index) => (
            <div key={index} className="relative `w-full h-full h-52">
              {loading[index + 1] && <ImageSkeleton />}
              <img
                src={src}
                alt={`Apartment Image ${index + 1}`}
                className={`w-full h-full max-h-52 h-52 object-cover cursor-pointer hover:opacity-85 transition-opacity duration-300 ${loading[index + 1] ? 'hidden' : 'block'}`}
                onLoad={() => handleImageLoad(index + 1)}
                onClick={onClick}
              />
              {index === displayedImages.slice(1).length - 1 && (
                <button
                  onClick={onClick}
                  className="absolute flex items-center justify-center gap-x-2 bottom-4 right-4 bg-white border border-black text-sm text-black font-medium text-center px-3 py-2 rounded-md"
                >
                  <PiDotsNineBold className="w-5 h-5" />
                  View All Photos
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="md:hidden relative">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              {loading[index] && <ImageSkeleton />}
              <img
                src={image}
                alt={`Apartment Image ${index + 1}`}
                className={`w-full h-[30rem] object-cover cursor-pointer ${loading[index] ? 'hidden' : 'block'}`}
                onLoad={() => handleImageLoad(index)}
                onClick={onClick}
              />
            </div>
          ))}
        </Slider>
        <div className="absolute bottom-4 right-2 bg-black bg-opacity-50 text-white text-xs text-center px-2 py-1 rounded-sm">
          {currentIndex + 1} / {images.length}
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;

