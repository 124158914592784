import React, { useState, useEffect } from "react";
import { fetchUserData, updateContactInfo } from "../backend/dbAPI"; // Adjust the path as needed
import { sendTourBookEmail } from "../backend/emailAPI";

const BookTourModal = ({ userId, apartment, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [agreed, setAgreed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  const apartmentEmail = apartment.email;
  const apartmentPropertyName = apartment.name;
  const apartmentPhone = apartment.phone;
  const leasingOfficeAddress = apartment.leasingAddress;
  const apartmentWebsite = apartment.websiteLink;
  const apartmentImage = apartment.images[0];

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId) {
        const userData = await fetchUserData(userId);
        setUserData(userData);
        if (userData) {
          setFormData({
            name: userData.firstName + " " + userData.lastName,
            email: userData.email || "",
            phone: userData.phone || "",
            preferredContact: userData.preferredContact || "phone",
            description: "",
          });
        }
      }
    };

    fetchUserInfo();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (agreed && formData.email && formData.phone && formData.description) {
      try {
        const response = await sendTourBookEmail(
          formData,
          apartmentEmail,
          apartmentPropertyName
        );
        const result = await response.json();

        if (response.ok) {
          setSubmitted(true);
          if (
            userData.email !== formData.email ||
            userData.phone !== formData.phone ||
            userData.preferredContact !== formData.preferredContact
          ) {
            if (userId) {
              await updateContactInfo(userId, {
                email: formData.email,
                phone: formData.phone,
                preferredContact: formData.preferredContact,
              });
            }
          }
        } else {
          setError(result.message || "Failed to send email");
        }
      } catch (err) {
        setError("Failed to send email");
      }
    } else {
      setError("Please fill in all required fields and agree to the terms.");
    }
    setLoading(false);
  };

  const renderContactDetails = () => (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold mb-2">Tour Booking Details</h2>
      <p className="text-md">
        Reach out to the apartment directly to book a tour. Visit their website
        or directly call their friendly front desk :).
      </p>
      {apartmentWebsite && (
        <p>
          <strong>Website:</strong>{" "}
          <a
            href={apartmentWebsite}
            className="underline text-logo_blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {apartmentWebsite}
          </a>
        </p>
      )}
      {apartmentPhone && (
        <p>
          <strong>Phone:</strong> {apartmentPhone}
        </p>
      )}
      {leasingOfficeAddress && (
        <p>
          <strong>Leasing Office:</strong> {leasingOfficeAddress}
        </p>
      )}

      {apartmentEmail && (
        <p>
          <strong>Email:</strong>{" "}
          <a href={`mailto:${apartmentEmail}`} className="text-logo_blue">
            {apartmentEmail}
          </a>
        </p>
      )}

      <button
        onClick={onClose}
        className="mt-4 bg-logo_blue text-white px-4 py-2 rounded hover:bg-logo_blue_dark transition-colors"
      >
        Close
      </button>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
      <div className="relative bg-white p-6 rounded-lg shadow-xl max-w-4xl w-full">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {submitted ? (
          <div className="text-center">
            <h2 className="text-xl font-bold mb-4">Thank you!</h2>
            <p>
              The front desk has been notified and will be in contact with you
              shortly to confirm availability and book a tour!
            </p>
            <button
              onClick={onClose}
              className="mt-4 bg-logo_blue text-white px-4 py-2 rounded hover:bg-logo_blue_dark transition-colors"
            >
              Close
            </button>
          </div>
        ) : apartmentEmail ? (
          <div className="flex flex-col lg:flex-row lg:space-x-8">
            {/* Apartment Info Section */}
            <div className="flex-shrink-0 lg:w-2/5 mb-6 lg:mb-0">
              <h3 className="text-lg font-bold text-gray-900 mb-2">
                {apartmentPropertyName}
              </h3>

              {apartmentImage && (
                <img
                  src={apartmentImage}
                  alt={apartmentPropertyName}
                  className="rounded-lg object-cover w-full h-48 lg:h-auto"
                />
              )}

              {apartmentPhone && (
                <div className="mt-4">
                  <h4 className="text-md text-gray-700">
                    Prefer to Speak Directly?
                  </h4>
                  <div>
                    <h1>
                      Call Now:{" "}
                      <a
                        href={`tel:${apartmentPhone}`}
                        className="text-logo_blue underline hover:text-logo_blue_dark transition-colors"
                      >
                        {apartmentPhone}
                      </a>
                    </h1>
                  </div>
                </div>
              )}
            </div>

            {/* Form Section */}
            {apartmentEmail === "jsm@jsmliving.com" ? (
              <div className="flex-grow space-y-4 lg:w-3/5">
                <h2 className="text-xl font-bold mb-4">Call to Book a Tour</h2>
                <p className="text-md">
                  To book a tour, please call JSM directly at{" "}
                  <a
                    href={`tel:${apartmentPhone}`}
                    className="text-logo_blue underline hover:text-logo_blue_dark transition-colors"
                  >
                    {apartmentPhone}
                  </a>{" "}
                  and mention that you saw the listing on Abode for context.
                </p>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="flex-grow space-y-4 lg:w-3/5"
              >
                <div>
                  <h2 className="text-xl font-bold mb-4">Book a Tour</h2>

                  <h2 className="text-md">
                    Apartment's front desk will quickly confirm availability.
                  </h2>

                  <h2 className="text-md">
                    Avg response time:{" "}
                    <span className="font-medium">Instant - end of day!</span>
                  </h2>
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  placeholder="Full Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  placeholder="Personal Email"
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  placeholder="Phone Number"
                  required
                />
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full h-32 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue"
                  placeholder="Please specify your preferred floorplan/# of beds, tour date, and time. (Usually available M-F from 9am-5pm)"
                  required
                />
                <div className="mt-4">
                  <label className="block text-md font-medium text-gray-700">
                    Preferred Contact Method
                  </label>
                  <select
                    name="preferredContact"
                    value={formData.preferredContact}
                    onChange={handleChange}
                    className="mt-2 block w-full border pl-3 pr-10 py-2 text-base border-gray-200 sm:text-sm rounded-md"
                  >
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                  </select>
                </div>

                {error && <p className="text-red-500">{error}</p>}
                <button
                  type="submit"
                  className="w-full bg-logo_blue text-white px-4 py-2 rounded-lg hover:bg-logo_blue_dark transition-colors disabled:bg-gray-400"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
            )}
          </div>
        ) : (
          renderContactDetails()
        )}
      </div>
    </div>
  );
};

export default BookTourModal;
