import React, { useState } from "react";

const LivingHabitsQuestionnaire = ({ livingHabits, setLivingHabits }) => {
  const questions = [
    {
      question: "How clean are you?",
      leftText: "A little mess never hurt anybody... 🧹",
      rightText: "Neat freak 🧼",
    },
    {
      question: "What kind of bird are you?",
      leftText: "Night owl 🦉",
      rightText: "Early bird 🐦",
    },
    {
      question: "How do you like your guests?",
      leftText: "Me, myself, and I 🧘",
      rightText: "Friends over 24/7 🎉",
    },
    {
      question: "How do you feel about noise levels?",
      leftText: "Quiet Environment 🤫",
      rightText: "Like a lively space 🎶",
    },
    {
      question: "How often do you cook?",
      leftText: "Eat out every day 🍔",
      rightText: "I'm cheffing in the kitchen 24/7 👨‍🍳",
    },
    {
      question: "Temperature Preference?",
      leftText: "Like it cold 🥶",
      rightText: "I want it toasty 🥵",
    },
  ];

  const questionKeys = [
    "cleanliness",
    "birdType",
    "guestPreference",
    "noiseLevel",
    "cookingFrequency",
    "temperaturePreference",
  ];

  const [answers, setAnswers] = useState(
    livingHabits
      ? livingHabits
      : questionKeys.reduce((acc, key) => ({ ...acc, [key]: null }), {})
  );

  const handleAnswerChange = (index, value) => {
    const key = questionKeys[index];
    const newAnswers = { ...answers, [key]: value };
    setAnswers(newAnswers);
    setLivingHabits(newAnswers);
    // console.log(newAnswers);
  };

  return (
    <div className="">
      <div className="bg-white rounded-lg w-full p-8">
        <h2 className="text-2xl font-bold mb-4">Living Habits</h2>
        <div className="grid grid-cols-1 gap-x-24 gap-y-12">
          {questions.map((q, index) => (
            <div key={index} className="mb-6">
              <label
                className={`mb-2 text-md font-medium ${"flex items-center justify-center"}`}
              >
                {q.question}
              </label>
              <div className="flex items-center justify-between">
                {q.leftText && (
                  <span className="text-md text-gray-600 w-1/4">
                    {q.leftText}
                  </span>
                )}
                <div className="flex items-center justify-center space-x-2 w-1/2">
                  {[1, 2, 3, 4].map((value) => (
                    <button
                      key={value}
                      onClick={() => handleAnswerChange(index, value)}
                      className={`flex items-center justify-center text-lg transform transition-transform duration-200 ${
                        answers[questionKeys[index]] === value
                          ? "bg-logo_blue text-white"
                          : "border-2 border-gray-500 text-gray-700"
                      }`}
                      style={{
                        width:
                          value === 1 || value === 4
                            ? "50px"
                            : value === 2 || value === 3
                            ? "40px"
                            : "50px",
                        height:
                          value === 1 || value === 4
                            ? "50px"
                            : value === 2 || value === 3
                            ? "40px"
                            : "50px",

                        borderRadius: "50%",
                      }}
                    ></button>
                  ))}
                </div>
                {q.rightText && (
                  <span className="text-md text-gray-600 w-1/4 text-right">
                    {q.rightText}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LivingHabitsQuestionnaire;
