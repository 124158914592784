export const preferenceDescriptions = {
  cleanliness: (value) => {
    switch (value) {
      case 4:
        return "both are very neat and organized";
      case 3:
        return "both prefer to keep things moderately tidy";
      case 2:
        return "both are okay with some clutter";
      case 1:
        return "both are very relaxed about cleanliness";
      default:
        return "both have a cleanliness preference";
    }
  },
  birdType: (value) => {
    switch (value) {
      case 4:
        return "both are early birds";
      case 3:
        return "both are morning people";
      case 2:
        return "both are night owls";
      case 1:
        return "both are late-night enthusiasts";
      default:
        return "both have similar sleep schedules";
    }
  },
  guestPreference: (value) => {
    switch (value) {
      case 1:
        return "both prefer minimal guests";
      case 2:
        return "both are okay with occasional guests";
      case 3:
        return "both enjoy having guests over regularly";
      case 4:
        return "both love to entertain and have guests frequently";
      default:
        return "both have similar guest preferences";
    }
  },
  noiseLevel: (value) => {
    switch (value) {
      case 1:
        return "both prefer a very quiet environment";
      case 2:
        return "both like moderately quiet noise levels";
      case 3:
        return "both enjoy a lively and somewhat noisy space";
      case 4:
        return "both thrive in a bustling, noisy environment";
      default:
        return "both have similar noise level preferences";
    }
  },
  cookingFrequency: (value) => {
    switch (value) {
      case 4:
        return "both love to cook frequently";
      case 3:
        return "both cook occasionally";
      case 2:
        return "both prefer to eat out or order in most of the time";
      case 1:
        return "both rarely cook and prefer to eat out";
      default:
        return "both have similar cooking habits";
    }
  },
  temperaturePreference: (value) => {
    switch (value) {
      case 1:
        return "both prefer a very cool environment";
      case 2:
        return "both like it moderately cool";
      case 3:
        return "both prefer a warm atmosphere";
      case 4:
        return "both like it very warm and cozy";
      default:
        return "both have similar temperature preferences";
    }
  },
};

export const lifestylePreferenceDescriptions = {
  alcohol: (value) => {
    switch (value) {
      case "No":
        return "both don't drink alcohol";
      case "Occasionally":
        return "both drink alcohol occasionally";
      case "Yes":
        return "both drink alcohol";
      default:
        return "both have similar alcohol preferences";
    }
  },
  smoke: (value) => {
    switch (value) {
      case "No":
        return "both don't smoke";
      case "Occasionally":
        return "both smoke occasionally";
      case "Yes":
        return "both smoke";
      default:
        return "both have similar smoking preferences";
    }
  },
  greekLife: (value) => {
    switch (value) {
      case "No":
        return "neither are involved in Greek life";
      case "Maybe":
        return "both are considering Greek life";
      case "Yes":
        return "both are involved in Greek life";
      default:
        return "both have similar Greek life preferences";
    }
  },
  social: (value) => {
    switch (value) {
      case "I prefer time alone 🧘‍♂️":
        return "both prefer time alone";
      case "A small gathering is nice 👫":
        return "both prefer small gatherings";
      case "I enjoy meeting new people 🎉":
        return "both enjoy meeting new people";
      case "Social butterfly, always out and about 🦋":
        return "both are social butterflies";
      default:
        return "both have similar social preferences";
    }
  },
};

