import React, { useState } from "react";

// Importing images for Bankier and JSM icons
import bankierIcon from "../../data/icons/apt_icons/bankier-icon.png";
import mhmIcon from "../../data/icons/apt_icons/mhm-icon.png";
import ugroupIcon from "../../data/icons/apt_icons/ugroup-icon.png";
import gstIcon from "../../data/icons/apt_icons/gst-icon.png";
import jsmIcon from "../../data/icons/apt_icons/jsm-icon.png";
// import smileIcon from "../../data/icons/apt_icons/smile-icon.png";
import academyIcon from "../../data/icons/apt_icons/academy-icon.png";
// import rolandIcon from "../../data/icons/apt_icons/roland-icon.png";
// import ramshawIcon from "../../data/icons/apt_icons/ramshaw-icon.png";
import { logEvent, analytics, auth} from "../../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";

function IconHeader({ filters, setFilters }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [my_user] = useAuthState(auth);

  const apartmentGroups = [
    { displayName: "The Academy Campustown", id: "academy" },
    { displayName: "Bankier Apartments", id: "bankier" },
    { displayName: "University Group", id: "ugroup" },
    { displayName: "MHM Properties", id: "mhm" },
    { displayName: "JSM Living", id: "jsm" },
    { displayName: "Green Street Realty", id: "gsr" },
    { displayName: "Smile Student Living", id: "smile" },
    { displayName: "Roland Realty", id: "roland" },
    { displayName: "Ramshaw Real Estate", id: "ramshaw" },
    // { displayName: "JSJ Property Managment", id: "jsj" },
    // { displayName: "Bailey Apartments", id: "bailey" },
  ];

  // Use image paths for specific icons
  const icons = {
    JSM: jsmIcon,
    Bankier: bankierIcon,
    GSR: gstIcon,
    Academy: academyIcon,
    MHM: mhmIcon,
    UGroup: ugroupIcon,
    
    // Group: gsrIcon,
    
    // Smile: smileIcon,
    // Roland: rolandIcon,
    // Ramshaw: ramshawIcon,
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const onIconClick = (newApartmentType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      apartmentType: newApartmentType,
    }));
    if (my_user) {
      logEvent(analytics, "group_icon_click", {
        apartmentType: newApartmentType,
        userId: my_user.uid,
        timestamp: Date.now(),
      });
    }
  };

  const handleGroupClick = (group) => {
    setFilters((prevFilters) => {
      const newApartmentGroups = prevFilters.apartmentGroups.includes(group.id)
        ? prevFilters.apartmentGroups.filter((g) => g !== group.id)
        : [...prevFilters.apartmentGroups, group.id];

      return {
        ...prevFilters,
        apartmentGroups: newApartmentGroups,
      };
    });
  };

  const handleSearchSubmit = () => {
    // Handle search query submission logic here, possibly passing it to a parent component or API
    // console.log("User Search Query:", searchQuery);
    setIsModalOpen(false); // Close modal after submission
  };

  return (
    <div className="flex px-4 md:px-16 mx-auto justify-between mt-4 items-end max-w-full overflow-x-auto">
      <div className="flex justify-start space-x-8">
        <div
          onClick={() => onIconClick("Apartments")}
          className={`flex flex-col items-center cursor-pointer hover:text-black ${
            filters.apartmentType === "Apartments"
              ? "text-black"
              : "text-gray-500"
          }`}
        >
          <div className="flex flex-col items-center">
            <i className={`fas fa-building text-lg`}></i>
            <span
              className={`text-xs font-semibold my-2 ${
                filters.apartmentType === "Apartments"
                  ? "selected-text"
                  : "preselect-text"
              }`}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {"Apartments"}
            </span>

            <div
              className={`w-16 ${
                filters.apartmentType === "Apartments"
                  ? "border-b-2 border-black"
                  : ""
              }`}
            ></div>
          </div>
        </div>
        {Object.entries(icons).map(([type, iconClass]) => (
          <div
            key={type}
            onClick={() => onIconClick(type)}
            className={`flex flex-col items-center cursor-pointer hover:text-black ${
              filters.apartmentType === type ? "text-black" : "text-gray-500"
            }`}
          >
            <div className="flex flex-col items-center">
              <img
                src={iconClass}
                alt={type}
                className={`icon max-w-6 h-6 object-cover${
                  filters.apartmentType === type ? "selected" : "preselect"
                }`}
              />

              {/* {/\.(png|jpe?g|svg|webp)$/.test(iconClass) ? (
        // If the iconClass is a path to an image file
        
        <h1>JJJ</h1>
      ) : (
        // If the iconClass is a FontAwesome class
        <i className={`${iconClass} text-lg`}></i>
      )} */}
              <span
                className={`text-xs font-semibold my-2 ${
                  filters.apartmentType === type
                    ? "selected-text"
                    : "preselect-text"
                }`}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {type}
              </span>

              <div
                className={`w-16 ${
                  filters.apartmentType === type
                    ? "border-b-2 border-black"
                    : ""
                }`}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex-grow overflow-auto">
        {/* Content for the scrollable div */}
        {/* <FilterButtons /> */}
      </div>
      <div className="flex flex-col items-end">
        {/* <button className="underline" onClick={() => setIsModalOpen(true)}>
          <div className="text-sm font-semibold self-start">
            What are you looking for?
          </div>
        </button> */}
        {filters.apartmentType === "Apartment Groups" && (
          <button
            className="underline mt-2"
            onClick={() => setIsGroupModalOpen(true)}
          >
            <div className="text-sm font-semibold self-end">
              Filter Management
            </div>
          </button>
        )}
      </div>

      {isGroupModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full relative">
            <h2 className="text-xl mb-4">Filter Apartment Management Groups</h2>
            <div className="grid grid-cols-3 gap-4">
              {apartmentGroups.map((group) => (
                <button
                  key={group.id}
                  onClick={() => handleGroupClick(group)}
                  className={`px-4 py-2 rounded-lg border ${
                    filters.apartmentGroups.includes(group.id)
                      ? "bg-white text-black border-black"
                      : "bg-white text-gray-400 border-gray-200 hover:bg-gray-100"
                  }`}
                >
                  {group.displayName}
                </button>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-logo_blue text-white rounded"
                onClick={() => setIsGroupModalOpen(false)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute text-lg top-0 right-0 p-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              &times;
            </button>
            <h2 className="text-xl font-medium">
              Tell us what you are looking for
            </h2>
            <h2 className="text-sm mb-4">
              Experimental AI feature (functional Fall 2024 😉)
            </h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4"
              rows="5"
              placeholder="e.g., 2-bedroom apartment under $1400, close to engineering classes. High-end, great gym."
              value={searchQuery}
              onChange={handleInputChange}
            ></textarea>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-logo_blue text-white rounded hover:bg-blue-600"
                onClick={handleSearchSubmit}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IconHeader;
