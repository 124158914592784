import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);



    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      const from = location.state?.from || "/listings";
      navigate(from);
    } catch (error) {
      setLoginError(error.message);
      setLoginLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!formData.email) {
      setResetError("Please enter your email first.");
      return;
    }
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for
      // this URL must be whitelisted in the Firebase Console.
      url: 'https://www.getabode.co/login',
      // This must be true for email link sign-in.
      handleCodeInApp: true,
    };

    try {
      await sendPasswordResetEmail(auth, formData.email, actionCodeSettings);
      setResetSuccess("Password reset email sent! Check your inbox.");
      setResetError("");
    } catch (error) {
      setResetError(error.message);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center justify-center">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-2">Welcome to Abode!</h2>
        <p className="text-md text-gray-500">
          View every on-campus apartment here. <br />
          For UIUC students, by UIUC students.
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full my-12">
        <h2 className="text-xl font-bold text-center mb-4">Log In</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label htmlFor="email" className="block font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="johndoe@gmail.com"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            disabled={loginLoading}
            className={`w-full text-white py-2 px-4 rounded-md transition-colors ${
              loginLoading ? "bg-blue-400" : "bg-logo_blue hover:bg-blue-800"
            }`}
          >
            {loginLoading ? "Loading..." : "Log In"}
          </button>
          {loginError && (
            <div className="text-red-500 text-center mt-2">{loginError}</div>
          )}
        </form>

        {/* Forgot Password Section */}
        <div className="mt-4 text-center">
          {/* <p className="text-gray-500">Forgot your password?</p> */}
          <button
            onClick={handlePasswordReset}
            className="text-blue-600 hover:underline"
          >
            Forgot your password?
          </button>
          {resetError && <div className="text-red-500 mt-2">{resetError}</div>}
          {resetSuccess && <div className="text-green-500 mt-2">{resetSuccess}</div>}
        </div>
      </div>
    </div>
  );
};
