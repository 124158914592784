import {
    FaHiking,
    FaMusic,
    FaCamera,
    FaGamepad,
    FaBasketballBall,
    FaTableTennis,
    FaDumbbell,
    FaSwimmer,
    FaVolleyballBall,
    FaBiking,
    FaRunning,
    FaChess,
    FaFilm,
    FaTheaterMasks,
    FaGuitar,
    FaPlane,
    FaTree,
    FaHeart,
    FaBeer,
    FaUtensils,
    FaMedkit,
    FaPaw,
    FaPray,
    FaSpa,
    FaCar,
    FaShoppingCart,
    FaTshirt,
    FaHashtag,
    FaLightbulb,
    FaMicrophone,
    FaNewspaper,
    FaPenFancy,
    FaLeaf,
    FaPodcast,
    FaOm,
    FaMicrophoneAlt,
    FaFistRaised,
    FaHandPaper,
    FaPaintRoller,
    FaMoneyBill,
    FaGlobe,
    FaRobot,
    FaStar,
    FaFlag,
    FaBolt,
    FaBullseye,
    FaDice,
    FaHamburger,
    FaMoneyBillWave,
    FaBrain,
    FaLaptopCode,
    FaPaintBrush,
    FaChartLine,
    FaDna,
    FaCog,
    FaBookOpen,
    FaLandmark,
    FaGavel,
    FaMedal,
    FaUniversity,
    FaGlassCheers,
    FaBaseballBall,
    FaFootballBall,
    FaGolfBall,
    FaHockeyPuck,
    FaSkating,
    FaSnowboarding,
    FaSkiing,
    FaCarCrash,
    FaFish,
    FaArrowCircleRight,
    FaBowlingBall,
  } from "react-icons/fa";
  
  import { RiBoxingFill, RiMedicineBottleLine } from "react-icons/ri";
  import {
    MdOutlineSkateboarding,
    MdSurfing,
    MdOutlineSportsGymnastics,
    MdOutlineSportsMartialArts,
    MdSportsCricket,
    MdSportsTennis,
    MdOutlinePiano,
  } from "react-icons/md";
  import { IoIosMicrophone } from "react-icons/io";
  import { GiMeditation, GiMountainClimbing } from "react-icons/gi";
  import { BsPersonArmsUp } from "react-icons/bs";
  import { FaMoneyBillTrendUp } from "react-icons/fa6";
  

export const InterestsList = {
    Sports: [
      { name: "Basketball", icon: <FaBasketballBall /> },
      { name: "American Football", icon: <FaFootballBall /> },
      { name: "Baseball", icon: <FaBaseballBall /> },
      { name: "Bowling", icon: <FaBowlingBall /> },
      { name: "Cricket", icon: <MdSportsCricket /> },
      { name: "Golf", icon: <FaGolfBall /> },
      { name: "Hockey", icon: <FaHockeyPuck /> },
      { name: "Tennis", icon: <MdSportsTennis /> },
      { name: "Volleyball", icon: <FaVolleyballBall /> },
      { name: "Table Tennis", icon: <FaTableTennis /> },
      { name: "Boxing", icon: <RiBoxingFill /> },
      { name: "Martial Arts", icon: <MdOutlineSportsMartialArts /> },
      { name: "Wrestling", icon: <FaFistRaised /> },
      { name: "Cycling", icon: <FaBiking /> },
      { name: "Running", icon: <FaRunning /> },
      { name: "Swimming", icon: <FaSwimmer /> },
      { name: "Gymnastics", icon: <MdOutlineSportsGymnastics /> },
      { name: "Surfing", icon: <MdSurfing /> },
      { name: "Skiing", icon: <FaSkiing /> },
      { name: "Snowboarding", icon: <FaSnowboarding /> },
      { name: "Ice Skating", icon: <FaSkating /> },
      { name: "Skateboarding", icon: <MdOutlineSkateboarding /> },
      { name: "Fishing", icon: <FaFish /> },
      { name: "Archery", icon: <FaBullseye /> },
      { name: "Climbing", icon: <GiMountainClimbing /> },
    ],
  
    "Arts & Entertainment": [
      { name: "Art", icon: <FaPaintBrush /> },
      { name: "Crafting", icon: <FaHandPaper /> },
      { name: "Photography", icon: <FaCamera /> },
      { name: "Music", icon: <FaMusic /> },
      { name: "Guitar", icon: <FaGuitar /> },
      { name: "Piano", icon: <MdOutlinePiano /> },
      { name: "Singing", icon: <IoIosMicrophone /> },
      { name: "Dance", icon: <BsPersonArmsUp /> },
      { name: "Theater", icon: <FaTheaterMasks /> },
      { name: "Movies", icon: <FaFilm /> },
      { name: "Podcasting", icon: <FaPodcast /> },
      { name: "Board Games", icon: <FaDice /> },
      { name: "Gaming", icon: <FaGamepad /> },
    ],
    "Academic & Intellectual": [
      { name: "Coding", icon: <FaLaptopCode /> },
      { name: "Technology", icon: <FaLaptopCode /> },
      { name: "Engineering", icon: <FaCog /> },
      { name: "Robotics", icon: <FaRobot /> },
      { name: "DIY Electronics", icon: <FaBolt /> },
      { name: "Astronomy", icon: <FaStar /> },
      { name: "Biology", icon: <FaDna /> },
      { name: "Environmentalism", icon: <FaLeaf /> },
      { name: "Medicine", icon: <RiMedicineBottleLine /> },
      { name: "Psychology", icon: <FaBrain /> },
      { name: "Political Science", icon: <FaGavel /> },
      { name: "Economics", icon: <FaChartLine /> },
      { name: "Finance", icon: <FaMoneyBillTrendUp /> },
      { name: "Investing", icon: <FaMoneyBill /> },
      { name: "Entrepreneurship", icon: <FaLightbulb /> },
      { name: "Graphic Design", icon: <FaPaintRoller /> },
      { name: "Journalism", icon: <FaNewspaper /> },
      { name: "Languages", icon: <FaGlobe /> },
      { name: "History", icon: <FaLandmark /> },
      { name: "Model UN", icon: <FaFlag /> },
      { name: "Debate", icon: <FaMicrophone /> },
      { name: "Public Speaking", icon: <FaMicrophoneAlt /> },
      { name: "Chess", icon: <FaChess /> },
      { name: "Reading", icon: <FaBookOpen /> },
      { name: "Writing", icon: <FaPenFancy /> },
    ],
    "Lifestyle & Personal Development": [
      { name: "Cars", icon: <FaCar /> },
      { name: "Cooking", icon: <FaUtensils /> },
      { name: "Foodie", icon: <FaHamburger /> },
      { name: "Fashion", icon: <FaTshirt /> },
      { name: "Fitness", icon: <FaDumbbell /> },
      { name: "Health & Wellness", icon: <FaMedkit /> },
      { name: "Hiking", icon: <FaHiking /> },
      { name: "Meditation", icon: <GiMeditation /> },
      { name: "Nature", icon: <FaTree /> },
      { name: "Partying", icon: <FaGlassCheers /> },
      { name: "Pets <3", icon: <FaPaw /> },
      { name: "Self Improvement", icon: <FaMedal /> },
      { name: "Shopping", icon: <FaShoppingCart /> },
      { name: "Social Media", icon: <FaHashtag /> },
      { name: "Spa & Relaxation", icon: <FaSpa /> },
      { name: "Spirituality", icon: <FaPray /> },
      { name: "Traveling", icon: <FaPlane /> },
      { name: "Volunteering", icon: <FaHeart /> },
      { name: "Greek Life", icon: <FaUniversity /> },
    ],
  };