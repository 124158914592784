import React, { useState, useEffect, useContext, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/index";
import { filterInitialApts } from "../backend/utils";
import AppContext from "../AppContext";
import { ReactComponent as ChevronLeft } from "../data/imgs/chevron-left.svg";
import MainHeader from "../components/Headers/MainHeader";
import SlideInComponent from "../components/SlideInComp";
import { fetchLeanSpecificApartment } from "../backend/dynamoAPI";
import SavedShareModal from "../components/SavedShareModal";

function SavedPage() {
  const [user, loading, error] = useAuthState(auth);
  const [apartmentData, setApartmentData] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // State to control the modal
  const navigate = useNavigate();
  const { favorites } = useContext(AppContext);

  const [filters, setFilters] = useState({
    priceRange: [100, 3000],
    bedRange: [1, 4],
    sortRent: "asc",
    amenities: [],
    selectedLocation: "Main Quad",
    distRange: 50, // in min
    pricePerPerson: true, // option
    hoverAptID: null, // option
    mapOpen: false,
    privateBathroom: false,
    availability: "All",
    apartmentType: "Apartments",
    apartmentGroups: ["bankier", "ugroup", "mhm", "jsm", "academy"],
  });

  const navigateBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/"); // Fallback URL
    }
  };

  useEffect(() => {
    const fetchFavoriteApartments = async () => {
      const fetchedApartments = await Promise.all(
        favorites.map(async (favId) => {
          let groupId = "single_apartments"; // Default group ID if no slash is present
          let apartmentId = favId;

          if (favId.includes("/")) {
            const [groupKey, id] = favId.split("/");
            groupId = groupKey;
            apartmentId = favId;
          }

          try {
            const apartments = await fetchLeanSpecificApartment(
              groupId,
              apartmentId
            );
            return apartments;
          } catch (error) {
            console.error(
              `Error fetching apartment with groupId: ${groupId} and id: ${apartmentId}`,
              error
            );
          }

          return null; // Return null if no apartment found or an error occurred
        })
      );

      // Filter out null values and apply initial filtering
      const validApartments = fetchedApartments.filter(Boolean);
      setApartmentData(filterInitialApts(validApartments));
    };

    if (user && !loading) {
      fetchFavoriteApartments();
    }
  }, [user, loading, favorites]);

  const handleShare = () => {
    setIsShareModalOpen(true); // Open the modal
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false); // Close the modal
  };

  const shareUrl = `https://www.getabode.co/saved/${user?.uid}`;

  return (
    <div>
      <MainHeader />
      <div className="flex flex-col bg-white-500 max-w-full min-h-screen overflow-hidden mx-auto">
        <div className="flex items-center justify-between transition-colors duration-300 p-4 md:px-16">
          <div className="flex items-center">
            <div className="p-2 hover:bg-gray-100 rounded-full">
              <ChevronLeft
                className="w-6 h-6 cursor-pointer"
                onClick={navigateBack}
              />
            </div>
            <h1 className="text-2xl font-semibold my-4">Liked Apartments</h1>
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={handleShare}
              className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100"
            >
              <i className="fa-solid fa-arrow-up-from-bracket fa-md"></i>
              <p className="text-sm font-semibold ml-2">Share</p>
            </button>
          </div>
        </div>
        <div className="flex-grow overflow-hidden">
          <SlideInComponent
            apartments={apartmentData}
            filters={filters}
            setFilters={setFilters}
            showIcons={false}
          />
        </div>
      </div>

      {/* Lazy load Share Modal */}
      <Suspense fallback={<div>Loading...</div>}>
        {isShareModalOpen && (
          <SavedShareModal
            isOpen={isShareModalOpen}
            onClose={handleCloseShareModal}
            shareUrl={shareUrl}
            title="Check these apartments!"
          />
        )}
      </Suspense>
    </div>
  );
}

export default SavedPage;
