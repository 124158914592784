import React, { useState, useEffect, useRef, Suspense } from "react";
import LoadingAnimation from "./LoadingAnimation";
const ListingCard = React.lazy(() => import("./ListingCard"));

function Listings({ apartments, filters, setFilters }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = apartments.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = (apartments || []).slice(indexOfFirstItem, indexOfLastItem);
  const loaderRef = useRef(null);

  const handleMouseEnter = (apartmentId) => {
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      hoverAptID: apartmentId,
    }));
  };

  const handleMouseLeave = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      hoverAptID: null,
    }));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setItemsPerPage((itemsPerPage) => itemsPerPage + 10);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [itemsPerPage]);

  return (
    <div className="mx-auto p-4 md:px-16">
      <div
        className="grid gap-y-8 gap-x-8"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        }}
        ><Suspense fallback={<LoadingAnimation />}>
        {currentItems.map((apartment) => (
          <div
            key={apartment.id}
            onMouseEnter={() => handleMouseEnter(apartment.id)}
            onMouseLeave={handleMouseLeave}
          >
            <ListingCard apartment={apartment} filters={filters} />
          </div>
        ))}
        </Suspense>
        <div
          ref={loaderRef}
          style={{ height: "100px", margin: "0 auto" }}
        ></div>
      </div>
    </div>
  );
}

export default Listings;
