import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../data/imgs/chevron-left.svg";
import MainHeader from "../components/Headers/MainHeader";
import TextField from "@mui/material/TextField";
import { Divider } from "./ApartmentDetail";
import { fetchUserData, updateContactInfo } from "../backend/dbAPI";
import { auth } from "../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";
import { fetchSpecificApartment } from "../backend/dynamoAPI";
import { sendApplyEmail } from "../backend/emailAPI";

const ApplyIntroPage = () => {
  const { id, secondParam } = useParams();
  const navigate = useNavigate();
  const [apartment, setApartment] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    preferredContact: "phone",
    description: "",
  });
  const [my_user] = useAuthState(auth);
  const userId = my_user ? my_user.uid : null;
  
  let groupId = "single_apartments"
  let apartmentId = id;
  if(secondParam) {
    groupId = id;
    apartmentId = `${id}/${secondParam}`
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId) {
        const userData = await fetchUserData(userId);
        // console.log(userData);
        if (userData) {
          setFormData({
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email || "",
            phone: userData.phone || "",
            preferredContact: userData.preferredContact || "phone",
            description: "",
          });
        }
      }
    };

    fetchUserInfo();
  }, [userId]);

  useEffect(() => {
    const fetchApartmentData = async () => {
      try {
        const jsonData = await fetchSpecificApartment(groupId, apartmentId);
        setApartment(jsonData);
      } catch (error) {
        console.error("Error fetching apartment data:", error);
      }
    };
    window.scrollTo(0, 0);
    fetchApartmentData();
  }, [id]);

  const navigateBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/"); // Fallback URL
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRedirect = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Basic validation checks
    const { name, email, phone, description } = formData;

    if (!name || !email || !phone || description.length < 3) {
      alert("Please fill out all fields correctly.");
      return;
    }

    // Email regex pattern for validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Phone number regex pattern for validation (US format)
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(phone.replace(/\D/g, ""))) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }

    // Redirect logic
    if (apartment.applyLink) {
      window.open(apartment.applyLink, "_blank");
    } else if (apartment.websiteLink) {
      window.open(apartment.websiteLink, "_blank");
    } else {
      alert(
        "Error: apartment website link not found. Please search apartment on Google."
      );
    }

    if (userId) {
      await updateContactInfo(userId, {
        email: formData.email,
        phone: formData.phone,
        preferredContact: formData.preferredContact,
      });
    }

    if (apartment.email) {
      await sendApplyEmail(formData, apartment.email, apartment.name);
    } else {
      await sendApplyEmail(formData, "contact@getabode.co", apartment.name);
    }
  };

  const handleViewWebsite = () => {
    if (apartment.websiteLink) {
      window.open(apartment.websiteLink, "_blank");
    } else {
      alert("Website link not found");
    }
  };

  const handleExploreApartments = () => {
    navigate("/listings");
  };

  const handleExploreRoommates = () => {
    navigate(`/interestedusers/${apartment.id}`);
  };

  if (!apartment) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <MainHeader />

      <div className="mx-auto max-w-6xl">
        <div className="flex flex-wrap">
          <div className="w-full md:w-2/3 md:pr-28 py-12">
            <div className="flex items-center transition-colors duration-300">
              <div className="p-2 hover:bg-gray-100 rounded-full">
                <ChevronLeft
                  className="w-6 h-6 cursor-pointer"
                  onClick={navigateBack}
                />
              </div>
              <h1 className="text-3xl font-medium ml-2">
                Apply to {apartment.name}
              </h1>
            </div>
            <div className="text-center md:text-start">
              <div>
                <h2 className="text-2xl font-medium mt-8 mb-1">
                  Simple 3 Steps
                </h2>
                <h2 className="text-sm text-gray-500 mb-4">
                  Once you click "Apply Now" and are on the apartment's
                  application page...
                </h2>
                <h2 className="text-sm text-gray-500 mb-4">
                  We recommend browsing through the apartment's website to
                  confirm details like price, availability, and answer any
                  questions before applying. Once you are ready to apply, follow
                  these simple steps on the apartment's website!
                </h2>
              </div>
              <Divider />
              <div className="justfiy-center items-center">
                <ol className="space-y-8 justfiy-center items-center">
                  <li>
                    <div className="text-xl font-medium">
                      1) Select floor plan
                    </div>
                    <div className="text-sm mt-2">
                      Choose the layout that suits your needs.
                    </div>
                  </li>
                  <li>
                    <div className="text-xl font-medium pb-2">
                      2) Submit Application
                    </div>
                    <ol className="text-sm list-disc">
                      Create an account, fill out the application with all
                      relevant personal, roommate, and guarantor details if
                      needed, and submit. Make sure your guarantor submits their
                      application too. (~15 mins)
                    </ol>

                    <div className="text-xs text-gray-500">
                      *This application is not legally binding but rather to
                      approve you as a tenant
                    </div>
                  </li>
                  <li>
                    <div className="text-xl font-medium">3) Sign Lease</div>
                    <div className="text-sm mt-2">
                      Usually, within a week, the leasing office will approve
                      you as a tenant, send a lease contract, and notify you via
                      email. Review and sign your lease agreement to secure your
                      apartment!
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <Divider />

            <div className="hidden md:block">
              <div className="mt-8">Not yet?</div>
              <button onClick={handleViewWebsite}>
                <div className="mt-4 underline">
                  Explore {apartment.name}'s website
                </div>
              </button>
              <div className="flex mt-4">
                <button onClick={handleExploreApartments}>
                  <div className="underline">Browse more apartments</div>
                </button>
                {/* <button onClick={handleExploreRoommates}>
                  <div className="ml-8 underline">Find roommates</div>
                </button> */}
              </div>
            </div>
            
          </div>

          <div className="w-full md:w-1/3 md:mt-24">
            <div className="bg-white p-6 border rounded-lg shadow-md">
              <h2 className="text-2xl text-left font-semibold text-transparent bg-clip-text bg-gradient-blue-r">
                I'm Interested!
              </h2>

              <div className="mt-4">
                <form>
                  <div className="mb-4">
                    <TextField
                      id="name"
                      label="Name"
                      variant="standard"
                      fullWidth
                      name="name"
                      placeholder="Joe Mama"
                      value={formData.name}
                      onChange={handleChange}
                      className="mb-4"
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="email"
                      label="Email"
                      variant="standard"
                      fullWidth
                      placeholder="joe@gmail.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="mb-4"
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="phone"
                      label="Phone Number"
                      variant="standard"
                      fullWidth
                      name="phone"
                      placeholder="224-321-7890"
                      value={formData.phone}
                      onChange={handleChange}
                      className="mb-4"
                    />
                  </div>

                  <div className="mb-4">
                    <TextField
                      id="description"
                      label="Floorplan & Bedroom Preferences"
                      variant="standard"
                      multiline
                      rows={4}
                      fullWidth
                      name="description"
                      placeholder="4 bed/2 bath, interested in the 4x2 terrace. We are a group of 4 friends looking to share. Any desc of your situation.."
                      value={formData.description}
                      onChange={handleChange}
                      className="mb-4"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-md font-medium text-gray-700">
                      Preferred Contact Method
                    </label>
                    <select
                      name="preferredContact"
                      value={formData.preferredContact}
                      onChange={handleChange}
                      className="mt-2 block w-full border pl-3 pr-10 py-2 text-base border-gray-400 sm:text-sm rounded-md"
                    >
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                    </select>
                  </div>
                  <div className="text-center">
                    <button
                      className="w-full mt-4 py-4 text-md font-semibold text-white rounded-lg bg-gradient-blue-r"
                      onClick={handleRedirect}
                    >
                      Apply Now
                    </button>
                    <div className="mt-2 text-xs text-gray-500">
                      *Registers interest and takes you to the apartment's
                      application website
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="block md:hidden">
            <div className="px-4 pb-8">

              <div className="mt-8">Not yet?</div>
              <button onClick={handleViewWebsite}>
                <div className="mt-4 underline">
                  Explore {apartment.name}'s website
                </div>
              </button>
              <div className="flex mt-4">
                <button onClick={handleExploreApartments}>
                  <div className="underline">Browse more apartments</div>
                </button>
                {/* <button onClick={handleExploreRoommates}>
                  <div className="ml-8 underline">Find roommates</div>
                </button> */}
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyIntroPage;
