import React, { useState, useEffect } from "react";
import StarRating from "../../components/StarRating.js";
import ReviewModal from "../../components/Reviews/ReviewModal.js";
import BarChart from "../../components/Reviews/BarChart.js";
import { ReactComponent as WrenchSvg } from "../../data/icons/tool-light.svg";
import { ReactComponent as ManagementIcon } from "../../data/icons/building-light.svg";
import { ReactComponent as ValueIcon } from "../../data/icons/value-light.svg";
import { ReactComponent as AmenitiesIcon } from "../../data/icons/dumbell-light.svg";
import { ReactComponent as LivingIcon } from "../../data/icons/bed-light.svg";
import { ReactComponent as LocationIcon } from "../../data/icons/location-light.svg";
import amenitiesIcon from "../../data/icons/amenities.png";
import userIcon from "../../data/imgs/user.png";
import { getReviewDoc, getAllReviews, getTopReviews } from "../../backend/dbAPI.js";

const Reviews = ({ id, reviewTags = [], reviewDesc = "" }) => {
  id = id.replace(/\//g, '_');
  const [reviews, setReviews] = useState([]);
  const [totalNumReviews, setTotalNumReviews] = useState(0);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [averages, setAverages] = useState({
    overall: 0,
    ratingValue: 0,
    ratingMaintenance: 0,
    ratingAmenities: 0,
    ratingLiving: 0,
    ratingLocation: 0,
    ratingManagement: 0,
  });
  const [barChartRatings, setBarChartRatings] = useState([0, 0, 0, 0, 0]);
  const [successMessage, setSuccessMessage] = useState("");

  const fetchReviews = async () => {
    try {
      const reviewDoc = await getReviewDoc(id);
      const reviewData = reviewDoc.data();
      const numReviews = reviewData?.numReviews;
      setTotalNumReviews(numReviews);

      const querySnapshot = await getTopReviews(id);
      const reviewList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReviews(reviewList);

      const averages = {
        overall: reviewData?.totalAverage / numReviews,
        ratingValue: reviewData?.totalValue / numReviews,
        ratingMaintenance: reviewData?.totalMaintenance / numReviews,
        ratingAmenities: reviewData?.totalAmenities / numReviews,
        ratingLiving: reviewData?.totalLiving / numReviews,
        ratingLocation: reviewData?.totalLocation / numReviews,
        ratingManagement: reviewData?.totalManagement / numReviews,
      };

      setAverages(averages);

      const barChartRatings = [0, 0, 0, 0, 0];

      if (numReviews > 0) {
        reviewList.forEach((review) => {
          barChartRatings[Math.round(review.ratingAverage) - 1]++;
        });
      }

      setBarChartRatings(barChartRatings);
    } catch (error) {
      console.error("Error fetching reviews data:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [id]);

  const newReviewSubmit = async () => {
    try {
      fetchReviews();
      setSuccessMessage("Review submitted successfully!");
      setTimeout(() => {
        setSuccessMessage("");
      }, 6000);
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  return (
    <div className="">
      <div className="text-center ">
      {(reviews.length > 0) && (
        <div
          className={`grid grid-cols-1 ${
            reviewTags.length || reviewDesc ? "md:grid-cols-2" : ""
          } mt-12 md:my-24`}
        >
          <div className="justify-center text-center my-auto">
            <h2 className="text-7xl font-semibold">
              <span className="mr-4">★</span>
              {averages.overall.toFixed(2)}
            </h2>
          </div>
          {(reviewTags.length || reviewDesc) && (
            <div className="text-center my-12 p-8 md:text-start md:m-0 md:p-0">
              <h2 className="text-2xl font-semibold">
                Here's what students are saying...
              </h2>
              {reviewDesc && (
                <h2 className="text-md font-regular py-4">{reviewDesc}</h2>
              )}
              {reviewTags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2 text-center justify-center md:justify-start">
                  {reviewTags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gradient-star text-gray-800 px-3 py-1 rounded-full text-sm font-medium"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
        {(reviews.length > 0) && (
        <div className="md:grid grid-cols-7 gap-6 mx-auto hidden">
          <BarChart ratings={barChartRatings} />

          <div className="text-left border-r border-gray-300 pr-8">
            <h3 className="text-sm font-medium">Value</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingValue.toFixed(1)}
            </p>
            <ValueIcon className="w-8" />
            {/* <img src={valueIcon} alt="value" className="h-8 mr-2 mt-2" /> */}
          </div>
          <div className="text-left border-r border-gray-300 pr-8">
            <h3 className="text-sm font-medium">Maintenance</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingMaintenance.toFixed(1)}
            </p>

            <WrenchSvg className="w-8" />

            {/* <img
              src={maintenanceIcon}
              alt="maintenance"
              className="h-8 mr-2 mt-2"
            /> */}
          </div>
          <div className="text-left border-r border-gray-300 pr-8">
            <h3 className="text-sm font-medium">Amenities</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingAmenities.toFixed(1)}
            </p>
            
            <img
              src={amenitiesIcon}
              alt="amenities"
              className="w-8 mr-2 mt-2"
            />
          </div>
          <div className="text-left border-r border-gray-300 pr-8">
            <h3 className="text-sm font-medium">Living</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingLiving.toFixed(1)}
            </p>
            <LivingIcon className="w-8" />
          </div>

          <div className="text-left border-r border-gray-300 pr-8">
            <h3 className="text-sm font-medium">Location</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingLocation.toFixed(1)}
            </p>
            <LocationIcon className="w-8" />
          </div>
          <div className="text-left">
            <h3 className="text-sm font-medium">Management</h3>
            <p className="text-lg font-semibold mb-8">
              {averages.ratingManagement.toFixed(1)}
            </p>
            <ManagementIcon className="w-8" />
          </div>
        </div>
        )}
      </div>

      <div className="md:border-t border-gray-300 my-8 mt-0 md:mt-10"></div>

      <ReviewModal
        isOpen={showReviewForm}
        onClose={() => setShowReviewForm(false)}
        id={id}
        onReviewSubmit={newReviewSubmit}
      />

      <div className="flex flex-wrap grid grid-cols-1 md:grid-cols-2 gap-12">
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div key={review.id}>
              <div className="h-full px-8 md:w-5/6 md:px-0">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img src={userIcon} className="w-8 h-8 rounded-full mr-2" />
                    <div>
                      <h3 className="text-md font-medium">{review.name}</h3>
                      <p className="font-regular text-xs text-gray-500">
                        {review.date}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <StarRating score={review.ratingAverage} />
                  </div>
                </div>
                <ClippedText text={review.description}/>
                {/* <p className="text-md mt-2">{review.description}</p> */}
              </div>
            </div>
          ))
        ) : (
          <p>No reviews yet.</p>
        )}
      </div>

      <div className="flex flex-wrap gap-x-8 px-8 mt-12 md:px-0">
      <button
          className="px-4 py-3 font-medium border border-black rounded-md hover:bg-gray-100"
          // onClick={() => setShowReviewForm(true)}
        >
          Show all {totalNumReviews} reviews
        </button>
        <button
          className="text-md font-medium underline"
          onClick={() => setShowReviewForm(true)}
        >
          Add Review
        </button>
      </div>
      {successMessage && (
        <div className="flex">
          <p className="text-green-600">{successMessage}</p>
        </div>
      )}
    </div>
  );
};

const ClippedText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p className={`text-md text-black font-light mt-2 ${isExpanded ? '' : 'line-clamp-4'}`}>
        {text}
      </p>
      <button 
        onClick={toggleExpand} 
        className="text-black font-medium underline mt-2"
      >
        {isExpanded ? 'Show Less' : 'Show More'}
      </button>
    </div>
  );
};

export default Reviews;
