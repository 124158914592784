import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopHeader from "../components/Headers/TopHeader";
import SubleasePage from "./Sublease/SubleasePage";
import ListingsPage from "./ListingPage";
import InterestedUsers from "./InterestedUsers";

function BetaPage() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("Apartments");

  // Function to parse the hash from the URL and set the correct tab
  const setTabFromHash = (hash) => {
    switch (hash) {
      case "#subleases":
        setCurrentPage("Subleases");
        break;
      case "#roommates":
        setCurrentPage("Roommates");
        break;
      case "#apartments":
      default:
        setCurrentPage("Apartments");
        break;
    }
  };

  useEffect(() => {
    // On component mount or URL hash change, set the tab based on the hash
    setTabFromHash(location.hash);

    // Optional: Update the page title (or perform other side effects) based on the tab
    if (location.hash) {
      document.title = `Abode | ${location.hash.substring(1).charAt(0).toUpperCase() + location.hash.slice(2)}`;
    }
  }, [location.hash]);

  return (
    <div>
      <div className="flex flex-col bg-white-500 max-w-full min-h-screen overflow-hidden">
        <TopHeader firstSelect={currentPage} setPage={setCurrentPage} />

        {currentPage === "Apartments" && <ListingsPage />}
        {currentPage === "Subleases" && <SubleasePage />}
        {currentPage === "Roommates" && <InterestedUsers />}
      </div>
    </div>
  );
}

export default BetaPage;
