import React, { createContext, useState, useEffect } from 'react';
import { fetchFavorites, addFavorite, removeFavorite } from './backend/dbAPI';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/index';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const getFavorites = async () => {
      if (user && !loading && !error) {
        try {
          const fetchedFavorites = await fetchFavorites(user.uid);
          setFavorites(fetchedFavorites);
        } catch (fetchError) {
          console.error('Error fetching favorites:', fetchError);
        }
      } else if (!user) {
        // Clear favorites when user logs out
        setFavorites([]);
      }
    };

    getFavorites();
  }, [user, loading, error]);

  const addToFavorites = async (userId, listingId) => {
    if (user) {
      try {
        await addFavorite(userId, listingId);
        setFavorites((prevFavorites) => [...prevFavorites, listingId]);
      } catch (addError) {
        console.error('Error adding favorite:', addError);
      }
    }
  };

  const removeFromFavorites = async (userId, listingId) => {
    if (user) {
      try {
        await removeFavorite(userId, listingId);
        setFavorites((prevFavorites) => prevFavorites.filter(id => id !== listingId));
      } catch (removeError) {
        console.error('Error removing favorite:', removeError);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <AppContext.Provider value={{ favorites, addToFavorites, removeFromFavorites }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
