import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/index";
import { filterInitialApts } from "../backend/utils";
import AppContext from "../AppContext";
import { ReactComponent as ChevronLeft } from "../data/imgs/chevron-left.svg";
import MainHeader from "../components/Headers/MainHeader";
import SlideInComponent from "../components/SlideInComp";
import { fetchSpecificApartment } from "../backend/dynamoAPI";
import { fetchUserData } from "../backend/dbAPI";

function ProfileHeader({ userId, userProfile }) {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`/roommate/${userId}`);
  };

  return (
    <div
      onClick={handleProfileClick}
      className="flex items-center cursor-pointer p-4"
    >
      <div className="mr-4">
        {userProfile?.profilePicture ? (
          <img
            src={userProfile.profilePicture}
            alt={`${userProfile.firstName}'s Profile`}
            className="w-12 h-12 rounded-full object-cover"
          />
        ) : (
          <div className="w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center">
            <span></span>
          </div>
        )}
      </div>
      <div>
        <h1 className="text-xl font-bold">
          {userProfile?.firstName}'s Favorites
        </h1>
        <p className="text-gray-600 underline text-xs">View profile</p>
      </div>
    </div>
  );
}

function UserSavedPage() {
    const [user, loading, error] = useAuthState(auth);
    const [apartmentData, setApartmentData] = useState([]);
    const [profileData, setProfileData] = useState(null);
    const { userId } = useParams(); // Get userId from URL params
  
    useEffect(() => {
      const fetchUserProfile = async () => {
        try {
          const userData = await fetchUserData(userId);
          if (userData) {
            setProfileData(userData);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };
  
      if (userId) {
        fetchUserProfile();
      }
    }, [userId]);

  const [filters, setFilters] = useState({
    priceRange: [100, 3000],
    bedRange: [1, 4],
    sortRent: "asc",
    amenities: [],
    selectedLocation: "Main Quad",
    distRange: 50, // in min
    pricePerPerson: true, // option
    hoverAptID: null, // option
    mapOpen: false,
    privateBathroom: false,
    availability: "All",
    apartmentType: "Apartments",
    apartmentGroups: ["bankier", "ugroup", "mhm", "jsm", "academy"],
  });

  useEffect(() => {
    // Fetch the user profile data
    const fetchUserProfile = async () => {
      try {
        const userData = await fetchUserData(userId); // Fetch user profile
        if (userData) {
          setProfileData(userData);
          const fetchedFavorites = userData.favorites || [];
          
          const fetchedApartments = await Promise.all(
            fetchedFavorites.map(async (favId) => {
              let groupId = "single_apartments"; // Default group ID if no slash is present
              let apartmentId = favId;

              if (favId.includes("/")) {
                const [groupKey, id] = favId.split("/");
                groupId = groupKey;
                apartmentId = favId;
              }

              try {
                const apartments = await fetchSpecificApartment(groupId, apartmentId);
                return apartments;
              } catch (error) {
                console.error(`Error fetching apartment with groupId: ${groupId} and id: ${apartmentId}`, error);
              }

              return null; // Return null if no apartment found or an error occurred
            })
          );

          // Filter out null values and apply initial filtering
          const validApartments = fetchedApartments.filter(Boolean);
          setApartmentData(filterInitialApts(validApartments));
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (userId) {
      fetchUserProfile();
    }
  }, [userId]);

  return (
    <div>
      <MainHeader />
      <div className="flex flex-col bg-white-500 max-w-full min-h-screen overflow-hidden mx-auto">
        <div className="flex items-center transition-colors duration-300 p-4 md:px-16">

          
      <div>
        {/* ProfileHeader: shows profile picture, name, and navigates to full profile on click */}
        {profileData && <ProfileHeader userId={userId} userProfile={profileData} />}
        </div>
        </div>
        <div className="flex-grow overflow-hidden">
          <SlideInComponent
            apartments={apartmentData}
            filters={filters}
            setFilters={setFilters}
            showIcons={false}
          />
        </div>
      </div>
    </div>
  );
}

export default UserSavedPage;
