import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  lazy,
  Suspense,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../components/Headers/MainHeader";
import Slider from "react-slick";
import { ReactComponent as ChevronLeft } from "../data/imgs/chevron-left.svg";
import Footer from "../components/Headers/Footer";

import { auth } from "../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";

import pfpImages from "../data/imgs/pfpImgs.jpg";
import AppContext from "../AppContext";
import { getPriceDescString } from "../backend/utils";
import LocationCard from "../components/Map/LocationCard";
import ImageGallery from "../components/ImageGallery";
import floorplanPlaceholderImg from "../data/imgs/floorplan_placeholder.jpg";
import { fetchSpecificApartment } from "../backend/dynamoAPI";
import { logEvent, analytics } from "../firebase/index";

const PhotoModal = lazy(() => import("../components/PhotoModal"));
const Reviews = lazy(() => import("../components/Reviews/Reviews"));
const ShareModal = lazy(() => import("../components/ShareModal"));
const ApplyNowForm = lazy(() => import("../components/ApplyNowForm"));
const FloorPlanPhotoModal = lazy(() =>
  import("../components/FloorPlanPhotoModal")
);
const FloorplanModal = lazy(() =>
  import("../components/Listings/FloorplanModal")
);

function GroupApartmentDetail() {
  const { groupId, apartmentId } = useParams();
  const fullId = groupId + "/" + apartmentId;
  const navigate = useNavigate();
  const [apartment, setApartment] = useState(null);
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const { favorites, addToFavorites, removeFromFavorites } =
    useContext(AppContext);
  const [user, loading, error] = useAuthState(auth);
  const [isFavorited, setIsFavorited] = useState(favorites.includes(fullId));
  const [showAlert, setShowAlert] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [priceDesc, setPriceDesc] = useState("");
  const [selectedFloorplan, setSelectedFloorplan] = useState(null); // Add state for selected floorplan
  const [isFloorplanModalOpen, setIsFloorplanModalOpen] = useState(false); // Add state for floorplan modal

  const handleOpenModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsShareModalOpen(false);
  };

  const shareUrl = window.location.href;

  const toggleShowAllPhotos = () => {
    setShowAllPhotos(!showAllPhotos);
  };

  const handleShareClick = async () => {
    // if (navigator.share) {
    //   try {
    //     await navigator.share({
    //       title: apartment?.name,
    //       text: apartment.name + ": " + priceDesc,
    //       url: shareUrl,
    //     });
    //     // console.log("Share successful");
    //   } catch (error) {
    //     console.error("Error sharing:", error);
    //   }
    // } else {
      // console.log("Web Share API not supported in this browser.");
      handleOpenModal();
    //   // console.log(`${apartment.id} Share clicked`);
    // }
  };

  const handleSaveClick = () => {
    if (user) {
      if (!isFavorited) {
        addToFavorites(user.uid, apartment.id);
      } else {
        removeFromFavorites(user.uid, apartment.id);
      }
      setIsFavorited(!isFavorited);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 7000);
    } else {
      alert("Please login to save apartments");
    }
    // console.log(`${apartment.id} Save clicked`);
  };

  const navigateBack = () => {
    navigate("/listings");
  };

  useEffect(() => {
    const fetchApartmentData = async () => {
      try {
        const jsonData = await fetchSpecificApartment(groupId, fullId);
        setApartment(jsonData);
      } catch (error) {
        console.error("Error fetching apartment data:", error);
      }
    };

    fetchApartmentData();
  }, [groupId, fullId]);

  useEffect(() => {
    const setPriceDescState = () => {
      if (apartment) {
        setPriceDesc(getPriceDescString(apartment.floorPlans));
      }
    };

    setPriceDescState();
  }, [apartment]);

  useEffect(() => {
    // console.log(favorites, fullId);
    setIsFavorited(favorites.includes(fullId));
  }, [favorites, fullId]);

  const openFloorplanModal = (floorplan) => {
    setSelectedFloorplan(floorplan);
    setIsFloorplanModalOpen(true);
  };

  const closeFloorplanModal = () => {
    setSelectedFloorplan(null);
    setIsFloorplanModalOpen(false);
  };

  if (!apartment) {
    return <div>Loading...</div>;
  }

  const Title = () => {
    return (
      <div className="flex justify-between items-center pb-4 pt-6 hidden md:flex">
        <div className="flex items-center transition-colors duration-300">
              <div className="p-2 hover:bg-gray-100 rounded-full">
                <ChevronLeft
                  className="w-6 h-6 cursor-pointer"
                  onClick={navigateBack}
                />
              </div>
              <h1 className="text-2xl font-semibold ml-1">
                {apartment.name}
              </h1>
            </div>
        <div className="flex">
          <button
            onClick={handleShareClick}
            className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100"
          >
            <i className="fa-solid fa-arrow-up-from-bracket fa-md"></i>
            <p className="text-sm font-semibold ml-2">Share</p>
          </button>
          <button
            onClick={handleSaveClick}
            className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100"
          >
            {isFavorited ? (
              <div className="flex items-center">
                <i className="fa-solid text-red-500 fa-heart fa-md"></i>
                {/* <RedHeartIcon className="h-4" /> */}
                <p className="text-sm font-medium ml-2 underline">Saved</p>
              </div>
            ) : (
              <div className="flex items-center">
                <i className="fa-regular fa-heart fa-md"></i>
                <p className="text-sm font-medium ml-2 underline">Save</p>
              </div>
            )}
          </button>
        </div>
      </div>
    );
  };

  const Header = () => {
    return (
      <>
        <div className="hidden md:block">
          <MainHeader />
        </div>
        <div>
          <div className="flex items-center justify-between bg-white md:hidden">
            <button className="p-4" onClick={navigateBack}>
              <ChevronLeft className="w-6 h-6" />
            </button>

            <div className="flex text-gray-700">
              <button
                onClick={handleShareClick}
                className="flex items-center p-4"
              >
                <i className="fa-solid fa-arrow-up-from-bracket fa-md"></i>
              </button>
              <button
                onClick={handleSaveClick}
                className="flex items-center p-4"
              >
                {isFavorited ? (
                  <i className="fa-solid text-red-500 fa-heart fa-md"></i>
                ) : (
                  <i className="fa-regular fa-heart fa-md"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const apartmentImages = getCompleteImageList(apartment);
  const floorplanImages = getCompleteFloorplanImageList(apartment);

  function getCompleteImageList(apartment) {
    const apartmentImages = apartment.images ? [...apartment.images] : [];

    // Check if the number of images is less than 5
    if (apartmentImages.length < 5) {
      // Iterate over each floorplan and extract images if they exist
      apartment.floorPlans.forEach((floorplan) => {
        if (floorplan.images && Array.isArray(floorplan.images)) {
          floorplan.images.forEach((image) => {
            if (apartmentImages.length < 5) {
              apartmentImages.push(image); // Add until we reach 5 images
            }
          });
        }
        // Stop the loop if we already have 5 images
        if (apartmentImages.length >= 5) {
          return;
        }
      });
    }

    return apartmentImages;
  }

  function getCompleteFloorplanImageList(apartment) {
    const floorplanImages = [{ name: "Building", images: apartment.images }];

    // Add floorplans with valid images
    apartment.floorPlans.forEach((floorplan) => {
      if (floorplan.images && Array.isArray(floorplan.images)) {
        floorplanImages.push({
          name: floorplan.name,
          images: floorplan.images,
        });
      }
    });

    return floorplanImages.length > 1 ? floorplanImages : null;
  }

  function handleApplyNowClick() {
    if (user) {
      logEvent(analytics, "apply_now_click", {
        apartmentId: apartment.id,
        userId: user.uid,
        timestamp: Date.now(),
      });
    }
    navigate(`/apply-now/${apartment.id}`);
  }

  return (
    <div className="bg-white relative">
      <Header />
      <div className="container max-w-6xl mx-auto md:px-6">
        <Title title={apartment.name} />
        <ImageGallery images={apartmentImages} onClick={toggleShowAllPhotos} />

        {/* Body */}
        <div className="flex flex-col px-6 md:flex-row md:px-0">
          {/* Left Body */}
          <div className="w-full pr-0 md:w-2/3 md:pr-12">
            <SubHeader
              apartment={apartment}
              priceDesc={priceDesc}
              rating="4.13"
              reviews="16 reviews"
            />
            <VibeTags vibeTags={apartment.vibeTags} />

            <PricingAndFloorPlans
              floorPlans={apartment.floorPlans}
              id={apartment.id}
              onClick={openFloorplanModal} // Pass the click handler
            />

            <Description
              apartment={apartment}
              toggleShowAllPhotos={toggleShowAllPhotos}
            />

            <FeaturedPhotos
              photos={apartment.featured_images}
              handleClick={toggleShowAllPhotos}
            />

            <AmenitiesComponent
              amenities={apartment.amenities}
              appliances={apartment.appliances}
            />

            <VirtualTour virtualTour={apartment.virtual_tour} />

            <UtilitiesComponent utilities={apartment.utilities} />

            <FeesAndPolicies
              fees={apartment.fees}
              pets={apartment.pets}
              parking={apartment.parking}
            />
          </div>
          {/* Right Body / Sticky Footer */}
          <div className="hidden md:block w-full md:w-1/3 rounded-lg">
            <h1 className="text-lg font-semibold py-4 pt-6 text-end">
              92 Viewed 🔥
            </h1>
            <aside className="self-start sticky top-24 mt-8 col-span-1">
              <ApplyNowForm apartment={apartment} />
              <LookingForRoommates id={apartment.id} />
            </aside>
          </div>
          <div className="fixed bottom-0 left-0 w-full bg-white text-black p-4 md:hidden border-t border-gray-300">
            <div className="flex w-full justify-between">
              <div>
                <div className="text-sm font-semibold">
                  Looking for roommates?
                </div>
                <button className="text-xs font-medium underline">
                  Explore profiles
                </button>
              </div>
              <button className="bg-logo_blue text-white text-sm font-semibold py-2 px-8 rounded-md"
              onClick={handleApplyNowClick}>
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <Divider />
        <Reviews
          id={apartment.id}
          reviewDesc={apartment.reviewDesc}
          reviewTags={apartment.reviewTags}
        />

        <Divider />

        <div>
          <h2 className="text-xl font-semibold mb-2">Location</h2>
          <h2 className="text-sm font-medium mb-4">{apartment.address}</h2>
          <LocationCard
            latitude={apartment.latitude}
            longitude={apartment.longitude}
          />
        </div>
        <Divider />
      </div>
      {floorplanImages ? (
        <FloorPlanPhotoModal
          showModal={showAllPhotos}
          toggleModal={toggleShowAllPhotos}
          floorplanImages={floorplanImages}
        />
      ) : (
        <PhotoModal
          showModal={showAllPhotos}
          toggleModal={toggleShowAllPhotos}
          images={apartmentImages}
        />
      )}

      <Footer />
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div class="fixed bottom-24 md:bottom-12 md:left-12 p-3 bg-white text-black text-md rounded-lg shadow-xl border transition-opacity duration-500">
            <div className="flex items-center">
              <img
                src={apartment.images[0]}
                className="h-12 w-12 rounded-lg object-cover"
              ></img>
              <div className="ml-3">
                {!isFavorited ? (
                  <div> Removed from saved</div>
                ) : (
                  <div> Added to saved!</div>
                )}

                <div className="text-sm font-semibold underline">View</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={handleCloseModal}
        shareUrl={shareUrl}
        imageLink={apartment.images[0]}
        title={apartment.name}
        shortDescription={priceDesc}
      />
      <FloorplanModal
        isOpen={isFloorplanModalOpen}
        onClose={closeFloorplanModal}
        floorplan={selectedFloorplan}
        onImageClick={toggleShowAllPhotos}
      />
    </div>
  );
}

export const Divider = () => {
  return <hr className="my-8" />;
};

const LookingForRoommates = ({ id }) => {
  const navigate = useNavigate();

  const navigateToProfiles = () => {
    navigate(`/interestedusers/${id}`);
  };

  return (
    <div className="">
      <div className="py-6 px-4 border text-center rounded-md">
        <h2 className="text-lg font-semibold">Looking for roommates?</h2>
        <h2 className="text-sm">
          View 10+ other students looking for roommates
        </h2>
        <div className="flex items-center justify-center mt-3">
          <button
            className="flex items-center space-x-1"
            onClick={navigateToProfiles}
          >
            <img src={pfpImages} alt="chevron-left" className="h-5" />
            <h2 className="text-sm underline text-gray-500 font-semibold">
              Explore profiles
            </h2>
          </button>
        </div>
      </div>
      {/* <TourBookingForm /> */}
    </div>
  );
};

const MiniImageSlider = ({ images, onClick }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className="mini-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} onClick={onClick} className="mini-slider-item">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className="h-12 object-cover cursor-pointer rounded"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SubHeader = ({ apartment, priceDesc, rating, reviews }) => {
  const name = apartment.name;
  let subHeader = apartment.shortDescription ?? apartment.short_description;

  if (!subHeader && apartment.short_tags) {
    subHeader = apartment.short_tags.slice(0, 2).join(" | ");
  }

  return (
    <>
      {/* SubHeader for small screens */}
      <div className="container pt-4 md:hidden">
        <h1 className="text-2xl font-semibold mb-4">{name}</h1>
        <h1 className="text-md font-medium">{subHeader}</h1>
        <div className="text-sm font-regular py-1">
          <span>{priceDesc}</span>
        </div>
        {/* <div className="text-sm flex items-center font-semibold">
          <span className="mr-1">★</span>
          <span>{rating}</span>
          <span className="mx-1">·</span>
          <span className="underline">{reviews}</span>
        </div> */}
      </div>

      {/* SubHeader for larger screens */}
      <div className="container pt-6 hidden md:block">
        <h1 className="text-xl font-semibold">{subHeader}</h1>
        <div className="text-md font-regular py-1">
          <span>{priceDesc}</span>
        </div>
        {/* <div className="text-md flex items-center font-semibold">
          <span className="mr-1">★</span>
          <span>{rating}</span>
          <span className="mx-1">·</span>
          <span className="underline">{reviews}</span>
        </div> */}
      </div>
    </>
  );
};

const VibeTags = ({ vibeTags }) => {
  const [isVibeModalOpen, setIsVibeModalOpen] = useState(false);

  const handleVibeShowMore = () => {
    setIsVibeModalOpen(true);
  };

  const handleVibeModalClose = () => {
    setIsVibeModalOpen(false);
  };

  return (
    <div>
      {vibeTags && (
        <div>
          <Divider />
          <div className="">
            <h2 className="text-xl font-semibold mr-2">Vibe</h2>
            <div className="flex flex-wrap gap-2 my-4 line-clamp-2">
              {vibeTags.slice(0, 8).map((tag, index) => (
                <div
                  key={index}
                  className="bg-gray-100 text-gray-800 px-2 py-1 rounded-lg text-md font-medium"
                >
                  {tag}
                </div>
              ))}
            </div>
            <button
              onClick={handleVibeShowMore}
              className="font-medium underline"
            >
              {"Show More >"}
            </button>
          </div>
          {isVibeModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Vibe</h2>
                  <button
                    onClick={handleVibeModalClose}
                    className="text-gray-800 text-xl font-semibold"
                  >
                    &times;
                  </button>
                </div>
                <div className="flex flex-wrap gap-2 mb-8">
                  {vibeTags.map((tag, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 text-gray-800 px-2 py-1 rounded-lg text-md font-medium"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PricingAndFloorPlans = ({ id, floorPlans, onClick }) => {
  const bedroomTabs = [...new Set(floorPlans.map((plan) => plan.beds))].map(
    (bed) => (bed === 0 ? "Studio" : `${bed} Bedroom`)
  );

  const tabs = ["For You", ...bedroomTabs, "All"];
  const [activeTab, setActiveTab] = useState("For You");

  const parseRent = (rent, beds, perPers) => {
    const rentStr = typeof rent === "number" ? rent.toString() : rent;
    let rentDisplay = "";

    if (typeof rentStr === "number" && perPers === true && beds < 2) {
      return `$${rent} /mo`;
    } else if (typeof rentStr === "string" && /^\d+$/.test(rentStr)) {
      rentDisplay = `$${rentStr} /mo`;
    } else if (typeof rentStr === "string" && /^\d+-\d+$/.test(rentStr)) {
      rentDisplay = `$${rentStr.replace("-", "-$")} /mo`;
    } else {
      // Return non-numeric strings as is
      return rentStr;
    }

    if (perPers === false && beds > 0) {
      const rentAmount = parseInt(rentStr.split("-")[0]);
      const rentPerPerson = Math.round(rentAmount / beds);
      return `$${rentPerPerson} /mo (${rentDisplay})`;
    }

    return rentDisplay;
  };

  const isAvailable = (availability) => {
    const unavailableKeywords = ["sold", "waitlist", "unavailable"];
    return !unavailableKeywords.some((keyword) =>
      availability.toLowerCase().includes(keyword)
    );
  };

  const getForYouPlans = (plans) => {
    const forYouPlans = {};
    plans.forEach((plan) => {
      if (isAvailable(plan.availability)) {
        const key = `${plan.beds}x${plan.bath}`;
        if (
          !forYouPlans[key] ||
          parseInt(plan.rent) > parseInt(forYouPlans[key].rent)
        ) {
          forYouPlans[key] = plan;
        }
      }
    });
    return Object.values(forYouPlans);
  };

  const forYouPlans = getForYouPlans(floorPlans);

  const filteredPlans = floorPlans.filter((plan) => {
    if (activeTab === "For You") {
      return forYouPlans.includes(plan);
    }
    if (activeTab === "All") {
      return true;
    }
    const tabLabel = plan.beds === 0 ? "Studio" : `${plan.beds} Bedroom`;
    return tabLabel === activeTab;
  });

  const sortedPlans = filteredPlans.sort((a, b) => {
    const aAvailable = isAvailable(a.availability);
    const bAvailable = isAvailable(b.availability);

    if (aAvailable && !bAvailable) return -1;
    if (!aAvailable && bAvailable) return 1;

    const parseRent = (rent) => {
      if (typeof rent === "string") {
        return parseInt(rent.split("-")[0]);
      } else if (typeof rent === "number") {
        return rent;
      } else {
        throw new Error("Invalid rent type");
      }
    };

    return parseRent(a.rent) - parseRent(b.rent);
  });

  return (
    <div className="container">
      <Divider />

      <h2 className="text-xl font-semibold mb-2 md:mb-3">
        Pricing and Floor Plans
      </h2>
      <div className="">
        <nav className="flex flex-wrap justify-start items-start">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`border-b-2 md:py-2 py-1 font-medium text-md mr-8 ${
                activeTab === tab
                  ? "border-logo_blue text-logo_blue"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
      {sortedPlans.length === 0 ? (
        <div className="text-center text-gray-700 mt-4">
          No floor plans available
        </div>
      ) : (
        sortedPlans.map((plan) => (
          <div
            key={plan.name}
            className="my-2 cursor-pointer"
            onClick={() => onClick(plan)}
          >
            <div className="border border-gray-200 rounded-md p-4">
              <div className="flex items-center">
                <div className="flex flex-col justify-center items-center w-1/2">
                  <img
                    src={plan.img || floorplanPlaceholderImg}
                    alt={plan.name}
                    className="h-32 object-cover rounded-lg"
                  />
                  {
                    plan.images ? <div className="text-sm font-medium underline">View More</div> : null
                  }
                  
                </div>
                <div className="flex flex-col w-1/2">
                  <h2 className="text-xl font-medium py-2">{plan.name}</h2>
                  <h2 className="text-lg font-medium">
                    {parseRent(plan.rent, plan.beds, plan.perPers)}
                  </h2>
                  <div className="text-gray-700 text-sm py-1">
                    <span className="font-medium">{plan.beds} Bed</span>
                    <span className="mx-1">|</span>
                    <span className="font-medium">{plan.bath} Bath</span>
                    {plan.sf && (
                      <span>
                        <span className="mx-1">|</span>
                        <span className="font-medium">{plan.sf} Sq. Ft.</span>
                      </span>
                    )}
                  </div>
                  <div className="text-gray-700 text-sm">
                    <span>{plan.availability}</span>
                  </div>
                  {plan.comments && (
                    <div className="text-sm mt-4">
                      <div className="line-clamp-1">{plan.comments}</div>
                      <button className="text-gray-500 underline">
                        {"View More"}"
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const Description = ({ apartment }) => {
  const description = apartment?.description;
  const description_list = apartment?.description_list;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  if(description == null && description_list == null) {
    return null;
  }

  return (
    <div className="container">
      <Divider />
      
      {/* Display description if it exists */}
      {description && (
        <div className="mb-4">
          <p className={`overflow-hidden ${isExpanded ? "" : "line-clamp-3"}`}>
            {description}
          </p>
          <button onClick={toggleDescription} className="font-medium underline">
            {isExpanded ? "Show Less" : "Show More >"}
          </button>
        </div>
      )}

      {/* Display description list if it exists */}
      {description_list && description_list.length > 0 && (
        <ul className="list-disc ml-6">
          {description_list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <button
    className="p-1 bg-white border border-gray-300 rounded-full"
    onClick={onClick}
  >
    <ChevronLeft style={{ height: 20, width: 20 }} />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    className="p-1 bg-white border border-gray-300 rounded-full rotate-180"
    onClick={onClick}
  >
    <ChevronLeft style={{ height: 20, width: 20 }} />
  </button>
);

const FeaturedPhotos = ({ photos, handleClick }) => {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesToShow = 2;
  const totalGroups =
    photos == null ? 0 : Math.ceil(photos.length / slidesToShow);

  useEffect(() => {
    if (photos === null || photos === undefined) {
      return;
    }
    const currentGroup = Math.ceil((currentIndex + 1) / slidesToShow);
    document.getElementById(
      "slider-status"
    ).innerText = `${currentGroup} / ${totalGroups}`;
  }, [currentIndex, slidesToShow, totalGroups]);

  if (photos === null || photos === undefined) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: false,
    dots: false,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  return (
    <div className="container">
      <Divider />
      <div className="flex justify-between items-center mb-4 relative">
        <h2 className="text-xl font-semibold">Featured Photos</h2>
        <div className="flex items-center space-x-4 absolute right-0 top-0">
          <div id="slider-status" className="text-sm font-light">
            1 / {totalGroups}
          </div>
          <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />
          <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
      <Slider ref={sliderRef} {...settings} className="space-x-4 pt-2">
        {photos.map((photo, index) => (
          <div
            key={index}
            onClick={handleClick}
            className="w-full flex-shrink-0 px-2"
          >
            <div className={`w-full h-36 md:h-56`}>
              <img
                src={photo.link}
                alt={photo.name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="mt-4 text-left">
              <div className="font-medium">{photo.name}</div>
            </div>
          </div>
        ))}
      </Slider>
      <button onClick={handleClick} className="pt-4 font-medium underline ">
        {"View All Photos >"}
      </button>
    </div>
  );
};

const AmenitiesComponent = ({ amenities = [], appliances = [] }) => {
  const combinedItems = [...amenities, ...appliances].slice(0, 10);
  const totalItems = amenities.length + appliances.length;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (totalItems === 0) {
    return null;
  }

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <h2 className="text-xl font-semibold">What this place offers</h2>
      </div>

      <ul className="grid grid-cols-2 gap-4 list-disc pl-5">
        {combinedItems.map((item, index) => (
          <li key={index} className="text-base">
            {item}
          </li>
        ))}
      </ul>

      <button
        className="mt-8 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
        onClick={openModal}
      >
        Show all {totalItems} amenities
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-3xl h-full md:h-5/6 overflow-auto">
            <div className="sticky top-0 bg-white flex justify-between items-center p-4">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700 text-2xl"
              >
                &times;
              </button>
            </div>
            <div className="px-6">
              <h2 className="text-2xl font-medium">What this place offers</h2>
              <h3 className="text-xl font-medium py-8">Amenities</h3>
              <ul className="list-disc pl-5">
                {amenities.map((item, index) => (
                  <li key={index} className="text-base">
                    {item}
                    <Divider />
                  </li>
                ))}
              </ul>

              <h3 className="text-xl font-medium py-8">Appliances</h3>
              <ul className="list-disc pl-5">
                {appliances.map((item, index) => (
                  <li key={index} className="text-base">
                    {item}
                    <Divider />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UtilitiesComponent = ({ utilities }) => {
  // If no utilities are provided
  if (!utilities) {return null}
  // if (!utilities) {
  //   return (
  //     <div className="container">
  //       <Divider />
  //       <div className="mb-4">
  //         <h2 className="text-xl font-semibold">Utilities</h2>
  //       </div>
  //       <div className="px-0">
  //         <div className="mb-6">
  //           <h3 className="text-base">Contact office for utilities info</h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // If utilities is an array, assume it's the included utilities
  if (Array.isArray(utilities)) {
    return (
      <div className="container">
        <Divider />
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Utilities</h2>
        </div>
        <div className="px-0">
          <div className="mb-6">
            <h3 className="text-base font-medium">Included</h3>
            <div className="my-2" />
            <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
              {utilities.map((item, index) => (
                <li key={index} className="text-base mr-4 min-w-[150px]">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  // If utilities is an object, assume it has `included` and `fee` properties
  const { included, fee } = utilities;

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <h2 className="text-xl font-semibold">Utilities</h2>
      </div>
      <div className="px-0">
        {/* Included */}
        <div className="mb-6">
          <h3 className="text-base font-medium">Included</h3>
          <div className="my-2" />
          <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
            {included.map((item, index) => (
              <li key={index} className="text-base mr-4 min-w-[150px]">
                {item}
              </li>
            ))}
          </ul>
        </div>
        {/* With fee */}
        {fee && fee.length > 0 && (
          <div>
            <h3 className="text-base font-medium">With Fee</h3>
            <div className="my-2" />
            <ul className="flex flex-wrap gap-x-8 gap-y-2 list-disc pl-5">
              {fee.map((item, index) => (
                <li key={index} className="text-base mr-4">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const FeesAndPolicies = ({ fees, pets, parking }) => {
  const [activeTab, setActiveTab] = useState("fees");

  if(fees == null && pets == null && parking == null) {
    return null;
  }

  return (
    <div className="">
      <Divider />
      <h1 className="text-xl font-semibold mb-2">Fees and Policies</h1>
      <div className="border-b border-gray-300">
        <nav className="flex space-x-8">
          <button
            className={`whitespace-nowrap border-b-2 font-medium text-md ${
              activeTab === "fees"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("fees")}
          >
            Fees
          </button>
          <button
            className={`whitespace-nowrap border-b-2 font-medium text-md ${
              activeTab === "pets"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("pets")}
          >
            Pets
          </button>
          <button
            className={`whitespace-nowrap py-2 border-b-2 font-medium text-md ${
              activeTab === "parking"
                ? "border-logo_blue text-logo_blue"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab("parking")}
          >
            Parking
          </button>
        </nav>
      </div>

      <div className="mt-4">
        {activeTab === "fees" && (
          <div>
            {(!fees?.monthly || fees.monthly.length === 0) &&
            (!fees?.single || fees.single.length === 0) ? (
              <p>No fees listed.</p>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {fees?.monthly && fees.monthly.length > 0 && (
                  <div className="col-span-1">
                    <h2 className="text-md font-medium mb-2">
                      Monthly Utilities & Services
                    </h2>
                    <div className="border rounded-md p-4">
                      {fees.monthly.map((item, index) => (
                        <div key={index} className="flex justify-between">
                          <span>{item.name}</span>
                          <span>{item.price}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {fees?.single && fees.single.length > 0 && (
                  <div className="col-span-1">
                    <h2 className="text-md font-medium mb-2">
                      One-Time Move-In Fees
                    </h2>
                    <div className="border rounded-md p-4">
                      {fees.single.map((item, index) => (
                        <div key={index} className="flex justify-between mb-2">
                          <span>{item.name}</span>
                          <span>{item.price}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {activeTab === "pets" && (
          <div>
            <div>
              <h2 className="text-md font-medium mb-2">Pets Policy</h2>
              <p>{pets?.desc || "No pets policy listed."}</p>
            </div>
          </div>
        )}
        {activeTab === "parking" && (
          <div>
            <h2 className="text-md font-medium mb-2">Parking Policy</h2>
            <p>{parking?.desc || "No parking policy listed."}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const VirtualTour = ({ virtualTour }) => {
  if (!virtualTour) {
    return null;
  }

  const renderSingleTour = (link) => (
    <div className="bg-gray-200 rounded-md mb-8 overflow-hidden">
      <iframe
        src={link}
        scrolling="yes"
        className="iframe-holder"
        title="Virtual Tour"
        style={{ width: "100%", height: "500px" }}
        allowFullScreen
      ></iframe>
    </div>
  );

  const renderMultipleTours = (links) => (
    <div className="flex overflow-x-scroll space-x-4 pb-4">
      {links.map((link, index) => (
        <div
          key={index}
          className="bg-gray-200 rounded-md overflow-hidden min-w-[300px]"
        >
          <iframe
            src={link}
            scrolling="yes"
            className="iframe-holder"
            title={`Virtual Tour ${index + 1}`}
            style={{ width: "100%", height: "300px" }}
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Divider />
      <div className="pb-4">
        <div className="flex items-end">
          <h1 className="text-xl font-semibold">Virtual Tour</h1>
          <p className="text-xs font-light pb-1 ml-1">
            *full-screen for best experience
          </p>
        </div>
      </div>
      {Array.isArray(virtualTour)
        ? renderMultipleTours(virtualTour)
        : renderSingleTour(virtualTour)}
    </div>
  );
};

export default GroupApartmentDetail;
