import React, { useState } from "react";

const LifestylePreferencesQuestionnaire = ({ lifestylePreferences, setLifestylePreferences }) => {
  const dropDownQuestions = [
    {
      question: "Preferred Roommate Gender",
      choices: ["Male", "Female", "Other", "All"],
    },
    {
      question: "Diet",
      choices: [
        "All (No specific dietary restrictions or preferences)",
        "Vegetarian (No meat, may include dairy and eggs)",
        "Vegan (No animal products at all)",
        "Pollo-pescetarian (No beef or pork, includes chicken and fish)",
        "Pescatarian (Vegetarian diet that includes fish)",
        "Gluten-Free (No gluten-containing foods)",
        "Halal (Meat and other foods prepared according to Islamic law)",
        "Other",
      ],
    },
    {
      question: "Do you drink alcohol?",
      choices: ["Yes", "No", "Occasionally"],
    },
    {
      question: "Do you smoke? (Weed, Cigarettes, etc.)",
      choices: ["Yes", "No", "Occasionally"],
    },
    {
      question: "Are you in Greek life?",
      choices: ["Yes", "No", "Maybe"],
    },
    {
      question: "Are you bringing any pets?",
      choices: ["Dog", "Cat", "No"],
    },
    {
      question: "How social are you?",
      choices: [
        "I prefer time alone 🧘",
        "A small gathering is nice 👫",
        "I enjoy meeting new people 🎉",
        "Social butterfly, always out and about 🦋",
      ],
    },
  ];

  const questionKeys = [
    "preferredRoommateGender",
    "diet",
    "alcohol",
    "smoke",
    "greekLife",
    "pets",
    "social",
  ];

  const [answers, setAnswers] = useState(
    lifestylePreferences
      ? lifestylePreferences
      : questionKeys.reduce((acc, key) => ({ ...acc, [key]: null }), {})
  );

  const handleAnswerChange = (index, value) => {
    const key = questionKeys[index];
    const newAnswers = { ...answers, [key]: value };
    setAnswers(newAnswers);
    // console.log(newAnswers);
    setLifestylePreferences(newAnswers);
  };

  return (
    <div className="">
      <div className="bg-white rounded-lg w-full p-8">
        <h2 className="text-2xl font-bold mb-4">Lifestyle Preferences</h2>
        <div className="grid grid-cols-2 gap-y-2">
          {dropDownQuestions.map((q, index) => (
            <div key={index} className="mb-6">
              <label className="block mb-2 text-md font-medium">{q.question}</label>
              <div className="flex items-center justify-between">
                {q.type === "text" ? (
                  <input
                    type="text"
                    value={answers[questionKeys[index]] || ""}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    className="form-input w-1/2 border"
                    placeholder="Enter your allergies"
                  />
                ) : (
                  <select
                    value={answers[questionKeys[index]] || ""}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    className="form-select w-1/2 border"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {q.choices.map((choice, idx) => (
                      <option key={idx} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </select>
                )}
                {q.rightText && (
                  <span className="text-md text-gray-600 w-1/4 text-right">
                    {q.rightText}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LifestylePreferencesQuestionnaire;
