import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center text-center">
      {/* Hero Section */}
      <section className="bg-[#0A2463] text-white py-20 w-full flex flex-col items-center">
        <h1 className="text-5xl font-bold mb-4">Welcome to Abode</h1>
        <p className="text-xl max-w-4xl mx-auto leading-relaxed">
          Simplifying student housing with seamless solutions and connections that matter.
        </p>
      </section>

      {/* Mission Section */}
      <section className="py-16 bg-white w-full flex flex-col items-center">
        <h2 className="text-4xl font-semibold text-gray-800 mb-6">Our Mission</h2>
        <p className="text-lg max-w-3xl text-gray-600 leading-relaxed">
          At Abode, we believe that finding the perfect student housing should be easy, accessible, and stress-free. Our mission is to revolutionize the way students connect with apartments and go through their housing process, by providing personalized matches and transparent information to make smarter decisions.
        </p>
      </section>

      {/* Who We Are Section */}
      <section className="py-16 bg-gray-50 w-full flex flex-col items-center">
        <h2 className="text-4xl font-semibold text-gray-800 mb-6">Who We Are</h2>
        <p className="text-lg max-w-3xl text-gray-600 leading-relaxed mb-6">
          We are a passionate team of innovators, technologists, and problem-solvers. Founded by students for students, Abode is built with an understanding of the unique needs and challenges of student housing.
        </p>
      </section>

      {/* Talent Section */}
      <section className="py-16 bg-white w-full flex flex-col items-center">
        <h2 className="text-4xl font-semibold text-gray-800 mb-6">We're Hiring!</h2>
        <p className="text-lg max-w-3xl text-gray-600 leading-relaxed">
          We’re looking for passionate, driven individuals to join us. If you’re excited about transforming the student housing market and thrive in a fast-paced startup environment, we’d love to chat.
        </p>
        <div className="flex justify-center gap-10 mt-8">
          <div className="bg-[#0A2463] text-white px-6 py-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h3 className="font-bold text-xl mb-2">Founding Engineer</h3>
            <p>Join us to build and scale our platform with cutting-edge tech.</p>
          </div>
          <div className="bg-[#0A2463] text-white px-6 py-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h3 className="font-bold text-xl mb-2">B2B Sales Lead</h3>
            <p>Drive partnerships with housing companies and drive our growth.</p>
          </div>
          <div className="bg-[#0A2463] text-white px-6 py-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <h3 className="font-bold text-xl mb-2">Student Marketing Lead</h3>
            <p>Help us reach more students and engage our community.</p>
          </div>
        </div>
      </section>

      {/* Current Progress Section */}
      <section className="py-16 bg-gray-50 w-full flex flex-col items-center">
        <h2 className="text-4xl font-semibold text-gray-800 mb-6">Our Progress</h2>
        <p className="text-lg max-w-3xl text-gray-600 leading-relaxed mb-6">
          We’ve made huge strides since our launch! With over 1,000 student users and counting since 1 week of launch, Abode is growing every day. We’re proud to have partnered with key housing providers and are constantly expanding our platform.
        </p>
        <div className="flex justify-around w-full max-w-4xl">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-[#0A2463]">1,000+</h3>
            <p className="text-lg text-gray-600">Students Signed Up</p>
          </div>
          <div className="text-center">
            <h3 className="text-5xl font-bold text-[#0A2463]">20+</h3>
            <p className="text-lg text-gray-600">Apartment Partners</p>
          </div>
          <div className="text-center">
            <h3 className="text-5xl font-bold text-[#0A2463]">260</h3>
            <p className="text-lg text-gray-600">Daily Active Users</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-[#0A2463] text-white w-full flex flex-col items-center">
        <h2 className="text-4xl font-semibold mb-6">Let's Chat</h2>
        <p className="text-lg max-w-3xl leading-relaxed">
          Whether you’re interested in joining our team, partnering with us, or just want to know more about Abode, we’d love to hear from you.
        </p>
        <a
          href="mailto:adarsh@getabode.co"
          className="mt-8 px-6 py-3 bg-white text-[#0A2463] rounded-lg font-semibold hover:bg-gray-200 transition-colors duration-300"
        >
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default AboutUs;
