import React, { useState, useEffect } from "react";
import Modal from '../Modal';
import StarRating from '../StarRating';
import { collection, doc, getDoc, addDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ReviewModal = ({ isOpen, onClose, id, onReviewSubmit}) => {
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({
    name: "",
    description: "",
    ratingAverage: 0,
    ratingValue: 0,
    ratingMaintenance: 0,
    ratingAmenities: 0,
    ratingLiving: 0,
    ratingLocation: 0,
    ratingManagement: 0
    });
  const [charCount, setCharCount] = useState(0);
  const minCharCount = 150;
  const [errorMessage, setErrorMessage] = useState("");

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const totalRatings = newReview.ratingValue + newReview.ratingMaintenance + newReview.ratingAmenities + newReview.ratingLiving + newReview.ratingLocation + newReview.ratingManagement;
      const ratingAverage = totalRatings / 6;

      await addDoc(collection(db, "reviews", id, "apartmentReviews"), {
        ...newReview,
        date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long'}),
        ratingAverage: parseFloat(ratingAverage.toFixed(2))
      });

      const reviewDocRef = doc(db, "reviews", id);
      const reviewDoc = await getDoc(reviewDocRef);

      if (!reviewDoc.exists()) {
        await setDoc(reviewDocRef, {
          totalAverage: parseFloat(ratingAverage.toFixed(2)),
          totalValue: newReview.ratingValue,
          totalMaintenance: newReview.ratingMaintenance,
          totalAmenities: newReview.ratingAmenities,
          totalLiving: newReview.ratingLiving,
          totalLocation: newReview.ratingLocation,
          totalManagement: newReview.ratingManagement,
          numReviews: 1
        });
      } else {
        const reviewData = reviewDoc.data();
        await updateDoc(reviewDocRef, {
          totalAverage: reviewData.totalAverage + parseFloat(ratingAverage.toFixed(2)),
          totalValue: reviewData.totalValue + newReview.ratingValue,
          totalMaintenance: reviewData.totalMaintenance + newReview.ratingMaintenance,
          totalAmenities: reviewData.totalAmenities + newReview.ratingAmenities,
          totalLiving: reviewData.totalLiving + newReview.ratingLiving,
          totalLocation: reviewData.totalLocation + newReview.ratingLocation,
          totalManagement: reviewData.totalManagement + newReview.ratingManagement,
          numReviews: reviewData.numReviews + 1
        });
      }

      setNewReview({ name: "", description: "", ratingAverage: 0, ratingValue: 0, ratingMaintenance: 0, ratingAmenities: 0, ratingLiving: 0, ratingLocation: 0, ratingManagement: 0});
      await onReviewSubmit();
      onClose();
    } catch (error) {
      console.error("Error adding review:", error);
      setErrorMessage("There was an error submitting your review. Please try again.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h3 className="font-bold mb-2">Submit Your Review</h3>
      <form onSubmit={handleReviewSubmit}>
        {errorMessage && (
            <div className="mb-4 text-red-500">
              {errorMessage}
            </div>
        )}
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            value={newReview.name}
            onChange={(e) => setNewReview({ ...newReview, name: e.target.value })}
            className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm w-full"
            required
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">Review</label>
          <textarea
            value={newReview.description}
            onChange={(e) => {
              setNewReview({ ...newReview, description: e.target.value });
              setCharCount(e.target.value.length);
            }}
            className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm w-full"
            style={{ minHeight: "20vh", resize: "vertical" }}
            required
          />
          <div className="text-sm text-gray-500 mt-1">
            {charCount}/{minCharCount}
          </div>
        </div>

        <div className="flex justify-center">
          <div className="flex flex-col items-center" style={{ paddingRight: "54px" }}>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Value</label>
              <div>
                <StarRating
                  score={newReview.ratingValue}
                  setScore={(ratingValue) => setNewReview({ ...newReview, ratingValue })}
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Maintenance</label>
              <div>
                <StarRating
                  score={newReview.ratingMaintenance}
                  setScore={(ratingMaintenance) => setNewReview({ ...newReview, ratingMaintenance })}
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Amenities</label>
              <div>
                <StarRating
                  score={newReview.ratingAmenities}
                  setScore={(ratingAmenities) => setNewReview({ ...newReview, ratingAmenities })}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Living</label>
              <div>
                <StarRating
                  score={newReview.ratingLiving}
                  setScore={(ratingLiving) => setNewReview({ ...newReview, ratingLiving })}
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Location</label>
              <div>
                <StarRating
                  score={newReview.ratingLocation}
                  setScore={(ratingLocation) => setNewReview({ ...newReview, ratingLocation })}
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="block text-sm font-medium text-gray-700 mr-4">Management</label>
              <div>
                <StarRating
                  score={newReview.ratingManagement}
                  setScore={(ratingManagement) => setNewReview({ ...newReview, ratingManagement })}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className={`mt-4 py-2 px-4 rounded-xl shadow-md ${
            charCount >= minCharCount && newReview.ratingValue > 0 && newReview.ratingMaintenance > 0 && newReview.ratingAmenities > 0 && newReview.ratingLiving > 0 && newReview.ratingLocation > 0 && newReview.ratingManagement > 0 ? "bg-logo_blue text-white" : "bg-gray-300 text-gray-500"
          }`}
          disabled={charCount < minCharCount || newReview.ratingValue === 0 || newReview.ratingMaintenance === 0 || newReview.ratingAmenities === 0 || newReview.ratingLiving === 0 || newReview.ratingLocation === 0 || newReview.ratingManagement === 0}
        > Submit
        </button>
        <button
          type="button"
          onClick={onClose}
          className="mt-4 ml-2 bg-gray-200 text-black py-2 px-4 rounded-xl shadow-md"
        > Cancel
        </button>
      </form>
    </Modal>
  );

}


export default ReviewModal;
