// FriendsPage.js

import React, { useEffect, useState } from 'react';
import { auth } from '../firebase/index';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { fetchFriends, fetchPendingRequests, acceptFriendRequest, declineFriendRequest, sendFriendRequest, searchUsers } from '../backend/dbFriendsAPI';
import MainHeader from '../components/Headers/MainHeader';
import Footer from '../components/Headers/Footer';

function FriendsPage() {
    const [my_user] = useAuthState(auth);
    const [friends, setFriends] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (my_user) {
                try {
                    const friendsList = await fetchFriends(my_user.uid);
                    setFriends(friendsList);

                    const pendingRequestsList = await fetchPendingRequests(my_user.uid);
                    setPendingRequests(pendingRequestsList);
                } catch (error) {
                    console.error('Error fetching friends or pending requests:', error);
                }
            }
        };
        fetchData();
    }, [my_user]);

    const handleSearch = async () => {
        if (searchTerm) {
            try {
                const results = await searchUsers(searchTerm);
                // Filter out the current user
                const filteredResults = results.filter(user => user.id !== my_user.uid);
                setSearchResults(filteredResults);
            } catch (error) {
                console.error('Error searching users:', error);
            }
        }
    };

    const handleSendFriendRequest = async (toUserId) => {
        if (friends.some(friend => friend.id === toUserId)) {
            alert('You are already friends with this user.');
            return;
        }

        try {
            await sendFriendRequest(my_user.uid, toUserId);
            alert('Friend request sent!');
        } catch (error) {
            console.error('Error sending friend request:', error);
            alert('Failed to send friend request.');
        }
    };

    const handleAcceptRequest = async (requestId) => {
        try {
            await acceptFriendRequest(requestId);
            const friendsList = await fetchFriends(my_user.uid);
            setFriends(friendsList);
            const pendingRequestsList = await fetchPendingRequests(my_user.uid);
            setPendingRequests(pendingRequestsList);
        } catch (error) {
            console.error('Error accepting friend request:', error);
        }
    };

    const handleDeclineRequest = async (requestId) => {
        try {
            await declineFriendRequest(requestId);
            setPendingRequests(pendingRequests.filter(request => request.id !== requestId));
        } catch (error) {
            console.error('Error declining friend request:', error);
        }
    };

    if (!my_user) {
        return <div>You need to log in to view your friends and pending requests.</div>;
    }

    return (
        <div className="bg-gray-50 relative">
            <MainHeader />
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 h-screen">
                <h1 className="text-2xl font-bold py-6">Friends</h1>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Search Users</h2>
                    <div className="flex mb-4">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="border p-2 flex-grow rounded-l"
                            placeholder="Search by first name..."
                        />
                        <button
                            onClick={handleSearch}
                            className="bg-logo_blue text-white px-4 py-2 rounded-r"
                        >
                            Search
                        </button>
                    </div>
                    <ul>
                        {searchResults.map(user => (
                            <li key={user.id} className="mb-2">
                                <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img src={user.profilePicture} alt={user.firstName} className="w-12 h-12 rounded-full mr-4" />
                                        <div>
                                            <p className="font-semibold">{user.firstName} {user.lastName}</p>
                                            <p className="text-gray-600">{user.email}</p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleSendFriendRequest(user.id)}
                                        className="bg-logo_blue text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                                    >
                                        Send Friend Request
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Friends List</h2>
                    <ul>
                        {friends.map(friend => (
                            <li key={friend.id} className="mb-2">
                                <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img src={friend.profilePicture} alt={friend.firstName} className="w-12 h-12 rounded-full mr-4" />
                                        <div>
                                            <p className="font-semibold">{friend.firstName} {friend.lastName}</p>
                                            <p className="text-gray-600">{friend.email}</p>
                                        </div>
                                    </div>
                                    <Link
                                        to={`/profile/${friend.id}`}
                                        className="bg-logo_blue text-white border border-logo_blue px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-logo_blue focus:ring-opacity-50"
                                    >
                                        Profile
                                    </Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h2 className="text-xl font-semibold mb-2">Pending Friend Requests</h2>
                    <ul>
                        {pendingRequests.map(request => (
                            <li key={request.id} className="mb-2">
                                <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img src={request.profilePicture} alt={request.fromUserName} className="w-12 h-12 rounded-full mr-4" />
                                        <div>
                                            <p className="font-semibold">{request.fromUserName}</p>
                                            <p className="text-gray-600">{request.fromUserEmail}</p>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleAcceptRequest(request.id)}
                                            className="bg-logo_blue text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                                        >
                                            Accept
                                        </button>
                                        <button
                                            onClick={() => handleDeclineRequest(request.id)}
                                            className="border border-logo_blue px-4 py-2 rounded hover:bg-red-600 transition-colors"
                                        >
                                            Decline
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FriendsPage;
