import { useState, useEffect } from "react";
import {
  Building2,
  Clock,
  Search,
  Users,
  Star,
  FileText,
  Instagram,
} from "lucide-react";
import { addPhoneNumber } from "../backend/dbAPI";
import AbodeListings from "../data/imgs/AbodeListings.png";
import { useNavigate } from "react-router-dom";

export default function QuickSignupPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [confirmationMessage, setConfirmationMessage] = useState(""); // Confirmation message
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const countDownDate = new Date("2024-09-19T00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      });
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidPhoneNumber(phoneNumber)) {
      setErrorMessage(""); // Clear error message if valid
      setLoading(true); // Set loading state
      setConfirmationMessage(""); // Clear any previous confirmation message

      try {
        localStorage.setItem("phoneNumber", phoneNumber);
        await addPhoneNumber(phoneNumber);
        setConfirmationMessage("We will text you when we launch :)"); // Show confirmation message
        setPhoneNumber(""); // Clear input field
      } catch (error) {
        setErrorMessage("Failed to add phone number. Please try again.");
        setConfirmationMessage(""); // Clear any previous confirmation message
      } finally {
        setLoading(false); // Remove loading state
      }
    } else {
      setErrorMessage("Please enter a valid phone number.");
      setConfirmationMessage(""); // Clear any previous confirmation message
    }
  };

  return (
    <div className="flex flex-col bg-white">
      <main className="flex-1">
        <section className="w-full py-8 md:py-16 lg:py-24 bg-gray-50">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2 max-w-3xl">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold tracking-tighter text-logo_blue">
                  Find Your Next U of I Apartment in Minutes
                </h1>
                <p className="mx-auto max-w-[90%] sm:max-w-[700px] text-sm sm:text-sm md:text-xl text-gray-500">
                  Simplify your student housing. View all apartments near
                  campus, connect with students, and secure your ideal living
                  space—all in one place.
                </p>
              </div>

              {/* <div className="py-8 md:py-0 md:pt-4 space-y-2">
                <h3 className="text-lg sm:text-xl font-semibold">
                  Launching September 19th
                </h3>
                <div className="flex justify-center flex-wrap space-x-4">
                  {Object.entries(countdown).map(([unit, value]) => (
                    <div key={unit} className="flex flex-col items-center">
                      <div className="text-lg sm:text-xl md:text-2xl font-bold text-[#0A2463]">
                        {value}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-500">
                        {unit}
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}

              {/* <div className="w-full max-w-md space-y-2 pt-4">
                <form onSubmit={handleSubmit} className="flex space-x-2">
                  <input
                    className="flex-1 border border-logo_blue text-logo_blue rounded p-2 text-sm sm:text-base placeholder-light_logo_blue"
                    placeholder="Enter your phone number"
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={loading} // Disable input when loading
                  />

                  <button
                    type="submit"
                    className={`bg-gradient-blue-r text-white px-3 sm:px-4 py-2 rounded text-sm sm:text-base ${
                      loading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-[#0A2463]/90"
                    }`}
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? "Submitting..." : "Notify Me"}
                  </button>
                </form>
                {errorMessage && (
                  <p className="text-red-500 text-xs sm:text-sm">
                    {errorMessage}
                  </p>
                )}
                {confirmationMessage && (
                  <p className="text-green-500 text-xs sm:text-sm">
                    {confirmationMessage}
                  </p>
                )}
                <p className="text-xs sm:text-sm text-gray-500">
                  Be the first to know when we launch. Most students secure
                  their apartments by October—don't miss out!
                </p>
              </div> */}

              {/* <div className="shadow-lg  w-full max-w-3xl border rounded-lg">
                <video
                  src="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/AbodeDemo.mp4"
                  className="rounded-lg shadow-md w-full"
                  alt="Roommate Matching Demo Video"
                  loop
                  muted
                  controls
                />
              </div> */}

<div className="py-4">


              <div className="space-y-4 w-full max-w-3xl border rounded-lg">
                <img
                  src={AbodeListings}
                  alt="Apartment Search Screenshot"
                  className="rounded-lg shadow-md w-full"
                />
              </div>
              </div>
              <button
                onClick={() => {
                  navigate("/listings");
                }}
                type="submit"
                className={`bg-gradient-blue-r text-white mt-4 px-4 py-3 rounded font-semibold text-md sm:text-base ${"hover:bg-[#0A2463]/90"}`}
              >
                {"Explore Apartments"}
              </button>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  All Apartments in One Place
                </h2>
                <p className="text-gray-500">
                  Browse through every apartment near UIUC, compare prices, and
                  find your perfect match with our intuitive search.
                </p>
                {/* <button
                  onClick={() => {
                    navigate("/listings");
                  }}
                  type="submit"
                  className={`bg-gradient-blue-r text-white px-3 sm:px-4 py-2 rounded text-sm sm:text-base ${"hover:bg-[#0A2463]/90"}`}
                >
                  {"View Apartments Now"}
                </button> */}
              </div>
              <div className="space-y-4">
                <img
                  src={AbodeListings}
                  alt="Apartment Search Screenshot"
                  className="rounded-lg shadow-md w-full"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4 order-2 md:order-1">
                <video
                  src="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/AbodeRoommateDemo.mp4"
                  className="rounded-lg shadow-md w-full"
                  alt="Roommate Matching Demo Video"
                  autoPlay
                  loop
                  muted
                  controls
                />
              </div>
              <div className="space-y-4 order-1 md:order-2">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  Connect with Students
                </h2>
                <p className="text-gray-500">
                  Find compatible roommates, explore student profiles, and
                  easily connect and message to coordinate your housing plans.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  Sign with Confidence
                </h2>
                <p className="text-gray-500">
                  From apartment recommendations to understanding lease terms,
                  we guide you with student-focused guides, tips, and
                  resources—ensuring you're informed and ready to sign.{" "}
                  <a
                    href="https://alpine-throat-006.notion.site/Apartment-Process-for-Students-10251741e8038005960ed35d943a5673"
                    className="text-logo_blue underline hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"View Doc >"}
                  </a>
                </p>
              </div>
              <div className="space-y-4 order-2 md:order-1">
                <video
                  src="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/AbodeGuideDemo.mp4"
                  className="rounded-lg shadow-md w-full"
                  alt="Roommate Matching Demo Video"
                  autoPlay
                  loop
                  muted
                  controls
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center text-center space-y-4">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  Stay Updated with Abode
                </h2>
                <p className="max-w-[600px] mx-auto text-gray-500 md:text-xl">
                  Follow us on Instagram for the latest apartment deals, student
                  interviews, new apartment tours, and more exciting content
                  coming soon!
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() =>
                    window.open("https://www.instagram.com/abodeuiuc", "_blank")
                  }
                  className="flex items-center bg-[#E1306C] text-white px-4 py-2 rounded-md hover:bg-[#C13584]"
                >
                  <Instagram className="h-6 w-6 mr-2" />
                  Follow Us on Instagram
                </button>
              </div>
            </div>
          </div>
        </section> */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container max-w-screen-lg mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold text-center mb-12 text-[#0A2463]">
              All Your Housing Needs in One Place
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center space-y-3 text-center">
                <Building2 className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">
                  All UIUC Apartments Listed
                </h3>
                <p className="text-sm text-gray-500">
                  Every single apartment near UIUC listed in one place.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Users className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Connect with Roommates</h3>
                <p className="text-sm text-gray-500">
                  Easily connect with other student roommates.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Search className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">
                  Intuitive Apartment Search
                </h3>
                <p className="text-sm text-gray-500">
                  Find and sign for your apartment with our easy-to-use search.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Clock className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Up-to-Date Information</h3>
                <p className="text-sm text-gray-500">
                  Get the latest prices, availability, and apartment
                  information.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Star className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Real Student Reviews</h3>
                <p className="text-sm text-gray-500">
                  Read authentic reviews from fellow students.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <FileText className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Sublease Listings</h3>
                <p className="text-sm text-gray-500">
                  Find or post sublease opportunities easily.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center mt-8 text-center">
              {/* <a href="/listings" className="text-[#0A2463]">
                {"View Unofficial Beta Now ->"}
              </a> */}
              <button
                onClick={() => {
                  navigate("/listings");
                }}
                type="submit"
                className={`bg-gradient-blue-r text-white mt-4 px-4 py-3 rounded font-semibold text-md sm:text-base ${"hover:bg-[#0A2463]/90"}`}
              >
                {"Explore Apartments Now"}
              </button>
            </div>
          </div>
        </section>
        {/* <section
          id="signup"
          className="w-full py-12 md:py-24 lg:py-32 bg-[#0A2463] text-white"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl">
                  Don't Miss Out on Your Perfect Apartment
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-300 md:text-xl">
                  Most UIUC students sign leases in October. Be the first to
                  know when Abode launches and secure your ideal living space.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form onSubmit={handleSubmit} className="flex space-x-2">
                  <input
                    className="flex-1 bg-white text-black border border-gray-300 rounded p-2"
                    placeholder="Enter your phone number"
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={loading} // Disable input when loading
                  />
                  <button
                    type="submit"
                    className={`bg-white text-[#0A2463] px-4 py-2 rounded ${
                      loading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-gray-100"
                    }`}
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? "Submitting..." : "Notify Me"}
                  </button>
                </form>
                {errorMessage && (
                  <p className="text-red-500 text-xs sm:text-sm">
                    {errorMessage}
                  </p>
                )}
                {confirmationMessage && (
                  <p className="text-green-500 text-xs sm:text-sm">
                    {confirmationMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © 2024 Abode. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            href="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/Abode+Privacy+Policy.pdf"
            className="text-xs hover:underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <a
            href="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/Abode+Terms+of+Service.pdf"
            className="text-xs hover:underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </nav>
      </footer>
    </div>
  );
}
