// src/components/AddToRoommateListPopup.js
import React from 'react';

function AddToRoommateListPopup({ onConfirm, onCancel }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Looking for Roommates?</h2>
        <p className="mb-4">
          Confirm that you are looking for roommates at this apartment. This will allow other students who are also interested in this apartment to connect with you.
        </p>
        <div className="flex justify-end">
          <button
            onClick={onCancel}
            className="mr-4 px-4 py-2 bg-gray-200 text-black rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-logo_blue text-white rounded-lg"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddToRoommateListPopup;
