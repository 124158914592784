import React, { useRef, useCallback, useState, useEffect } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import useGoogleMapElements from "./useGoogleMapElements";
import polygonData from "./polygonData.json"

const LocationCard = ({ latitude, longitude }) => {
  const mapCenter = { lat: latitude, lng: longitude };
  const googleMapsApiKey = "AIzaSyCU2HcNBFcNhluwAQ9YlTscpNqU0RvD-8c";
  const [map, setMap] = useState(null);

  // Define the coordinates for the polygons
  const polygonMap = new Map(polygonData);
  
  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {}, [map]);

  useGoogleMapElements(map, mapCenter, polygonMap);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <div className="">
        
        <div className="rounded-lg overflow-hidden">
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "500px",
              borderRadius: "8px",
            }}
            center={mapCenter}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{ mapId: "5931d562ae32bd1c" }}
          >
            {/* Polygons are managed by the custom hook */}
          </GoogleMap>
        </div>
      </div>
    </LoadScript>
  );
};

export default LocationCard;
