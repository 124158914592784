import React, { useState } from 'react';
import {sendBulkEmails} from '../../backend/emailAPI'

const BulkEmailSender = () => {
  // State for inputs
  const [emailList, setEmailList] = useState('');
  const [subject, setSubject] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [previewContent, setPreviewContent] = useState('');

  // Handler for form submission
  const handleSendEmails = async (e) => {
    e.preventDefault();

    const emails = emailList.split(',').map((email) => email.trim()); // Split emails by comma
    
    try {
      const resp = await sendBulkEmails(emails, "contact@getabode.co", subject, "", htmlContent);
      // console.log(resp);
      
      // Mock post request to send emails, integrate with your backend or SendGrid API
      // await axios.post('/send-bulk-emails', {
      //   emails,
      //   subject,
      //   htmlContent,
      // });
      alert('Emails sent successfully!');
    } catch (error) {
      console.error('Error sending emails', error);
      alert('Failed to send emails');
    }
  };

  // Handler to update preview
  const handlePreview = () => {
    setPreviewContent(htmlContent);
  };

  return (
    <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Bulk Email Sender</h2>
      
      <form onSubmit={handleSendEmails} className="space-y-6">
        {/* Email List Input */}
        <div>
          <label htmlFor="emails" className="block text-lg font-medium text-gray-700">Recipient Emails (comma-separated):</label>
          <textarea
            id="emails"
            value={emailList}
            onChange={(e) => setEmailList(e.target.value)}
            placeholder="Enter recipient emails..."
            rows="3"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        {/* Subject Input */}
        <div>
          <label htmlFor="subject" className="block text-lg font-medium text-gray-700">Email Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter email subject..."
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* HTML Content Input */}
        <div>
          <label htmlFor="htmlContent" className="block text-lg font-medium text-gray-700">Email HTML Content:</label>
          <textarea
            id="htmlContent"
            value={htmlContent}
            onChange={(e) => setHtmlContent(e.target.value)}
            placeholder="Enter email HTML code..."
            rows="5"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>

        {/* Preview Button */}
        <button
          type="button"
          onClick={handlePreview}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Preview Email
        </button>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-green-600 text-white px-4 py-2 rounded-lg shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Send Emails
        </button>
      </form>

      {/* HTML Preview */}
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Email Preview:</h3>
        <div
          className="border p-4 rounded-lg shadow-md bg-white"
          dangerouslySetInnerHTML={{ __html: previewContent }}
        ></div>
      </div>
    </div>
  );
};

export default BulkEmailSender;
