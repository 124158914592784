// emailAPI.js
export async function sendBulkEmails(
  recipients,
  sender,
  subject,
  message,
  htmlMessage
) {
  const endpoint = process.env.REACT_APP_AWS_BULK_EMAIL_ENDPOINT; // Update with your bulk email API endpoint

  const payload = {
    recipients, // This is an array of email addresses
    sender,
    subject,
    message,
    htmlMessage,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const postPayload = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };

  // console.log("Bulk email payload:", postPayload, endpoint);

  try {
    const response = await fetch(endpoint, postPayload);

    if (!response.ok) {
      throw new Error("Failed to send bulk emails");
    }

    const responseData = await response.json();
    return responseData; // Return the response data from the API
  } catch (error) {
    console.error("Error sending bulk emails:", error);
    throw error;
  }
}

export async function sendEmail(
  recipient,
  sender,
  subject,
  message,
  htmlMessage
) {
  // 
  if (recipient == "jsm@jsmliving.com") {
    recipient = "adarshdayal10@gmail.com"
  }
  const endpoint = process.env.REACT_APP_AWS_EMAIL_2_ENDPOINT;
  const payload = {
    recipient,
    sender,
    subject,
    message,
    htmlMessage,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  const postPayload = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };

  // console.log(postPayload, endpoint);

  try {
    const response = await fetch(endpoint, postPayload);
    if (!response.ok) {
      throw new Error("Failed to send email");
    }
    return response;
  } catch (error) {
    console.error("Error sending email:", error);
    throw error;
  }
}

export async function sendContactEmail(formData, apartmentEmail, apartmentPropertyName) {
  // console.log("sending contact email to ", apartmentEmail);
  if (apartmentEmail === null) {
    apartmentEmail = "adarshdayal10@gmail.com";
    return;
  }

  const propertyInfo = apartmentPropertyName ? `Interested Property: ${apartmentPropertyName}\n` : "";
  const propertyHtml = apartmentPropertyName ? `<li><strong>Interested Property:</strong> ${apartmentPropertyName}</li>` : "";

  const messageString = `
    Congrats!
  
    You have a new student lead from Abode. Here are the details:
  
    ${propertyInfo}
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Message: ${formData.description}
  
    Please respond as soon as possible to ${formData.name} and their message.
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Congrats!</p>
        <p>You have a new student lead and message from Abode. Here are the details:</p>
        <ul>
          ${propertyHtml}
          <li><strong>Name:</strong> ${formData.name}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
          <li><strong>Phone:</strong> ${formData.phone}</li>
          <li><strong>Message:</strong> ${formData.description}</li>
          <li><strong>Preferred Contact Method:</strong> ${formData.preferredContact}</li>
        </ul>
        <p>Please respond as soon as possible to ${formData.name} and their message.</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `New Message | Student Lead Name: ${formData.name}`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendTourBookEmail(formData, apartmentEmail, apartmentPropertyName) {
  // console.log("sending tour booking email to ", apartmentEmail);
  if (apartmentEmail === null) {
    return;
  }

  const propertyInfo = apartmentPropertyName ? `Interested Property: ${apartmentPropertyName}\n` : "";
  const propertyHtml = apartmentPropertyName ? `<li><strong>Interested Property:</strong> ${apartmentPropertyName}</li>` : "";

  const messageString = `
    Congrats!
  
    A new student requested to book a tour with you from Abode. Here are the details:
  
    ${propertyInfo}
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Message: ${formData.description}
    Preffered Contact Method: ${formData.preferredContact}
  
    Please respond as soon as possible to ${formData.name} and their message.
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Congrats!</p>
        <p>A new student requested to book a tour with you from Abode. Here are the details:</p>
        <ul>
          ${propertyHtml}
          <li><strong>Name:</strong> ${formData.name}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
          <li><strong>Phone:</strong> ${formData.phone}</li>
          <li><strong>Message:</strong> ${formData.description}</li>
          <li><strong>Preffered Contact Method:</strong> ${formData.preferredContact}</li>
        </ul>
        <p>Please respond as soon as possible to ${formData.name} and their message.</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `Tour Request | New Student Lead | Name: ${formData.name}`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendVisitWebsiteEmail(formData, apartmentEmail, apartmentPropertyName) {
  // console.log("sending visit website email to ", apartmentEmail);
  if (apartmentEmail === null) {
    return;
  }

  const propertyInfo = apartmentPropertyName ? `Interested Property: ${apartmentPropertyName}\n` : "";
  const propertyHtml = apartmentPropertyName ? `<li><strong>Interested Property:</strong> ${apartmentPropertyName}</li>` : "";

  const messageString = `
    Congrats!
  
    We have referred a student to your website.
    A new student is interested in your apartment from Abode. Here are the details:
  
    ${propertyInfo}
    Name: ${formData.name}
    Email: ${formData.email}
    Message: ${formData.description}
  
    ${formData.name} is interested in hearing about your specials or promotions.
    We have referred them to your website for more information.
    Lead Strength: Medium
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Congrats!</p>
        <p>A new student is interested in your apartment from Abode. Here are the details:</p>
        <ul>
          ${propertyHtml}
          <li><strong>Name:</strong> ${formData.name}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
          <li><strong>Message:</strong> ${formData.description}</li>
        </ul>
        <p>${formData.name} is interested in hearing about your specials or promotions.</p>
        <p>We have referred them to your website for more information</p>
        <p>Lead Strength: Medium</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `Website Referral | New Student Lead | Name: ${formData.name}`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendApplyEmail(formData, apartmentEmail, apartmentPropertyName) {
  // console.log("sending apply email to ", apartmentEmail);
  if (apartmentEmail === null) {
    return;
  }

  const propertyInfo = apartmentPropertyName ? `Interested Property: ${apartmentPropertyName}\n` : "";
  const propertyHtml = apartmentPropertyName ? `<li><strong>Interested Property:</strong> ${apartmentPropertyName}</li>` : "";

  const messageString = `
    Congrats!
  
    We have referred a student to your application page.
    A new student is interested in your apartment from Abode. Here are the details:
  
    ${propertyInfo}
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Message: ${formData.description}
  
    We have referred them to your website application page for more information and to submit their leasing application.
    Lead Strength: Medium
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Congrats!</p>
        <p>Abode has referred an interested student to your application page. Here are the details:</p>
        <ul>
          ${propertyHtml}
          <li><strong>Name:</strong> ${formData.name}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
          <li><strong>Phone:</strong> ${formData.phone}</li>
          <li><strong>Message:</strong> ${formData.description}</li>
          <li><strong>Preffered Contact Method:</strong> ${formData.preferredContact}</li>
        </ul>
        <p>We have referred them to your website application page for more information and to submit their leasing application.</p>
        <p>Lead Strength: High</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `Application Referral | New Student Lead | Name: ${formData.name}`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendSubleaseHostEmail(formData, hostEmail) {
  // console.log("sending sublease host email to ", hostEmail);
  // apartmentEmail = "adarshdayal10@gmail.com";
  if (hostEmail === null) {
    return;
  }
  const messageString = `
    Congrats!
  
    A student is interested in staying at your place. Here are the details:
  
    Name: ${formData.firstName} ${formData.lastName}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Message: ${formData.description}
  
    Please respond as soon as possible to ${formData.name} to further discuss your sublease.
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Congrats!</p>
        <p>A student is interested in staying at your place. Here are the details:</p>
        <ul>
          <li><strong>Name:</strong> ${formData.firstName} ${formData.lastName}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
          <li><strong>Phone:</strong> ${formData.phone}</li>
          <li><strong>Message:</strong> ${formData.description}</li>
        </ul>
        <p> Please respond to ${formData.firstName} as soon as possible to further discuss your sublease.</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", hostEmail);
  const resp = await sendEmail(
    hostEmail,
    "contact@getabode.co",
    `${formData.firstName} is interested in your sublease!`,
    messageString,
    htmlContent
  );
  return resp;
  // Add email send here
}

export async function sendSubleaseUserConfirmationEmail(formData, hostData, userEmail) {
  // console.log("sending sublease host email to ", userEmail);
  // apartmentEmail = "adarshdayal10@gmail.com";
  if (userEmail === null) {
    return;
  }
  const messageString = `
    Hey ${formData.firstName},
  
    This is a confirmation that your message has been sent to ${hostData.firstName} ${hostData.lastName} regarding their sublease. Your message is as follows:
 
    Message: ${formData.description}
  
    Look out for a response from ${hostData.firstName} soon!
  
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Hey ${formData.firstName},</p>
        <p>This is a confirmation that your message has been sent to ${hostData.firstName} ${hostData.lastName} regarding their sublease. Your message is as follows:</p>
        <ul>
          <li><strong>Message:</strong> ${formData.description}</li>
        </ul>
        <p>Look out for a response from ${hostData.firstName} soon!</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", userEmail);
  // console.log("Form Data:", formData);
  const resp = await sendEmail(
    userEmail,
    "contact@getabode.co",
    `Confirmation: Sublease question sent to ${hostData.firstName}`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendRoommateRequestListed(formData, apartmentEmail) {
  // console.log("sending apply email to ", apartmentEmail);
  if (apartmentEmail === null) {
    return;
  }

  const messageString = `
    Hello!
  
    You have a new roommate request. Here is the information of the student:
  
    Name: ${formData.firstName } ${formData.lastName}
    Email: ${formData.email}
    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Hello!</p>
        <p> You have a new roommate request. Here is the information of the student:</p>
        <ul>
          <li><strong>Name:</strong> ${formData.firstName}  ${formData.lastName}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
        </ul>
        <p>Lead Strength: Medium</p>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  // console.log("Form Data:", formData);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `Incoming Roommate Request!`,
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendRoommateRequestMessage(formData, toStudentEmail, fromStudentMessage, fromStudentPreferredContact, profileLink) {
  // console.log("Sending roommate request email to", toStudentEmail);
  if (toStudentEmail === null) {
    return;
  }

  const messageString = `
    Hello!

    You have received a new roommate request from Abode!

    Message from ${formData.firstName} ${formData.lastName}: 
    "${fromStudentMessage}"

    Year and Major: ${formData.year} in ${formData.major}
    Preferred Contact Method: ${fromStudentPreferredContact}

    To know more about ${formData.firstName}, check out their roommate profile here: ${profileLink}

    Please reach out to ${formData.firstName} directly using their preferred contact method to continue the conversation.

    Note: Do not reply to this email. Abode is here to connect students, and we encourage you to take it from here!

    Best wishes,
    The Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Hello!</p>
        <p>You have received a new roommate request from Abode!</p>
        <p><strong>Message from ${formData.firstName} ${formData.lastName}:</strong></p>
        <blockquote>"${fromStudentMessage}"</blockquote>
        <ul> 
          <li><strong>Year and Major:</strong> ${formData.year} in ${formData.major}</li>
          <li><strong>Preferred Contact Method:</strong> ${fromStudentPreferredContact}</li>
        </ul>
        <p>To know more about ${formData.firstName}, check out their roommate profile here: <a href="${profileLink}">View Profile</a></p>
        <p>Please reach out to ${formData.firstName} directly using their preferred contact method to continue the conversation.</p>
        <p><em>Note:</em> Do not reply to this email. Abode is here to connect students, and we encourage you to take it from here!</p>
        <p>Best wishes,<br/>
        The Abode Team</p>
      </body>
    </html>
  `;

  const resp = await sendEmail(
    toStudentEmail,
    "contact@getabode.co",
    "Incoming Roommate Request!",
    messageString,
    htmlContent
  );
  return resp;
}

export async function sendRoommateRequest(formData, apartmentEmail) {
  // console.log("sending apply email to ", apartmentEmail);
  if (apartmentEmail === null) {
    return;
  }

  const messageString = `
    Hello!
  
    We sent your roommate request. Here is the information of the student:
  
    Name: ${formData.firstName } ${formData.lastName}
    Email: ${formData.email}

    Best Wishes,
    Abode Team
  `;

  const htmlContent = `
    <html>
      <body>
        <p>Hello!</p>
        <p>We sent your roommate request. Here is the information of the student:</p>
        <ul>
          <li><strong>Name:</strong> ${formData.firstName}  ${formData.lastName}</li>
          <li><strong>Email:</strong> ${formData.email}</li>
        </ul>
        <p>Best Wishes,<br/>
        Abode Team</p>
      </body>
    </html>
  `;

  // console.log("Sending email to:", apartmentEmail);
  // console.log("Form Data:", formData);
  const resp = await sendEmail(
    apartmentEmail,
    "contact@getabode.co",
    `Roommate Request Sent!`,
    messageString,
    htmlContent
  );
  return resp;
}

export default sendEmail;
