import { React, useState, useEffect } from "react";
import MainHeader from "../components/Headers/MainHeader";
import { apartments } from "../data/all_apartments";
import { useAuthState } from "react-firebase-hooks/auth";
import Footer from "../components/Headers/Footer";
import PopupLogin from "../components/PopupLogin";
import { auth } from "../firebase/index";
import { trackClick } from "../backend/dbAPI";

const UIUCApartmentListings = () => {
  const [user, loading, error] = useAuthState(auth);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!user && !loading) {
      const timer = setTimeout(() => {
        setShowModal(true);
        document.body.style.overflow = "hidden"; // Prevent scrolling
      }, 5000);

      return () => {
        clearTimeout(timer);
        document.body.style.overflow = "unset"; // Re-enable scrolling
      };
    }
  }, [user, loading]);

  const ApartmentListings = () => {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-2 text-gray-900">
          Ultimate UIUC Apartment List
        </h1>
        <h2 className="text-lg font-semibold mb-8 text-gray-600">
          Every single apartment by UIUC listed here.
        </h2>

        {/* Single Buildings */}
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Apartments
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {apartments
            .filter((apartment) => !apartment.buildings && !apartment.listings)
            .map((apartment, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-200 ease-in-out"
              >
                {apartment.img && (
                  <button
                    style={{ backgroundImage: `url(${apartment.img})` }}
                    className="w-full bg-cover bg-center rounded-md aspect-square"
                    onClick={(e) => {
                      e.preventDefault();
                      if (user) {
                        window.open(apartment.link, "_blank");
                        trackClick(user.uid, apartment.id).catch((error) => {
                          console.error("Error tracking click:", error);
                        });
                      } else {
                        window.open(apartment.link, "_blank");
                      }
                    }}
                  ></button>
                )}

                <div className="py-2 px-4">
                  <h3 className="text-xl font-semibold text-gray-700 mb-2">
                    {apartment.name}
                  </h3>
                </div>
              </div>
            ))}
        </div>

        {/* Apartment Groups */}
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Apartment Groups
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {apartments
            .filter((apartment) => apartment.listings)
            .map((apartment, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-200 ease-in-out"
              >
                {apartment.img && (
                  <div className="relative pb-60">
                    <img
                      src={apartment.img}
                      alt={apartment.name}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                )}
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-gray-700">
                      {apartment.name}
                    </h3>
                    {apartment.link && (
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={(e) => {
                          e.preventDefault();
                          if (user) {
                            window.open(apartment.link, "_blank");
                            trackClick(user.uid, apartment.id).catch(
                              (error) => {
                                console.error("Error tracking click:", error);
                              }
                            );
                          } else {
                            window.open(apartment.link, "_blank");
                          }
                        }}
                      >
                        Link
                      </button>
                    )}
                  </div>
                  <p className="text-gray-600 mb-2">
                    Total Buildings: {apartment.listings}
                  </p>
                  {apartment.campusListings && (
                    <p className="text-gray-600 mb-2">
                      Buildings Close to Campus: {apartment.campusListings}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 relative">
      <MainHeader />
      <ApartmentListings />
      {showModal && !user && <PopupLogin />}
      <Footer />
    </div>
  );
};

export default UIUCApartmentListings;
