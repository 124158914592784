import React, { useEffect, useState } from "react";
import { parse, format, isValid } from "date-fns";
import ImageSlider from "../../components/Listings/ImageSlider";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase/index";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

function SubleasePage() {
  const navigate = useNavigate();
  const [subleases, setSubleases] = useState([]);
  const [userSubleases, setUserSubleases] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [subleaseToDelete, setSubleaseToDelete] = useState(null);
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);

  const onPostSublease = () => {
    navigate("/sublease-intro");
  };

  const fetchUserSubleases = async () => {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userSubleaseIds = userDoc.data().subleases || [];
        const userSubleasesData = await Promise.all(
          userSubleaseIds.map(async (subleaseId) => {
            const subleaseDocRef = doc(db, "subleases", subleaseId);
            const subleaseDoc = await getDoc(subleaseDocRef);
            return { id: subleaseDoc.id, ...subleaseDoc.data() };
          })
        );
        setUserSubleases(userSubleasesData);
      }
    } catch (error) {
      console.error("Error fetching user's subleases:", error);
    }
  };

  const fetchSubleases = async () => {
    try {
      const subleasesCollectionRef = collection(db, "subleases");
      const subleasesSnapshot = await getDocs(subleasesCollectionRef);
      const subleasesData = subleasesSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((sublease) => sublease.showOnWebsite);

      // console.log(subleasesData);
      setSubleases(subleasesData);
    } catch (error) {
      console.error("Error fetching subleases:", error);
    }
  };

  useEffect(() => {
    fetchUserSubleases();
    fetchSubleases();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      console.error("Invalid date string: ", dateString);
      return "Invalid Date";
    }
  
    const parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
    if (!isValid(parsedDate)) {
      console.error("Parsed date is invalid: ", parsedDate);
      return "Invalid Date";
    }
  
    return format(parsedDate, "M/d/yy");
  };
  

  const handleNavigate = (id) => {
    navigate(`/sublease/${id}`);
  };

  const handleDeleteClick = (subleaseId) => {
    setSubleaseToDelete(subleaseId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (!subleaseToDelete) return;

    
    try {
      const userId = auth.currentUser.uid;

      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        subleases: arrayRemove(subleaseToDelete),
      });

      const subleaseDocRef = doc(db, "subleases", subleaseToDelete);
      await updateDoc(subleaseDocRef, {
        showOnWebsite: false,
      });

      setShowModal(false);
      fetchUserSubleases();
      fetchSubleases();
    } catch (error) {
      console.error("Error deleting sublease:", error);
      setShowModal(false);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setSubleaseToDelete(null);
  };

  return (
    <div className="relative">

      {showModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          style={{ zIndex: 2 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium">
              Are you sure you want to delete this sublease?
            </h3>
            <div className="mt-4 flex justify-end">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              >
                Delete
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mx-auto p-4 md:px-16">
        {userSubleases && (
          <div>
            <h2 className="text-xl font-medium py-4">Your Subleases</h2>
            <ul className="mb-4">
              {userSubleases.map((sublease) => (
                <li
                  key={sublease.id}
                  className="flex justify-between items-center mb-2 p-2 border rounded-md shadow-sm"
                >
                  <div className="flex-grow">
                    <span
                      className="font-medium text-blue-600 hover:underline cursor-pointer"
                      onClick={() => handleNavigate(sublease.id)}
                    >
                      {sublease.apartmentName}
                    </span>
                    {" • "}${sublease.rent}/mo • {sublease.configuration} •{" "}
                    {sublease.bedrooms} beds • {sublease.bathrooms} bath •{" "}
                    {formatDate(sublease.startDate)} to{" "}
                    {formatDate(sublease.endDate)}
                  </div>
                  <button
                    className="text-red-500 ml-4"
                    onClick={() => handleDeleteClick(sublease.id)}
                  >
                    Delete Sublease
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex justify-between">
          <h1 className="text-2xl font-medium py-4">Short term stays</h1>
          <button
            className="text-sm font-medium py-4 underline"
            onClick={onPostSublease}
          >
            List your apartment
          </button>
        </div>
        <div
          className="grid gap-y-8 gap-x-8"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          }}
        >
          {subleases.map((sublease) => (
            <SubleaseListingCard key={sublease.id} sublease={sublease} />
          ))}
        </div>
      </div>
    </div>
  );
}

function SubleaseListingCard({ sublease }) {
  const navigate = useNavigate();
  const id = sublease.id;
  const beds = sublease.bedrooms;
  const baths = sublease.bathrooms;
  const startDate = sublease.startDate;
  const endDate = sublease.endDate;
  const rent = sublease.rent;
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      if (sublease.images && Array.isArray(sublease.images)) {
        const orderedUrls = sublease.images.map(img => img.url);
        setImageUrls(orderedUrls);
      } else {
        const storage = getStorage();
        const listRef = ref(storage, `sublease_images/${id}/`);
        try {
          const res = await listAll(listRef);
          const urls = await Promise.all(
            res.items.map((itemRef) => getDownloadURL(itemRef))
          );
          setImageUrls(urls);
        } catch (error) {
          console.error("Error fetching images: ", error);
        }
      }
    };

    fetchImages();
  }, [id, sublease]);

  const onImageClick = (e) => {
    e.preventDefault();
    navigate(`/sublease/${id}`);
    // console.log("Listing clicked");
  };

  const handleFavClick = () => {
    // console.log(id);
    // console.log("Fav clicked");
  };

  const parseDate = (dateString) => {
    const parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
    const formattedDate = format(parsedDate, "MMM d");
    return formattedDate;
  };

  return (
    <div className="relative">
      <div className="absolute top-0 right-0 z-10">
        <button onClick={handleFavClick}>
          <div className="p-2 m-2">
            <i className="fa-regular fa-heart fa-lg hover:scale-125 text-white"></i>
          </div>
        </button>
      </div>

      <div key={sublease.id}>
        <div className="relative flex flex-col rounded-md overflow-hidden">
          <div className="w-full overflow-hidden relative">
            <ImageSlider
              images={imageUrls}
              onImageClick={onImageClick}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
          <div className="cursor-pointer py-1" onClick={onImageClick}>
            <div className="flex justify-between">
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <div className="text-md font-medium text-black truncate">
                    {sublease.apartmentName}
                  </div>
                  <div className="text-sm text-black truncate"></div>
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {parseDate(startDate)} - {parseDate(endDate)}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {beds} beds • {baths} bath
                </div>
              </div>
              <div className="flex flex-col justify-between text-sm h-full"></div>
            </div>

            <div className="flex pt-2">
              <div className="text-sm font-semibold text-black">${rent}</div>
              <div className="text-sm text-black ml-1">month</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubleasePage;
