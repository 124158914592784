import { React, useState, useEffect } from "react";

import {
  BedRangeSelector,
  DistRangeSelector,
  PriceRangeSelector,
} from "./RangeSelector";
import { CgOptions } from "react-icons/cg";

function OptionModal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
      <div className="relative bg-white p-4 rounded-lg max-w-sm w-full">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 px-2 text-2xl"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

function Filters({ filters, setFilters }) {
  // Handler functions to update filters
  const setMapOpen = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      mapOpen: true,
    }));
  };

  const handleBedRangeChange = (newBedRange) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      bedRange: newBedRange,
    }));
  };

  const handlePriceChage = (newPriceRange) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: newPriceRange,
    }));
  };

  const handleDistRangeChange = (newRange) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      distRange: newRange,
    }));
    setMapOpen();
  };

  const handleLocationChange = (event) => {
    const newLocation = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedLocation: newLocation,
    }));
    setMapOpen();
  };

  const handlePricePerPersonToggle = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      pricePerPerson: !prevFilters.pricePerPerson,
    }));
  };

  const handlePrivateBathroomToggle = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      privateBathroom: !prevFilters.privateBathroom,
    }));
  };

  const handleAvailChange = (event) => {
    const newAvail = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      availability: newAvail,
    }));
  };

  const handleSortChange = (event) => {
    const newSort = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: newSort,
    }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isOptOpen, setIsOptOpen] = useState(false);

  const toggleIsOptOpen = () => setIsOptOpen(!isOptOpen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const applyFilters = () => {
    toggleModal();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%", // Adjust according to your needs
    },
  };

  function displayMobileFilters() {
    return (
      <div className="flex flex-col sm:text-xs">
        <div className="flex justify-center items-center">
          <BedRangeSelector
            min={0}
            max={5}
            bedRange={filters.bedRange}
            onChange={handleBedRangeChange}
          />
        </div>
        <div className="flex justify-center items-center">
          <PriceRangeSelector
            min={300}
            max={2000}
            priceRange={filters.priceRange}
            onChange={handlePriceChage}
          />
        </div>
        <div className="mt-4 flex justify-center items-center">
          <span className="text-md font-semibold">Location:</span>
          <select
            value={filters.selectedLocation}
            onChange={handleLocationChange}
            className="mx-2 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-logo_blue focus:border-logo_blue sm:text-sm"
          >
            <option value="Main Quad">Main Quad</option>
            <option value="Engineering Quad">Engineering Quad</option>
            <option value="CIF">CIF</option>
            <option value="South Quad">South Quad</option>
            <option value="Green Street">Green Street</option>
            <option value="ARC">ARC</option>
          </select>
        </div>
        <div className="flex justify-center items-center">
          <DistRangeSelector
            min={5}
            max={40}
            distRange={filters.distRange}
            onChange={handleDistRangeChange}
          />
        </div>

        <div className="flex justify-center items-center mt-4">
          <span className="mr-3 text-md font-semibold">
            Display price per person
          </span>
          <label
            htmlFor="price-per-person-toggle"
            className="flex items-center cursor-pointer"
          >
            <div className="relative">
              <input
                type="checkbox"
                id="price-per-person-toggle"
                className="sr-only"
                onChange={handlePricePerPersonToggle}
                checked={filters.pricePerPerson}
              />
              <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
              <div
                className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 ease-in-out ${
                  filters.pricePerPerson
                    ? "transform translate-x-full bg-white"
                    : "bg-gray-500"
                }`}
              ></div>
            </div>
          </label>
        </div>

        <button
          className="mt-12 px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
          onClick={applyFilters}
        >
          Done
        </button>
        <span className="text-sm mt-1 text-gray-500">
          *Visit getabode.co on your laptop for better search and live map!
        </span>
      </div>
    );
  }

  function showOptionsModal() {
    return (
      <OptionModal isOpen={isOptOpen} onClose={toggleIsOptOpen}>
        <div className="flex flex-col items-start space-y-4">
          <div className="flex items-center">
            <span className="mr-2">Private Bathroom:</span>
            <label className="flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                id="price-per-person-toggle"
                className="sr-only"
                onChange={handlePrivateBathroomToggle}
                checked={filters.privateBathroom}
              />
              <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
              <div
                className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
                  filters.privateBathroom
                    ? "translate-x-full bg-white"
                    : "bg-gray-500"
                }`}
              ></div>
            </div>
            </label>
          </div>

          <div className="flex items-center">
            <span className="mr-3 text-gray-700">Display price per person</span>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="price-per-person-toggle"
                  className="sr-only"
                  onChange={handlePricePerPersonToggle}
                  checked={filters.pricePerPerson}
                />
                <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
                <div
                  className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
                    filters.pricePerPerson
                      ? "translate-x-full bg-white"
                      : "bg-gray-500"
                  }`}
                ></div>
              </div>
            </label>
          </div>

          <div className="flex items-center">
            <span>Availability:</span>
            <select
              value={filters.availability}
              onChange={handleAvailChange}
              className="mx-2 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logo_blue sm:text-sm"
            >
              <option value="All">All</option>
              <option value="Fall 2025">Fall 2025</option>
              <option value="Sold Out">Sold Out</option>
            </select>
          </div>

          <div className="flex items-center">
            <span>Sort:</span>
            <select
              value={filters.sort}
              onChange={handleSortChange}
              className="mx-2 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-logo_blue sm:text-sm"
            >
              <option value="Reccomended">Reccomended</option>
              <option value="Distance">Distance</option>
              <option value="Price">Price</option>
            </select>
          </div>
        </div>
      </OptionModal>
    );
  }

  function displayLaptopFilters() {
    return (
      <>
        <div className="flex justify-between px-16">
          <div className="items-center">
            <BedRangeSelector
              min={0}
              max={5}
              bedRange={filters.bedRange}
              onChange={handleBedRangeChange}
            />
          </div>
          <PriceRangeSelector
            min={400}
            max={2000}
            priceRange={filters.priceRange}
            onChange={handlePriceChage}
          />
          <div className="flex items-center">
            <span>Location:</span>
            <select
              value={filters.selectedLocation}
              onChange={handleLocationChange}
              className="mx-2 mr-4 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-logo_blue focus:border-logo_blue sm:text-sm"
            >
              <option value="Main Quad">Main Quad</option>
              <option value="Engineering Quad">Engineering Quad</option>
              <option value="South Quad">South Quad</option>
              <option value="Green Street">Green Street</option>
              <option value="ARC">ARC</option>
            </select>

            <DistRangeSelector
              min={5}
              max={40}
              distRange={filters.distRange}
              onChange={handleDistRangeChange}
            />
          </div>
          <div className="flex items-center">
            <button
              onClick={toggleIsOptOpen}
              className="flex items-center p-2 h-12 text-black bg-white border border-gray-300 rounded-md"
            >
              {/* <i className="fa-solid fa-sliders mr-2"></i> */}
              <CgOptions className="h-12 mr-1"/>
              <span className="text-sm font-medium">Filters</span>
            </button>
          </div>
        </div>
        {showOptionsModal()}
      </>
    );
  }

  return (
    <div>
      <button
        onClick={toggleModal}
        className="lg:hidden p-2 m-2 border border-1 border-logo_blue text-base rounded-md text-bg_blue bg-white hover:bg-logo_blue-600"
      >
        Filters
      </button>
      <OptionModal isOpen={isModalOpen} onClose={toggleModal}>
        {displayMobileFilters()}
      </OptionModal>
      {isMobile ? null : displayLaptopFilters()}
    </div>
  );
}

export default Filters;
