import React from "react";
import Slider from "rc-slider";
import { sliderStyles } from "../../backend/consts";
import "rc-slider/assets/index.css";

const sliderStyle = {
  track: {
    backgroundColor: '#0A2463',
  }, handle: {
    backgroundColor: '#FFFFFF',
    borderColor: '#0A2463'
  },
}

export const BedRangeSelector = ({ min, max, bedRange, onChange }) => {
  const formatLabel = (value) => {
    if (value === 0) return "Studio";
    if (value >= 5) return "5+";
    return value;
  };

  return (
    <div className="w-40 mt-5 mb-2"> {/* Adjusted width */}
      <div className="justify-center items-center"> {/* Adjusted layout */}
        <div className="text-sm font-semibold">Beds</div>
        <div className="flex-1"> {/* Slider in the middle */}
          <Slider
            range
            min={min}
            max={max}
            defaultValue={bedRange}
            onChange={onChange}
            styles={sliderStyle}
          />
        </div>
        <span className="text-sm font-medium">
          {formatLabel(bedRange[0])} - {formatLabel(bedRange[1])}
        </span>
      </div>
    </div>
  );
};

export const DistRangeSelector = ({ min, max, distRange, onChange }) => {
  return (
    <div className="w-40 mb-2 mt-5"> {/* Adjusted width */}
      <div className="items-start"> {/* Adjusted layout */}
        <div className="text-sm font-semibold">Walk Time</div>
        <div className="flex-1"> {/* Slider in the middle */}
          <Slider
            min={min}
            max={max}
            defaultValue={distRange}
            onChange={onChange}
            styles={sliderStyle}
            step={5}
          />
        </div>
        <span className="text-sm">
          {distRange} min
        </span>
      </div>
    </div>
  );
};

export const PriceRangeSelector = ({ min, max, priceRange, onChange }) => {

  return (
    <div className="w-40 mt-5 mb-2"> {/* Adjusted width */}
      <div className="justify-center items-center"> {/* Adjusted layout */}
        <div className="text-sm font-semibold">Price Range per Person</div>
        <div className="flex-1"> {/* Slider in the middle */}
          <Slider
            range
            min={min}
            max={max}
            defaultValue={priceRange}
            onChange={onChange}
            styles={sliderStyle}
            step={100}
          />
        </div>
        <span className="text-sm font-medium">
          {priceRange[0]} - {priceRange[1]}
        </span>
      </div>
    </div>
  );
    }
