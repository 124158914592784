import React, { useEffect, useState } from 'react';
import { auth } from '../firebase/index';
import { useAuthState } from 'react-firebase-hooks/auth';
import MainHeader from '../components/Headers/MainHeader';
import {
    createGroup,
    fetchGroups,
    fetchGroupMembers,
    inviteToGroup,
    fetchUserGroup,
    fetchGroupRequests,
    acceptGroupRequest,
    declineGroupRequest,
    leaveGroup
} from '../backend/dbGroupsAPI';
import { fetchFriends } from '../backend/dbFriendsAPI';

function GroupsPage() {
    const [my_user] = useAuthState(auth);
    const [groupName, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [friends, setFriends] = useState([]);
    const [selectedGroupForInvite, setSelectedGroupForInvite] = useState(null);
    const [selectedGroupForMembers, setSelectedGroupForMembers] = useState(null);
    const [groupMembers, setGroupMembers] = useState([]);
    const [groupRequests, setGroupRequests] = useState([]);
    const [userGroupId, setUserGroupId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (my_user) {
                try {
                    const userGroupId = await fetchUserGroup(my_user.uid);
                    setUserGroupId(userGroupId);

                    if (userGroupId) {
                        const groupsList = await fetchGroups(my_user.uid);
                        setGroups(groupsList);
                    }

                    const friendsList = await fetchFriends(my_user.uid);
                    setFriends(friendsList);

                    const requests = await fetchGroupRequests(my_user.uid);
                    setGroupRequests(requests);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [my_user]);

    const handleCreateGroup = async () => {
        if (groupName.trim() === '') {
            alert('Group name cannot be empty.');
            return;
        }

        if (userGroupId) {
            alert('You are already in a group. You cannot create another one.');
            return;
        }

        try {
            await createGroup(groupName, my_user.uid);
            alert('Group created successfully.');
            setGroupName('');
            const groupsList = await fetchGroups(my_user.uid);
            setGroups(groupsList);
        } catch (error) {
            console.error('Error creating group:', error);
            alert('Failed to create group.');
        }
    };

    const handleInvite = async (groupId, userId) => {
        try {
            await inviteToGroup(groupId, userId, my_user.uid);
            alert('User invited to group.');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleShowGroupMembers = async (groupId) => {
        try {
            const members = await fetchGroupMembers(groupId);
            setGroupMembers(members);
            setSelectedGroupForMembers(groupId);
        } catch (error) {
            console.error('Error fetching group members:', error);
        }
    };

    const handleAcceptRequest = async (requestId) => {
        try {
            await acceptGroupRequest(requestId);
            setGroupRequests(groupRequests.filter(request => request.id !== requestId));
            const groupsList = await fetchGroups(my_user.uid);
            setGroups(groupsList);
        } catch (error) {
            alert('Failed to accept group request.');
        }
    };

    const handleDeclineRequest = async (requestId) => {
        try {
            await declineGroupRequest(requestId);
            alert('Group request declined.');
            setGroupRequests(groupRequests.filter(request => request.id !== requestId));
        } catch (error) {
            console.error('Error declining group request:', error);
            alert('Failed to decline group request.');
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await leaveGroup(my_user.uid);
            alert('You have left the group.');
            setUserGroupId(null);
            setGroups([]);
        } catch (error) {
            console.error('Error leaving the group:', error);
            alert('Failed to leave the group.');
        }
    };

    if (!my_user) {
        return <div>You need to log in to view your groups and pending requests.</div>;
    }

    return (
        <div className="bg-gray-50 relative">
            <MainHeader />
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-bold mb-4">Groups</h1>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Create a New Group</h2>
                    <div className="flex mb-4">
                        <input
                            type="text"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            className="border p-2 flex-grow rounded-l"
                            placeholder="Group Name"
                        />
                        <button
                            onClick={handleCreateGroup}
                            className="bg-logo_blue text-white px-4 py-2 rounded-r"
                        >
                            Create Group
                        </button>
                    </div>
                </div>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Your Groups</h2>
                    <ul>
                        {groups.map(group => (
                            <li key={group.id} className="mb-2">
                                <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="ml-4">
                                            <p className="font-semibold">{group.name}</p>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleShowGroupMembers(group.id)}
                                            className="bg-logo_blue text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                                        >
                                            Group Members
                                        </button>
                                        <button
                                            onClick={() => setSelectedGroupForInvite(group.id)}
                                            className="border border-logo_blue px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                                        >
                                            Invite Members
                                        </button>
                                        <button
                                            onClick={handleLeaveGroup}
                                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
                                        >
                                            Leave Group
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Group Requests</h2>
                    <ul>
                        {groupRequests.map(request => (
                            <li key={request.id} className="mb-2">
                                <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="ml-4">
                                            <p className="font-semibold">From: {request.fromUser}</p>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleAcceptRequest(request.id, request.groupId)}
                                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                                        >
                                            Accept
                                        </button>
                                        <button
                                            onClick={() => handleDeclineRequest(request.id)}
                                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
                                        >
                                            Decline
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {selectedGroupForInvite && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
                        <div className="relative bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <button
                                onClick={() => setSelectedGroupForInvite(null)}
                                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                                aria-label="Close"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="text-xl font-bold mb-4">Invite Friends to Group</h2>
                            <ul>
                                {friends.map(friend => (
                                    <li key={friend.id} className="mb-2">
                                        <div className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-between">
                                            <div className="flex items-center">
                                                <img src={friend.profilePicture} alt={friend.firstName} className="w-12 h-12 rounded-full mr-4" />
                                                <div>
                                                    <p className="font-semibold">{friend.firstName} {friend.lastName}</p>
                                                    <p className="text-gray-600">{friend.email}</p>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => handleInvite(selectedGroupForInvite, friend.id)}
                                                className="bg-logo_blue text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                                            >
                                                Invite
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {selectedGroupForMembers && groupMembers.length > 0 && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
                        <div className="relative bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <button
                                onClick={() => setSelectedGroupForMembers(null)}
                                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                                aria-label="Close"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="text-xl font-bold mb-4">Group Members:       {groupMembers.length}/10</h2>
                            <ul>
                                {groupMembers.map(member => (
                                    <li key={member.id} className="mb-2">
                                        <div className="bg-white shadow-lg rounded-lg p-4 flex items-center">
                                            <img src={member.profilePicture} alt={member.firstName} className="w-12 h-12 rounded-full mr-4" />
                                            <div>
                                                <p className="font-semibold">{member.firstName} {member.lastName}</p>
                                                <p className="text-gray-600">{member.email}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GroupsPage;
