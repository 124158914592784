import { React, useState } from "react";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/index";
import MainHeader from "../components/Headers/MainHeader";
import Abode_Beta from "../data/imgs/Abode_Beta.jpg";
import ApartmentList from "../data/imgs/ApartmentList.jpg";
import { addPhoneNumber } from "../backend/dbAPI";
import BetaDisclaimer from "../components/BetaDisclaimer";
import QuickSignupPage from "./QuickSignupPage";


const HomePage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);

  const handleExploreMoreClick = () => {
    setIsDisclaimerVisible(true);
  };

  const handleCloseDisclaimer = () => {
    setIsDisclaimerVisible(false);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://getabode.co",
      // This must be true.
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      alert("Check your email for the sign-in link.");
    } catch (error) {
      console.error("Error sending email link", error);
      alert("Error sending email link");
    }
  };

  const LoadingScreen = () => {
    return <div>Loading...</div>;
  };

  const HomeBody = () => {
    return (
      <div className="no-scroll">
        <Hero />
        <BetaPresentation />

        <Process />
        <Features />
        <CallToAction />
        {/* <EmailSignUp /> */}
      </div>
    );
  };

  const Process = () => {
    return (
      <section className="bg-white py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
              As Easy as 1, 2, 3
            </h2>
            <p className="text-xl text-gray-600">
              Finding your perfect UIUC apartment has never been simpler.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-logo_blue rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <svg
                  className="w-10 h-10 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-2">Search</h3>
              <p className="text-gray-600">
                View every apartment by UIUC in one place.
              </p>
              <br />
              <p className="text-gray-600">
                Browse through our listings and easily find options that meet
                your specific needs and preferences.
              </p>
              <br />
              <p className="text-gray-600">Read real student reviews.</p>
            </div>
            <div className="text-center">
              <div className="bg-logo_blue rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <svg
                  className="w-10 h-10 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-2">Connect</h3>
              <p className="text-gray-600">
                Instantly book in-person or virtual tours with apartments.
              </p>
              <br />
              <p className="text-gray-600">
                Find and sign with roommates that match your vibe.
              </p>
              <br />
              <p className="text-gray-600">
                Save and share your favorites to easily select your perfect
                apartment.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-logo_blue rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
                <svg
                  className="w-10 h-10 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
              <h3 className="text-2xl font-semibold mb-2">Sign</h3>
              <p className="text-gray-600">
                Apply for your chosen apartment using our streamlined
                application process.
              </p>
              <br />

              <p className="text-gray-600">
                Easily understand lease agreements and answer specific questions
                about the contract with AI.
              </p>

              <br />

              <p className="text-gray-600">
                Sign your lease and enjoy your new college home!
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const CallToAction = () => {
    return (
      <section className="mb-20 py-16 sm:py-24 bg-gray-50">
        {" "}
        {/* Use a light background for the whole section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-logo_blue rounded-lg shadow-lg p-8 md:p-12 lg:p-16 text-center">
            {" "}
            {/* Rounded container with padding */}
            <h2 className="text-3xl sm:text-4xl font-extrabold text-white mb-4">
              Find Your Perfect Apartment Today
            </h2>
            <p className="text-xl text-white mb-8">
              Join hundreds of UIUC students who have simplified their apartment
              search with Abode.
            </p>
            <div className="flex justify-center">
              <button
                onClick={goToBeta}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-logo_blue bg-white hover:bg-gray-200 shadow-md"
              >
                Try it out now!
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const Features = () => {
    return (
      <section className="bg-grey-50 py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
              Why Use Abode?
            </h2>
            <p className="text-xl text-gray-600">
              Abode simplifies your UIUC apartment search with tailored features
              designed for students.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">
                Comprehensive Listings
              </h3>
              <p className="text-gray-600">
                Browse an extensive collection of UIUC apartments, all in one
                place.
              </p>
            </div>
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Powerful Search</h3>
              <p className="text-gray-600">
                Filter and view all apartments that match your specific criteria
                based on location, price, number of beds, and more!
              </p>
            </div>
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Roommate Finder</h3>
              <p className="text-gray-600">
                View and connect with potential roommates who share your living
                style and interests.
              </p>
            </div>
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">
                Instant tour booking
              </h3>
              <p className="text-gray-600">
                Book an in-person or virtual tour for an apartment with one
                click.
              </p>
            </div>
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">
                Real student reviews
              </h3>
              <p className="text-gray-600">
                Read honest insights from real, verified student experiences to
                help you make informed decisions.
              </p>
            </div>
            <div className="bg-white shadow rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-2">Subleases</h3>
              <p className="text-gray-600">
                List and view available short-term leases with subleases posted
                by students!
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  // const BetaPresentation = () => {
  //   return (
  //     <section className="py-16 sm:py-24 bg-gray-50">
  //       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
  //         <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
  //           Explore Our Beta
  //         </h2>
  //         <p className="text-xl text-gray-600 mb-8">
  //           Take a sneak peek into the easiest way to find your next college
  //           apartment.
  //         </p>
  //         <div className="aspect-w-16 aspect-h-9">
  //           <img src={Abode_Beta}></img>
  //         </div>

  //         <div className="flex items-center justify-center my-8">
  //           <button
  //             onClick={goToBeta}
  //             className="px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
  //           >
  //             Try out Beta
  //           </button>

  //         </div>
  //       </div>
  //     </section>
  //   );
  // };

  const BetaPresentation = () => {
    return (
      <section className="py-16 sm:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
            Explore Our Beta
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Take a sneak peek into the easiest way to find your next college
            apartment.
          </p>
          <div className="w-full flex justify-center">
            <div className="aspect-w-16 aspect-h-9 max-w-3xl lg:max-w-4xl xl:max-w-5xl">
              
                <img src={Abode_Beta}></img>
              

              {/* <span className="flex mt-4 text-logo_blue text-sm">View every single apartment by UIUC. in one place. now.</span> */}
            </div>
          </div>

          <div className="flex items-center justify-center my-8">
            <button
              onClick={goToBeta}
              className="px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
            >
              Explore Apartments
            </button>
          </div>
        </div>
      </section>
    );
  };

  const Hero = () => {
    return (
      <section
        className="flex items-center justify-center min-h-[90vh] bg-white bg-opacity-50 bg-cover bg-no-repeat"
        style={{ backgroundImage: "url(YourBackgroundGraphicURL)" }}
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between px-4 py-16 md:py-24">
          <div className="w-full lg:w-5/12 text-center lg:text-left">
            <div>
              <h1 className="text-4xl sm:text-5xl xl:text-5xl 2xl:text-6xl font-extrabold text-gray-900 mb-4">
                Simplify Your Student Housing
              </h1>
              <p className="text-xl sm:text-2xl text-gray-600 mb-2">
                All your UIUC housing needs{" "}
                <span className="underline">in one place</span>
              </p>
              <p className="text-xl sm:text-2xl text-gray-600 mb-2">
                Easily find and secure your perfect apartment, connect with
                roommates, and explore subleases.
              </p>
              <p className="text-xl sm:text-2xl text-gray-600">
                Spend less time on leasing and more on living.
              </p>
            </div>

            <div className="flex justify-center lg:justify-start mt-2">
              {/* <LeanEmailSignUp className="inline-flex items-center"/> */}
              <button
                onClick={goToBeta}
                className="mt-4 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
              >
                Explore Apartments
              </button>

              {/* <div>
                <button
                  onClick={handleExploreMoreClick}
                  className="mt-4 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
                >
                  Explore More
                </button>

                <div className="text-white mt-4">
                  <a
                    href="https://www.instagram.com/abodeuiuc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                  >
                    <div className="bg-logo_blue rounded-full px-2 py-1">
                      <i className="fab fa-instagram text-md"></i>
                    </div>
                  </a>
                </div>
              </div> */}

              {/* Conditionally render the BetaDisclaimer */}
              {isDisclaimerVisible && (
                <BetaDisclaimer onClose={handleCloseDisclaimer} />
              )}
            </div>
            {/* <div className="flex justify-center lg:justify-start lg:my-4 font-small">
              
              <button
                onClick={goToApartmentList}
                className="mr-2 inline-flex items-center px-6 py-3 border border-transparent text-base rounded-md text-white bg-logo_blue hover:bg-logo_blue-600"
              >
                The Ultimate UIUC Apartment List
              </button>
              <button
                onClick={goToBeta}
                className="inline-flex items-center px-6 py-3 border border-2 border-logo_blue text-base rounded-md text-bg_blue bg-white hover:bg-logo_blue-600"
              >
                Explore Abode (Beta)
              </button>
            </div>*/}
          </div>
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mt-8 lg:mt-0">
            <div className="aspect-w-16 aspect-h-9 max-w-xl lg:max-w-2xl xl:max-w-3xl">
              <img src={ApartmentList} alt="Apartment List" />
              {/* <span className="flex mt-4 text-logo_blue text-sm">View every single apartment by UIUC. in one place. now.</span> */}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const Testimonials = () => {
    return (
      <section className="bg-white shadow py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 mb-4">
              What UIUC Students Are Saying
            </h2>
            <p className="text-xl text-gray-600">
              See how Abode has helped students find their perfect college
              apartment.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <p className="text-gray-600 mb-4">
                "Abode made my apartment search so much easier! I found the
                perfect place for my needs and budget in no time."
              </p>
              <p className="font-semibold">John D.</p>
              <p className="text-gray-600">Junior, Computer Science</p>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-md">
              <p className="text-gray-600 mb-4">
                "I love how Abode simplifies the application process. It saved
                me so much time and hassle!"
              </p>
              <p className="font-semibold">Sarah M.</p>
              <p className="text-gray-600">
                Sophomore, Business Administration
              </p>
            </div>
            <div className="bg-white rounded-lg p-6 shadow-md">
              <p className="text-gray-600 mb-4">
                "Thanks to Abode, I found an amazing apartment and connected
                with great roommates. Highly recommend!"
              </p>
              <p className="font-semibold">Alex T.</p>
              <p className="text-gray-600">Senior, Mechanical Engineering</p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const goToApartmentList = () => {
    navigate("/UIUC");
  };

  const goToBeta = () => {
    navigate("/listings");
  };

  return (
    <div>
      {/* {loading ? (
        <LoadingScreen />
      ) : ( */}
        <div className="max-h-screen overflow-y-scroll">
          <div className="bg-gray-50">
            <MainHeader />
            {/* <LandingHeader /> */}
            <QuickSignupPage />
            {/* <HomeBody />
            <Footer /> */}
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

function QuickSignupHome() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addPhoneNumber(phoneNumber);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting phone number: ", error);
      alert(
        "There was an error submitting your phone number. Please try again."
      );
    }
  };

  const handleNavigate = () => {
    navigate("/listings");
  };

  return (
    <div>
      {!submitted ? (
        <>
          {/* <p className="text-md mt-4 my-2 text-center">
            Enter your phone number to stay up to date with Abode and help
            simplify your college leasing process.
          </p> */}

          <p className="font-medium mt-1 mb-4">
            Everything you need for your college apartment search in one place.
          </p>

          <p className="text-gray-600 mb-4">
            Want to be notified when we launch? Enter your phone number below,
            and we'll send you a text!
          </p>

          <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
            <div className="flex justify-center items-center mb-4">
              <div className="flex items-center space-x-2">
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  className="block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-logo_blue focus:border-logo_blue sm:text-sm"
                  placeholder="224-098-0978"
                />
                <button
                  type="submit"
                  className="px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo_blue"
                >
                  Join Now
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="text-center bg-white">
          <h3 className="text-xl font-semibold text-gray-900 mt-4">
            Thank You for Signing Up!
          </h3>
          <p className="text-gray-600 mb-4">
            We will let you know when we launch!
          </p>
          <button
            onClick={handleNavigate}
            className=" px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-logo_blue hover:bg-logo_blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo_blue"
          >
            Explore Apartments
          </button>
        </div>
      )}
    </div>
  );
}

export default HomePage;
