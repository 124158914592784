import React from "react";
import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/Headers/MainHeader";

function SubleaseIntroPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="hidden md:block">
        <MainHeader />
      </div>
      <div className="max-w-3xl mt-1 mx-auto p-6 bg-white rounded-md">
        <h1 className="text-2xl font-bold mb-4">How to Sublease</h1>
        <p className="mb-4">
          Subleasing is a process where you rent out your apartment or room to
          another person for a specific period of time. This is a common
          practice if you need to move out before your lease ends, studying
          abroad, or going away for a short period.
        </p>
        <h2 className="text-xl font-semibold mb-3">Important:</h2>
        <p className="mb-4">
          Abode helps students connect with others for subleasing. Once you have
          connected with another student and both have agreed on subleasing
          terms, congrats! 90% of the hard work is done. From there, it is your
          responsibility to contact your leasing office to finalize the
          paperwork and finish the process!
        </p>
        <h2 className="text-xl font-semibold mb-3">Steps to Sublease:</h2>
        <ol className="list-decimal list-inside mb-4 space-y-2">
          <li>
            <strong>Check your lease agreement:</strong> Check with your
            apartment and make sure that your lease allows for
            subleasing/re-letting.
          </li>
          <li>
            <strong>Post sublease on Abode:</strong> Click the button below to
            post your sublease. Enter all the information needed so potential
            subtenants can view your listing through Abode.
          </li>
          <li>
            <strong>Connect with students:</strong> All UIUC students will be
            able to see your sublease listed and contact you if interested. You
            may also view and connect with other students interested in a
            semester/summer sublease.
          </li>
          <li>
            <strong>Finalize sublease: </strong> Once both parties have agreed,
            contact your leasing office to officially finalize the process and
            paperwork. Usually the potential subtenant submits an application
            and you just confirm that you are subleasing your apartemnt to
            them. Once paperwork is complete and the subtenant is approved,
            congrats! You have officially subleased your apartment.
          </li>
        </ol>
        <h2 className="text-xl font-semibold mb-3">Important Notes:</h2>
        <ul className="list-disc list-inside mb-4 space-y-2">
          {/* <li>Make sure to add all your social media information to your profile before proceeding with the sublease process.</li> */}
          <li>
            Communicate clearly with your subtenant about the terms, conditions,
            and process.
          </li>
          <li>
            Stay in touch with your landlord to ensure everything goes smoothly.
          </li>
        </ul>
        {/* <div className="bg-gray-100 p-4 rounded-md mb-4">
        <p className="text-gray-700">
          Before you proceed, please make sure that you have updated your profile
          with all your primary contact information and social media handles if you have any. This helps potential subtenants
          reach you through different means and can build trust with them.
        </p>
      </div> */}
        <button
          className="w-full py-2 px-4 bg-logo_blue text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
          onClick={() => navigate("/sublease-form")}
        >
          List Your Sublease
        </button>
      </div>
    </>
  );
}

export default SubleaseIntroPage;
