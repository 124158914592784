import React from "react";

function LoadingAnimation() {
    return (
      <div className="w-full p-4">
        <div className="bg-gray-200 rounded-lg overflow-hidden">
          <div className="w-full h-48 bg-gray-300 animate-pulse"></div>
          <div className="p-4">
            <div className="w-2/3 h-4 bg-gray-300 rounded animate-pulse mb-2"></div>
            <div className="w-1/2 h-3 bg-gray-300 rounded animate-pulse mb-4"></div>
            <div className="w-1/3 h-3 bg-gray-300 rounded animate-pulse mb-2"></div>
            <div className="w-2/5 h-5 bg-gray-300 rounded animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

export default LoadingAnimation;