import React, { useState, useEffect } from "react";
import { ReactComponent as HeartSVG } from "../data/imgs/heart.svg";

function PhotoModal({ images, showModal, toggleModal }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Stop background scrolling
  useEffect(() => {
    const bodyStyle = document.body.style;
    if (showModal) {
      bodyStyle.overflow = "hidden";
    }
    return () => {
      bodyStyle.overflow = "unset";
    };
  }, [showModal]);

  const handleImageSelect = (index) => {
    setSelectedIndex(index);
  };

  const handleNextImage = () => {
    setSelectedIndex((selectedIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setSelectedIndex((selectedIndex - 1 + images.length) % images.length);
  };

  const closeFullScreen = () => {
    setSelectedIndex(null);
  };

  if (!showModal) return null;
  return (
    <div>
      <div className="fixed inset-0 bg-gray-900 flex justify-center items-center z-10">
        <div className="relative bg-white w-full max-h-full overflow-auto">
          <div className="sticky top-0 bg-white p-4 z-10">
            <div className="flex justify-between items-center">
              <button
                onClick={toggleModal}
                className="text-gray-400 hover:text-black text-xl"
              >
                &#10005;
              </button>
            </div>
          </div>
          <div className="mx-auto w-full md:max-w-5xl">
            <div className="flex flex-wrap md:m-4">
              {images.map((img, index) => (
                <div
                  key={index}
                  className={`py-1 md:p-4 w-full ${
                    index % 5 === 0 ? "md:w-full" : "md:w-1/2 h-96"
                  }`}
                >
                  <img
                    src={img}
                    alt={`Thumbnail ${index}`}
                    className="object-cover w-full h-full cursor-pointer md:rounded shadow transform transition duration-300 md:hover:scale-105"
                    onClick={() => handleImageSelect(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-100 flex justify-center items-center z-50">
          <div className="flex absolute top-0 w-full justify-between p-6 md:px-12">
            <button
              onClick={closeFullScreen}
              className="text-white text-md font-medium"
            >
              &#10005; Close
            </button>
            <div className="text-white text-md">
              {`${selectedIndex + 1} / ${images.length}`}
            </div>
            <button className="p-2 rounded-full hover:bg-gray-600">
              
            <HeartSVG
              fill="white"
              style={{ width: "20px", height: "inherit" }}
            />
            </button>
          </div>
          <button
            onClick={handlePrevImage}
            className="absolute left-10 text-white text-4xl"
          >
            &#9664;
          </button>
          <img
            src={images[selectedIndex]}
            alt={`Image ${selectedIndex}`}
            className="max-h-[80vh] max-w-[95vw]"
          />
          <button
            onClick={handleNextImage}
            className="absolute right-10 text-white text-4xl"
          >
            &#9654;
          </button>
        </div>
      )}
    </div>
  );
}

export default PhotoModal;
