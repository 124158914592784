import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PopupLogin = ({ onClose }) => {
  const [netid, setNetid] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const goToHome = () => {
    navigate("/");
  };

  const goToGuestSignup = () => {
    navigate("/guest-signup");
  };

  const validateAndNavigate = () => {
    const isValidNetid = /^[a-zA-Z0-9]+$/.test(netid);

    if (netid.trim() && isValidNetid) {
      navigate("/signup", {
        state: { from: location, email: `${netid}@illinois.edu` },
      });
      setEmailError("");
    } else if (!netid.trim()) {
      setEmailError("Please enter your NetID.");
    } else {
      setEmailError("Please enter a valid UIUC NetID");
    }
  };

  const goToLogin = () => {
    navigate("/login", { state: { from: location } });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      validateAndNavigate();
    }
  };

  return (
    <div>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center"
        style={{ zIndex: 100 }}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative">
          {/* <button
            onClick={onClose}
            className="absolute text-lg top-0 right-0 p-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button> */}
          <h2 className="text-3xl font-bold mb-4 text-center text-logo_blue">
            Welcome to Abode!
          </h2>
          <p className="text-gray-600 text-center mb-6">
            Discover every on-campus apartment here.
            <br />
            For UIUC students, by UIUC students.
          </p>
          <p className="text-center font-medium mb-6">
            Verify you are a UIUC student to sign up and access!
          </p>

          {/* Input Section */}
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="flex items-center border rounded overflow-hidden w-full max-w-md">
              <input
                type="text"
                value={netid}
                onChange={(e) => setNetid(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Your NetID"
                className="p-3 w-full focus:outline-none"
              />
              <span className="border-l p-3 bg-gray-100 select-none text-sm">
                @illinois.edu
              </span>
            </div>
            {emailError && (
              <p className="text-red-500 text-center text-sm mt-2">
                {emailError}
              </p>
            )}
          </div>

          {/* Buttons Section */}
          <div className="flex flex-col items-center space-y-4">
            <button
              className="bg-logo_blue text-white font-bold py-3 px-6 rounded-lg shadow-sm w-full max-w-md hover:bg-opacity-90 transition duration-200"
              onClick={validateAndNavigate}
            >
              Sign Up
            </button>
            <button
              onClick={goToGuestSignup}
              className="text-sm text-gray-600 underline hover:text-gray-900"
            >
              Not a Student? Sign Up Here!
            </button>
          </div>

          {/* Divider */}
          <div className="my-6 border-b border-gray-300"></div>

          {/* Login Section */}
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-gray-600 mb-4">Or</h1>
            <button
              className="bg-white text-logo_blue border-2 border-logo_blue font-bold py-2 px-6 rounded-lg shadow-sm w-full max-w-md hover:bg-opacity-90 transition duration-200"
              onClick={goToLogin}
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLogin;
