import React, { useState, useEffect } from "react";
import DisplayMap from "./Map/DisplayMap";
import Listings from "./Listings/Lisitngs";
import IconHeader from "./Headers/IconHeader";
import { FaMapMarkerAlt } from "react-icons/fa";
import AptDisclaimer from "./AptDisclaimer";

const renderLoadingSkeleton = () => {
  return (
    <div>
      <div className="mx-auto p-4 md:px-16">
        <div
          className="grid gap-y-8 gap-x-8"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          }}
        >
          {[1, 2, 3, 4, 5, 7, 8, 9].map((_, index) => (
            <div key={index} className="relative animate-pulse">
              <div
                className="bg-gray-300 rounded-t" // Light gray background
                style={{
                  width: "100%", // Ensure the div takes up full grid column width
                  height: "310px", // Height for the bigger div
                }}
              >
                <div className="w-full h-full bg-gray-300 rounded-t"></div>
              </div>

              <div
                className="bg-gray-100 rounded-b"
                style={{
                  height: "80px", // Height for the bottom div
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SlideInComponent = ({
  apartments,
  filters,
  setFilters,
  showIcons = true,
  loading = false,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMapOpen = () => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, mapOpen: !filters.mapOpen };
      return newFilters;
    });
  };

  return (
    <div>
      {isMobile ? (
        <div className="flex-1 w-full">
          <div className="overflow-y-auto" style={{ height: "90vh" }}>
            {showIcons && (
              <IconHeader filters={filters} setFilters={setFilters} />
            )}
            <AptDisclaimer apartment_group={filters.apartmentType} />

            {loading ? (
              renderLoadingSkeleton() // Show loading skeleton
            ) : (
              <Listings
                apartments={apartments}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-full bg-white">
          <div
            className={`flex-1 transition-all duration-300 ${
              filters.mapOpen ? "w-9/14" : "w-full"
            }`}
          >
            <div
              className="overflow-y-auto overflow-x-auto"
              style={{ height: "90vh" }}
            >
              {showIcons && (
                <IconHeader filters={filters} setFilters={setFilters} />
              )}
              <AptDisclaimer apartment_group={filters.apartmentType} />
              {/* <div>UGroup Updated Fall 2025 Rates releasing in October!</div> */}

              {loading ? (
                renderLoadingSkeleton() // Show loading skeleton
              ) : (
                <Listings
                  apartments={apartments}
                  filters={filters}
                  setFilters={setFilters}
                />
              )}
            </div>
          </div>

          <div
            className={`${
              filters.mapOpen ? "w-5/14" : "w-0"
            } bg-gray-200 inset-y-0 right-0 transform ${
              filters.mapOpen ? "translate-x-0" : "translate-x-full"
            } `}
          >
            <div style={{ width: "42vw", height: "90vh" }}>
              <div
                className={`absolute top-10 -translate-y-1/2 z-10 cursor-pointer ${
                  filters.mapOpen ? "right-[42vw]" : "right-0"
                }`}
                onClick={toggleMapOpen}
              >
                <div className="bg-logo_blue text-white py-4 px-3 flex items-center justify-center rounded-l">
                  {filters.mapOpen ? (
                    <span className="material-icons">{">"}</span>
                  ) : (
                    <FaMapMarkerAlt />
                  )}
                </div>
              </div>

              {filters.mapOpen && (
                <DisplayMap apartments={apartments} filters={filters} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideInComponent;
