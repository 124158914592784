import React from 'react';

const BarChart = ({ ratings }) => {
  const totalRatings = ratings.reduce((acc, curr) => acc + curr, 0);
  const getBarWidth = (count) => (totalRatings === 0 ? 0 : (count / totalRatings) * 100);

  return (
    <div className="text-left border-r border-gray-300 pr-8">
      <h3 className="text-sm font-semibold mb-2">Overall rating</h3>
      {[5, 4, 3, 2, 1].map((rating) => (
        <div key={rating} className="flex items-center">
          <span className="text-xs mr-2">{rating}</span>
          <div className="flex-1 h-1 bg-gray-200 rounded-full">
            <div
              className="h-full bg-black rounded-full"
              style={{ width: `${getBarWidth(ratings[rating - 1])}%` }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
