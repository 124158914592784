import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactUsModal from "./ContactUsModal";
import BookTourModal from "./BookTourModal";
import VisitWebsiteModal from "./VisitWebsiteModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/index";
import { logEvent, analytics } from "../firebase/index";

const ApplyNowForm = ({ apartment }) => {
  const [my_user] = useAuthState(auth);
  const navigate = useNavigate();
  const websiteLink = apartment.websiteLink;
  const id = apartment.id;
  const [showContactModal, setShowContactModal] = useState(false);
  const [showBookTourModal, setShowBookTourModal] = useState(false);
  const [showVisitWebsiteModal, setShowVisitWebsiteModal] = useState(false);

  const userId = my_user ? my_user.uid : null;

  function handleApplyNowClick() {
    if (userId) {
      logEvent(analytics, "apply_now_click", {
        apartmentId: apartment.id,
        userId: userId,
        timestamp: Date.now(),
      });
    }
    navigate(`/apply-now/${id}`);
  }

  function handleContactClick() {
    if (userId) {
      logEvent(analytics, "contact_click", {
        apartmentId: apartment.id,
        userId: userId,
        timestamp: Date.now(),
      });
    }
    setShowContactModal(true)
  }

  function handleBookTourClick() {
    if (userId) {
      logEvent(analytics, "book_tour_click", {
        apartmentId: apartment.id,
        userId: userId,
        timestamp: Date.now(),
      });
    }
    setShowBookTourModal(true)
  }

  const handleViewWebsite = () => {
    if (userId) {
      logEvent(analytics, "visit_website_click", {
        apartmentId: apartment.id,
        userId: userId,
        timestamp: Date.now(),
      });
    }
    if (websiteLink) {
      window.open(websiteLink, "_blank");
    } else {
      alert("Website link not found");
    }
  };

  return (
    <div className="py-4 my-4 bg-white shadow-lg rounded-lg border border-gray-200 max-w-sm mx-auto">
      <div className="mt-4 py-2 px-6">
        <div>
          <h2 className="text-2xl text-left font-semibold text-transparent bg-clip-text bg-gradient-blue-r">
            Interested?
          </h2>
          <h2 className="text-sm text-left font-regular my-2">
            Continue to view the application process
          </h2>
          <button
            className="w-full mt-4 py-4 text-md font-semibold text-white rounded-lg bg-gradient-blue-r"
            onClick={handleApplyNowClick}
          >
            Apply Now
          </button>
        </div>
        <div className="flex justify-between pt-4">
          <button
            className="w-full text-sm text-gray-500 underline"
            onClick={handleContactClick}
          >
            Contact Us
          </button>
          <button
            className="w-full text-sm text-gray-500 underline"
            onClick={handleBookTourClick}
          >
            Book Tour
          </button>
          <button
            className="w-full text-sm text-gray-500 underline"
            onClick={() => handleViewWebsite(apartment.websiteLink)}
          >
            Visit Website
          </button>
        </div>
      </div>
      {showContactModal && (
        <ContactUsModal
          userId={userId}
          apartment={apartment}
          onClose={() => setShowContactModal(false)}
        />
      )}
      {showBookTourModal && (
        <BookTourModal
          userId={userId}
          apartment={apartment}
          apartmentEmail={apartment.email}
          apartmentPropertyName={apartment.name}
          onClose={() => setShowBookTourModal(false)}
        />
      )}
      {showVisitWebsiteModal && (
        <VisitWebsiteModal
          userId={userId}
          apartment={apartment}
          apartmentEmail={apartment.email}
          apartmentPropertyName={apartment.name}
          apartmentWebsite={websiteLink}
          onClose={() => setShowVisitWebsiteModal(false)}
        />
      )}
    </div>
  );
};

export default ApplyNowForm;
